import Account from "../../screens/account/Account";
import ManageSubscription from "../../screens/account/ManageSubscription";
import BoxDescription from "../../screens/account/BoxDescription";
import ShipingAddress from "../../screens/account/ShippingAddress";
import AddBoxNote from "../../screens/account/AddBoxNote";
import AddBoxPhotos from "../../screens/account/AddBoxPhotos";
import BoxDetails from "../../screens/account/BoxDetails";
import UserInformation from "../../screens/account/UserInformation";
import UpgradeMarket from "../../screens/account/UpgradeMarket";
import UpdatePaymentMethod from "../../screens/account/UpdatePaymentMethod";
import * as ROUTES from "../Routes";

export const AccountStack = [
    {
        path: ROUTES.ACCOUNT,
        component: Account,
    },
    {
        path: ROUTES.SUBSCRIPTION_SUMMARY,
        component: ManageSubscription,
    },
    {
        path: ROUTES.ADD_NEW_UPGRADE,
        component: UpgradeMarket,
    },
    {
        path: ROUTES.BOX_DESCRIPTION,
        component: BoxDescription,
    },
    {
        path: ROUTES.SHIPPING_ADDRESS,
        component: ShipingAddress,
    },
    {
        path: ROUTES.ADD_FEATURE_NOTE,
        component: AddBoxNote,
    },
    {
        path: ROUTES.ADD_FEATURE_PHOTOS,
        component: AddBoxPhotos,
    },
    {
        path: ROUTES.BOX_DETAILS,
        component: BoxDetails,
    },
    {
        path: ROUTES.USER_INFORMATION,
        component: UserInformation,
    },
    {
        path: ROUTES.UPDATE_PAYMENT_METHOD,
        component: UpdatePaymentMethod,
    },
];
