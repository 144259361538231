import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setupAccountInitialRoute } from "../services/SetupService";
import { saveOneTimeGift, resetSubscriptionStore } from "../store/subscriptions/actions";

function Initial(props) {
    const { history, subscriptions, saveOneTimeGift, resetSubscriptionStore } = props;

    useEffect(() => {
        const searchParams = window.location.search.replace("?", "").split("&");
        setupAccountInitialRoute(history, searchParams, subscriptions, saveOneTimeGift, resetSubscriptionStore);
    }, [history, subscriptions, saveOneTimeGift, resetSubscriptionStore]);

    return null;
}

const mapDispatchToProps = (dispatch) => ({
    saveOneTimeGift: (giftType, upgradeType) => dispatch(saveOneTimeGift(giftType, upgradeType)),
    resetSubscriptionStore: () => dispatch(resetSubscriptionStore()),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(Initial));
