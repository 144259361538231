import axios from "axios";
import { graphqlServiceSauce, graphqlServiceAuthSauce } from "./HttpService";
import { divideProductsList } from "./SubscriptionPlanService";
import { ProductList } from "./graphql/ProductList";
import { UpdateBox } from "./graphql/UpdateBox";
import { GetMe } from "./graphql/GetMe";
import { GetPromotionCode } from "./graphql/GetPromotionCode";
import { GetPaymentMethod } from "./graphql/GetPaymentMethod";
import { UpdateDietRestriction } from "./graphql/UpdateDietRestriction";
import { AddSubscription } from "./graphql/AddSubscription";
import { CancelSubscription } from "./graphql/CancelSubscription";
import { GetInvoiceHostedUrl } from "./graphql/GetInvoiceHostedUrl";
import { CheckInvoice } from "./graphql/CheckInvoice";
import { GetCustomerPortal } from "./graphql/GetCustomerPortal";
import { UpdatePaymentMethod } from "./graphql/UpdatePaymentMethod";
import { ResetPassword } from "./graphql/ResetPassword";
import { AddUpgrade } from "./graphql/AddUpgrade";
import { setLocalStorageItem } from "../services/LocalStorageService";
import * as ROUTES from "../navigation/Routes";
import * as CONSTANTS from "../constants/Global";
import * as LOCALSTORAGE from "../constants/LocalStorage";

export const getProductsList = async () => {
    try {
        const productsListResponse = await graphqlServiceSauce(ProductList);
        console.log(productsListResponse);

        if (productsListResponse.data && productsListResponse.data.data.productsList) {
            const productsList = productsListResponse.data.data.productsList;

            const dividedProductsList = await divideProductsList(productsList);

            return dividedProductsList;
        }
    } catch (error) {
        return false;
    }
};

export const getUserSubscriptions = async () => {
    try {
        const userSubscriptionResponse = await graphqlServiceAuthSauce(GetMe);
        console.log(userSubscriptionResponse);

        if (userSubscriptionResponse.data) {
            if (userSubscriptionResponse.data.data.getMe) {
                const subscriptionsList = userSubscriptionResponse.data.data.getMe;
                return subscriptionsList;
            }

            if (userSubscriptionResponse.data.errors) {
                return false;
            }
        }
    } catch (error) {
        console.log(error);

        return false;
    }
};

export const updateBox = async (data) => {
    try {
        const updatedBoxResponse = await graphqlServiceAuthSauce(UpdateBox, data);
        console.log(updatedBoxResponse);

        if (updatedBoxResponse.data && updatedBoxResponse.data.data.updateBox) {
            const updatedBox = updatedBoxResponse.data.data.updateBox;
            return updatedBox;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const postSubscription = async (submitSubscriptionData) => {
    const sessionId = submitSubscriptionData.input.sessionId;

    try {
        const subscriptionResponse = await graphqlServiceAuthSauce(AddSubscription, submitSubscriptionData);
        console.log(subscriptionResponse);

        if (subscriptionResponse.data) {
            if (subscriptionResponse.data.data.addSubscription) {
                const subscriptionData = subscriptionResponse.data.data.addSubscription;

                return subscriptionData;
            }

            if (subscriptionResponse.data.errors) {
                let error = {};
                switch (subscriptionResponse.data.errors[0].message) {
                    case "Your card was declined.":
                        error.message =
                            "Your card was declined. Maybe your card was reported how stolen or lost or has some restriction, contact your bank.";
                        break;
                    case "requires_action":
                        error.message = "url";
                        error.link = await getInvoiceHostedUrl(sessionId);
                        break;
                    case "SESSION_EXIST":
                        const subscriptions = await getUserSubscriptions();
                        if (subscriptions) {
                            const subscriptionIncomplete = subscriptions.some(
                                (subscription) =>
                                    subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.INCOMPLETE &&
                                    subscription.sessionId === sessionId
                            );

                            if (subscriptionIncomplete) {
                                error.message = "url";
                                error.link = await getInvoiceHostedUrl(sessionId);
                            } else {
                                error.message = "session_exist";
                            }
                        }
                        break;
                    default:
                        error.message = subscriptionResponse.data.errors[0].message;
                        break;
                }

                return error;
            }
        }
    } catch (error) {
        console.log(error);

        return false;
    }
};

export const getInvoiceHostedUrl = async (sessionId) => {
    try {
        const hostedUrlResponse = await graphqlServiceAuthSauce(GetInvoiceHostedUrl, {
            sessionId: sessionId,
        });
        console.log(hostedUrlResponse);

        if (hostedUrlResponse.data) {
            if (hostedUrlResponse.data.data.getInvoiceHostedUrl) {
                const hostedUrl = hostedUrlResponse.data.data.getInvoiceHostedUrl;

                return hostedUrl;
            }

            if (hostedUrlResponse.data.errors) {
                return hostedUrlResponse.data.errors[0].message;
            }
        }
    } catch (error) {
        console.log(error);

        return false;
    }
};

export const updateDietaryRestrictions = async (parentData) => {
    try {
        const updatedParentResponse = await graphqlServiceAuthSauce(UpdateDietRestriction, parentData);
        console.log(updatedParentResponse);

        if (updatedParentResponse.data && updatedParentResponse.data.data.updateDietRestriction) {
            const updateDietRestriction = updatedParentResponse.data.data.updateDietRestriction;
            return updateDietRestriction;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const validateDiscountCoupon = async (couponData) => {
    try {
        const getPromotionCodeResponse = await graphqlServiceAuthSauce(GetPromotionCode, couponData);

        if (getPromotionCodeResponse && getPromotionCodeResponse.data && getPromotionCodeResponse.data.data) {
            const coupon = getPromotionCodeResponse.data.data.getPromotionCode;

            return coupon;
        } else {
            if (getPromotionCodeResponse.data.errors) {
                const error = getPromotionCodeResponse.data.errors[0].message;
                if (error === "INFORMACION NO ENCONTRADA") {
                    return "not found";
                } else {
                    return "already used";
                }
            }
        }
    } catch (error) {
        return error;
    }
};

export const cancelSubscription = async (cancelData, email) => {
    try {
        const cancelSubscriptionResponse = await graphqlServiceAuthSauce(CancelSubscription, cancelData);
        console.log(cancelSubscriptionResponse);

        if (cancelSubscriptionResponse.data && cancelSubscriptionResponse.data.data.cancelSubscription) {
            const cancelSubscription = cancelSubscriptionResponse.data.data.cancelSubscription;

            if (email) {
                await axios.delete(
                    `https://us-central1-oneway-grandbox.cloudfunctions.net/subscribeToNewsLetter/?email=${email}&brand=Grandbox`
                );
            }

            return cancelSubscription;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const getLastPaymentMethod = async (userId) => {
    try {
        const paymentMethodResponse = await graphqlServiceAuthSauce(GetPaymentMethod, userId);
        console.log(paymentMethodResponse);

        if (paymentMethodResponse.data && paymentMethodResponse.data.data.getPaymentMethod) {
            const paymentMethod = paymentMethodResponse.data.data.getPaymentMethod;
            return paymentMethod;
        } else {
            if (paymentMethodResponse.data.errors) {
                return paymentMethodResponse.data.errors[0].message;
            }
        }
    } catch (error) {
        return false;
    }
};

export const getCustomerPortal = async (userId) => {
    console.log("getCustomerPortal");

    try {
        const customerPortalResponse = await graphqlServiceAuthSauce(GetCustomerPortal, userId);
        console.log(customerPortalResponse);
        if (customerPortalResponse.data && customerPortalResponse.data.data.getCustomerPortal) {
            const customerPortal = customerPortalResponse.data.data.getCustomerPortal;
            return customerPortal;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

export const checkLastInvoice = async (subscriptionId) => {
    console.log("checkLastInvoice");
    try {
        const checkInvoiceResponse = await graphqlServiceAuthSauce(CheckInvoice, subscriptionId);
        console.log(checkInvoiceResponse);

        if (checkInvoiceResponse.data && checkInvoiceResponse.data.data.checkInvoice) {
            const checkInvoice = checkInvoiceResponse.data.data.checkInvoice;
            return checkInvoice;
        } else {
            if (checkInvoiceResponse.data.errors) {
                const error = checkInvoiceResponse.data.errors[0].message;

                if (error === "invoice_unpaid") {
                    return "invoice_unpaid";
                } else {
                    return false;
                }
            }
        }
    } catch (error) {
        return false;
    }
};

export const updatePaymentMethod = async (paymentMethod) => {
    try {
        const updatePaymentMethodResponse = await graphqlServiceAuthSauce(UpdatePaymentMethod, paymentMethod);
        console.log(updatePaymentMethodResponse);

        if (updatePaymentMethodResponse.data && updatePaymentMethodResponse.data.data.updatePaymentMethod) {
            const updatePaymentMethod = updatePaymentMethodResponse.data.data.updatePaymentMethod;
            return updatePaymentMethod;
        } else {
            if (updatePaymentMethodResponse.data.errors) {
                const error = updatePaymentMethodResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const resetPassword = async (passwordData) => {
    try {
        const resetPasswordResponse = await graphqlServiceAuthSauce(ResetPassword, passwordData);

        if (resetPasswordResponse.data && resetPasswordResponse.data.data.resetPassword) {
            const resetPassword = resetPasswordResponse.data.data.resetPassword;
            return resetPassword;
        } else {
            if (resetPasswordResponse.data.errors) {
                const error = resetPasswordResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const addUpgrades = async (upgradesData) => {
    try {
        const addUpgradeResponse = await graphqlServiceAuthSauce(AddUpgrade, upgradesData);
        console.log(addUpgradeResponse);

        if (addUpgradeResponse.data.data && addUpgradeResponse.data.data.addUpgrade) {
            const addUpgrade = addUpgradeResponse.data.data.addUpgrade;
            return addUpgrade;
        } else {
            if (addUpgradeResponse.data.errors) {
                const error = addUpgradeResponse.data.errors[0].message;

                return error;
            }
        }
    } catch (error) {
        return false;
    }
};

export const setupAccountInitialRoute = (
    history,
    searchParams,
    subscriptions,
    saveOneTimeGift,
    resetSubscriptionStore
) => {
    const isResetPassword = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.RESET);
    const isMigration = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.MIGRATION);
    const isEmailValidation = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.EMAIL_VALIDATION);
    const isSubscribe = searchParams.some((param) => param === CONSTANTS.INITAL_PARAMS.SUBSCRIBE);
    const giftType = searchParams.find((param) => param.includes("giftType"));
    const upgradeType = searchParams.find((param) => param.includes("upgradeType"));
    const planType = searchParams.find((param) => param.includes("planType"));
    const parent = searchParams.find((param) => param.includes("parent"));

    if (isResetPassword) {
        history.push(ROUTES.RESET_PASSWORD, {
            searchParams: searchParams,
            accountRoutes: "manage-subscription",
        });
    } else if (isEmailValidation) {
        history.push(ROUTES.EMAIL_VALIDATION, {
            searchParams: searchParams,
            accountRoutes: "manage-subscription",
        });
    } else if (isMigration) {
        history.push(ROUTES.LOGIN, {
            accountRoutes: "manage-subscription",
            showModal: true,
        });
    } else if (isSubscribe) {
        resetSubscriptionStore();
        history.push(ROUTES.STEP_ONE, { from: 'website' });

        if (giftType) {
            saveOneTimeGift(giftType.split("=")[1], null);
        }
        if (upgradeType) {
            saveOneTimeGift(null, upgradeType.split("=")[1]);
            if (parent) {
                setLocalStorageItem(LOCALSTORAGE.PARENT, parent.split("=")[1]);
            }
        }

        if (planType) {
            setLocalStorageItem(LOCALSTORAGE.PLAN_TYPE, planType.split("=")[1]);
        }
    } else {
        if (!subscriptions.step) {
            if (![ROUTES.SUCCESSFUL].includes(history.location.pathname)) {
                history.push(ROUTES.SUBSCRIPTION_SUMMARY);
            }
        } else {
            if (history.location.pathname.includes(ROUTES.SUBSCRIBE)) {
                history.push(`${ROUTES.SUBSCRIBE}step-${subscriptions.step}`);
            } else {
                if (![ROUTES.SIGN_UP, ROUTES.LOGIN, ROUTES.SUCCESSFUL].includes(history.location.pathname)) {
                    history.push(ROUTES.SUBSCRIPTION_SUMMARY);
                }
            }
        }
    }
};
