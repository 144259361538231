import React from "react";
import { Header, Divider } from "semantic-ui-react";
import { BoxSubHeader, DefaultButton, BackButton, DonateBoxCards } from "../..";
import * as CONSTANTS from "../../../constants/Global";

function CancelSubscriptionComponent(props) {
    const { history, subscription, goToCancelSurvey } = props;

    const subheaderText = "Cancel Subscription";
    const cancelMessage =
        "If you cancel, you may lose your member benefits, your remaining boxes will be shipped until that last one is sent.";
    const actionButtons = (
        <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
            <DefaultButton title="Continue cancelling" buttonAction={goToCancelSurvey} inverted={true} />
            <BackButton title="Back"/>
        </div>
    );
    // const noMoreBoxesCheck = (
    //     <CustomCheckbox
    //         id="noMoreBoxes"
    //         label="I don't want to continue receiving more boxes, I want to receive difference of money of my subscription."
    //         value={noMoreBoxes}
    //         onChangeAction={changeNoMoreBoxes}
    //     />
    // );

    const unshippedBoxes = subscription.boxes
        .map((box) => {
            return {
                ...box,
                parents: subscription.parents,
            };
        })
        .filter((box) => box.status === CONSTANTS.BOX_STATUS.UNSHIPPED)
        .sort((a, b) => Number(a.boxNumber) - Number(b.boxNumber));

    const monthBoxes = unshippedBoxes.filter((box) => box.boxType.toLowerCase() === CONSTANTS.BOX_TYPES.SUBSCRIPTION);
    const upgradeBoxes = unshippedBoxes.filter((box) => box.boxType.toLowerCase() === CONSTANTS.BOX_TYPES.UPGRADE);

    const remainingBoxes = monthBoxes.concat(upgradeBoxes);

    return (
        <section className="padding-page">
            <BoxSubHeader singleTitle={subheaderText} history={history} />
            <div className="manage-subscription-container">
                <p className="detault-paragraph">{cancelMessage}</p>
                <Header as="h3" content={`You still have ${remainingBoxes.length} boxes`} />
                <div className="remaining-boxes-content margin-bottom">
                    {remainingBoxes.map((box, index) => {
                        return <DonateBoxCards key={index} box={box} />;
                    })}
                </div>
                <Divider />
                {/* {noMoreBoxesCheck} */}
            </div>
            {actionButtons}
        </section>
    );
}

export default CancelSubscriptionComponent;
