import Subscribe from "../../screens/subscribe/Subscribe";
import StepOne from "../../screens/subscribe/StepOne";
import StepTwo from "../../screens/subscribe/StepTwo";
import StepThree from "../../screens/subscribe/StepThree";
import StepFour from "../../screens/subscribe/StepFour";
import StepFive from "../../screens/subscribe/StepFive";
import StepSix from "../../screens/subscribe/StepSix";
import Successful from "../../screens/subscribe/Successful";
import * as ROUTES from "../Routes";

export const SubscribeStack = [
    {
        path: ROUTES.SUBSCRIBE,
        component: Subscribe,
    },
    {
        path: ROUTES.STEP_ONE,
        component: StepOne,
    },
    {
        path: ROUTES.STEP_TWO,
        component: StepTwo,
    },
    {
        path: ROUTES.STEP_THREE,
        component: StepThree,
    },
    {
        path: ROUTES.STEP_FOUR,
        component: StepFour,
    },
    {
        path: ROUTES.STEP_FIVE,
        component: StepFive,
    },
    {
        path: ROUTES.STEP_SIX,
        component: StepSix,
    },
    {
        path: ROUTES.SUCCESSFUL,
        component: Successful,
    },
];
