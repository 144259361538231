import moment from "moment";
import Hebcal from "hebcal";
import * as CONSTANTS from "../constants/Global";
import GiftEvent from "../images/gift.svg";
import FathersDayEvent from "../images/fathers-day.svg";
import MothersDayEvent from "../images/mothers-day.svg";
import AnnyversaryEvent from "../images/anniversary-event.svg";
import ChristmasEvent from "../images/christmas.svg";
import BithdayImage from "../images/birthday-box.svg";
import FathersDayImage from "../images/fathersday-box.svg";
import MothersDayImage from "../images/mothersday-box.svg";
import AnnyversaryImage from "../images/anniversary-box.svg";
import ChristmasImage from "../images/christmas-box.svg";
import ChristmasCoupleImage from "../images/christmas-box-couple.svg";
import HanukkahImage from "../images/hanukkah-box.svg";
import HanukkahEvent from "../images/hanukkah.svg";
import ValentineImage from "../images/valentine.png";
import ValentineEvent from "../images/valentine-box.png";

export const upgradeBoxOptions = [
    {
        logoEvent: GiftEvent,
        tradename: "birthdayGrandma",
        image: BithdayImage,
    },
    {
        logoEvent: GiftEvent,
        tradename: "birthdayGrandpa",
        image: BithdayImage,
    },
    {
        logoEvent: FathersDayEvent,
        tradename: "fathersDay",
        image: FathersDayImage,
    },
    {
        logoEvent: MothersDayEvent,
        tradename: "mothersDay",
        image: MothersDayImage,
    },
    {
        logoEvent: AnnyversaryEvent,
        tradename: "anniversary",
        image: AnnyversaryImage,
    },
    {
        logoEvent: ChristmasEvent,
        tradename: "christmas",
        image: ChristmasImage,
    },
    {
        logoEvent: ChristmasEvent,
        tradename: "christmas",
        image: ChristmasCoupleImage,
    },
    {
        logoEvent: HanukkahEvent,
        tradename: "hanukkah",
        image: HanukkahImage,
    },
    {
        logoEvent: ValentineEvent,
        tradename: "valentineDay",
        image: ValentineImage,
    },
];

export const getValentineDay = (year) => {
    let currentYear = moment().format("YYYY");
    if (year) currentYear = year;
    const month = "02";
    const day = "14";

    const valentineDay = moment(`${currentYear}-${month}-${day}`).format("YYYY-MM-DD");

    return valentineDay;
};

export const getChristmasDay = (year) => {
    let currentYear = moment().format("YYYY");
    if (year) currentYear = year;
    const month = "12";
    const day = "25";

    const christmasDay = moment(`${currentYear}-${month}-${day}`).format("YYYY-MM-DD");

    return christmasDay;
};

export const getOriginalMonthersDay = (year) => {
    let currentYear = moment().format("YYYY");
    if (year) currentYear = year;
    const month = "05";
    const day = "01";
    const secondSunday = 14;

    const mothersDay = moment(`${currentYear}-${month}-${day}`).day(secondSunday).format("YYYY-MM-DD");

    return mothersDay;
};

export const getMonthersDay = (year) => {
    const currentDay = moment();
    let currentYear = moment().format("YYYY");
    if (year) currentYear = year;
    const month = "05";
    const day = "01";
    const secondSunday = 14;
    const thirdSundayDay = 21;
    const fourSundayDay = 28;

    let mothersDay = moment(`${currentYear}-${month}-${day}`).day(secondSunday).format("YYYY-MM-DD");
    let mothersDayValidation = moment(currentDay).isSameOrAfter(mothersDay);

    if (mothersDayValidation) {
        mothersDay = moment(`${currentYear}-${month}-${day}`).day(thirdSundayDay).format("YYYY-MM-DD");

        mothersDayValidation = moment(currentDay).isSameOrAfter(mothersDay);
    }

    if (mothersDayValidation) {
        mothersDay = moment(`${currentYear}-${month}-${day}`).day(fourSundayDay).format("YYYY-MM-DD");
    }

    return mothersDay;
};

export const getFathersDay = (year) => {
    let currentYear = moment().format("YYYY");
    if (year) currentYear = year;
    const month = "06";
    const day = "01";
    const thirdSundayDay = 21;

    const fathersDay = moment(`${currentYear}-${month}-${day}`).day(thirdSundayDay).format("YYYY-MM-DD");

    return fathersDay;
};

export const getHanukkahDay = () => {
    const jewishDate = new Hebcal();
    const jewishYear = jewishDate.next().year;
    const gregorianDate = new Hebcal.HDate(`25 Kislev ${jewishYear}`).greg();
    const hanukkahDay = moment(gregorianDate).format("YYYY-MM-DD");

    return hanukkahDay;
};

export const getShippingDateRanges = (date, isHannukah) => {
    let belowDay = moment(date).subtract(3, "days").format("YYYY-MM-DD");
    let aboveDay = moment(date).add(2, "days").format("YYYY-MM-DD");

    if (isHannukah) {
        belowDay = moment(date).format("YYYY-MM-DD");
        aboveDay = moment(date).add(8, "days").format("YYYY-MM-DD");
    }

    const dateRanges = {
        below: belowDay,
        above: aboveDay,
    };

    return dateRanges;
};

export const getSubscriptionRanges = (packages) => {
    const startDay = moment().startOf("M").format("YYYY-MM-DD");
    const endDay = moment()
        .endOf("M")
        .add(packages - 1, "M")
        .format("YYYY-MM-DD");

    const subscriptionRanges = {
        start: startDay,
        end: endDay,
    };

    return subscriptionRanges;
};

const fathersDay = getFathersDay();
const mothersDay = getMonthersDay();
const christmasDay = getChristmasDay();
const hanukkahDay = getHanukkahDay();
const valentineDay = getValentineDay();

export const getSpecialOccasionBoxes = (relationship, packages, grandmaBirthday, grandpaBirthday, anniversary) => {
    let availableUpgrades = [];

    switch (relationship.toUpperCase()) {
        case CONSTANTS.GRANDMA.toUpperCase():
            availableUpgrades = getGrandmaUpgrades(packages, grandmaBirthday);
            break;
        case CONSTANTS.GRANDPA.toUpperCase():
            availableUpgrades = getGrandpaUpgrades(packages, grandpaBirthday);
            break;
        case CONSTANTS.GRANDPARENTS.toUpperCase():
            availableUpgrades = getGrandparentsUpgrades(packages, grandmaBirthday, grandpaBirthday, anniversary);
            break;
        default:
            break;
    }

    const sortedByStatus = availableUpgrades.sort((a, b) => {
        const statusA = a.status;
        const statusB = b.status;

        if (statusA > statusB) {
            return 1;
        }
        if (statusB > statusA) {
            return -1;
        }
        return 0;
    });

    return sortedByStatus;
};

const getGrandmaUpgrades = (packages, grandmaBirthday) => {
    const showBirthdayBox = checkIfBoxAvailable(packages, grandmaBirthday);
    const showMothersBox = checkIfBoxAvailable(packages, mothersDay);
    const showChristmasBox = checkIfBoxAvailable(packages, christmasDay);
    const showHanukkahBox = checkIfBoxAvailable(packages, hanukkahDay, true);
    const showValentineDay = checkIfBoxAvailable(packages, valentineDay);

    const birthdayBoxData = Object.assign(showBirthdayBox, upgradeBoxOptions[0]);
    const mothersBoxData = Object.assign(showMothersBox, upgradeBoxOptions[3]);
    const christmasBoxData = Object.assign(showChristmasBox, upgradeBoxOptions[5]);
    const hanukkahBoxData = Object.assign(showHanukkahBox, upgradeBoxOptions[7]);
    const valentineBoxData = Object.assign(showValentineDay, upgradeBoxOptions[8]);

    const grandmaUpgradeList = [birthdayBoxData, mothersBoxData, christmasBoxData, hanukkahBoxData, valentineBoxData];

    return grandmaUpgradeList;
};

const getGrandpaUpgrades = (packages, grandpaBirthday) => {
    const showBirthdayBox = checkIfBoxAvailable(packages, grandpaBirthday);
    const showFathersBox = checkIfBoxAvailable(packages, fathersDay);
    const showChristmasBox = checkIfBoxAvailable(packages, christmasDay);
    const showHanukkahBox = checkIfBoxAvailable(packages, hanukkahDay, true);
    const showValentineDay = checkIfBoxAvailable(packages, valentineDay);

    const birthdayBoxData = Object.assign(showBirthdayBox, upgradeBoxOptions[1]);
    const fathersBoxData = Object.assign(showFathersBox, upgradeBoxOptions[2]);
    const christmasBoxData = Object.assign(showChristmasBox, upgradeBoxOptions[5]);
    const hanukkahBoxData = Object.assign(showHanukkahBox, upgradeBoxOptions[7]);
    const valentineBoxData = Object.assign(showValentineDay, upgradeBoxOptions[8]);

    const grandpaUpgradeList = [birthdayBoxData, fathersBoxData, christmasBoxData, hanukkahBoxData, valentineBoxData];

    return grandpaUpgradeList;
};

const getGrandparentsUpgrades = (packages, grandmaBirthday, grandpaBirthday, anniversary) => {
    const grandmaValidation = getGrandmaUpgrades(packages, grandmaBirthday);
    const grandpaValidation = getGrandpaUpgrades(packages, grandpaBirthday);
    const showAnniversaryBox = checkIfBoxAvailable(packages, anniversary);
    const showChristmasBox = checkIfBoxAvailable(packages, christmasDay);
    const showHanukkahBox = checkIfBoxAvailable(packages, hanukkahDay, true);
    const showValentineDay = checkIfBoxAvailable(packages, valentineDay);

    const anniversaryBoxData = Object.assign(showAnniversaryBox, upgradeBoxOptions[4]);
    const grandmaItems = grandmaValidation.filter(
        (data) => data.tradename !== "christmas" && data.tradename !== "hanukkah" && data.tradename !== "valentineDay"
    );
    const grandpaItems = grandpaValidation.filter(
        (data) => data.tradename !== "christmas" && data.tradename !== "hanukkah" && data.tradename !== "valentineDay"
    );
    const christmasBoxData = Object.assign(showChristmasBox, upgradeBoxOptions[6]);
    const hanukkahBoxData = Object.assign(showHanukkahBox, upgradeBoxOptions[7]);
    const valentineBoxData = Object.assign(showValentineDay, upgradeBoxOptions[8]);

    const grandParentsUpgradeList = [anniversaryBoxData, valentineBoxData, christmasBoxData, hanukkahBoxData].concat(
        grandpaItems,
        grandmaItems
    );

    return grandParentsUpgradeList;
};

export const checkIfBoxAvailable = (packages, occasion, isHannukah) => {
    const subscriptionRanges = getSubscriptionRanges(packages);
    const currentDay = moment();
    let upgradeData = null;

    const currentOccasion = `${moment().format("YYYY")}-${moment(occasion).format("MM")}-${moment(occasion).format(
        "DD"
    )}`;

    let nextOccation = `${moment().add(1, "y").format("YYYY")}-${moment(occasion).format("MM")}-${moment(
        occasion
    ).format("DD")}`;

    if (!moment(nextOccation).isValid()) {
        const dateSplited = occasion.split("/");
        const newOccation = `${dateSplited[0]}/${Number(dateSplited[1]) - 1}/${dateSplited[2]}`;

        nextOccation = `
	${moment().add(1, "y").format("YYYY")}-${moment(newOccation).format("MM")}-${moment(newOccation).format("DD")}`;
    }

    const checkCurrentOccasion = moment(currentOccasion).isBetween(subscriptionRanges.start, subscriptionRanges.end);
    const checkNextOccation = moment(nextOccation).isBetween(subscriptionRanges.start, subscriptionRanges.end);

    if (checkCurrentOccasion || checkNextOccation) {
        upgradeData = getAvailableItems(
            currentOccasion,
            currentDay,
            checkNextOccation,
            nextOccation,
            packages,
            isHannukah
        );
    } else {
        upgradeData = getUnavailableItems(currentOccasion, currentDay, nextOccation, packages, isHannukah);
    }

    return upgradeData;
};

const getAvailableItems = (currentOccasion, currentDay, checkNextOccation, nextOccation, packages, isHannukah) => {
    let upgradeData = null;
    let estimatedDelivery = null;
    let minDays = 2;
    let status = "available";
    let explanation = "";
    let chargedDay = "It will be charged today";
    let ocationYear = moment(currentOccasion).format("YYYY");
    let ocationDate = moment(currentOccasion).format("x");

    let isShippiableOnTime = moment(currentOccasion).diff(currentDay, "days") >= minDays;
    let isSameMonth = moment(currentOccasion).isSame(currentDay, "month");
    let shippingDateRanges = getShippingDateRanges(currentOccasion, isHannukah);

    if (checkNextOccation) {
        isShippiableOnTime = moment(nextOccation).diff(currentDay, "days") >= minDays;
        shippingDateRanges = getShippingDateRanges(nextOccation, isHannukah);
        ocationYear = moment(nextOccation).format("YYYY");
        ocationDate = moment(nextOccation).format("x");
    }

    const below = moment(shippingDateRanges.below).format("MMM D[th]");
    const above = moment(shippingDateRanges.above).format("MMM D[th]");

    if (isShippiableOnTime) {
        explanation = "Delivers between";
        estimatedDelivery = `${below} to ${above}`;
    } else {
        explanation = "It will be delivered on";

        if (packages > 1) {
            estimatedDelivery = moment(nextOccation).add(3, "days").format("MMM D[th]");

            if (isSameMonth) {
                ocationYear = moment(currentOccasion).format("YYYY");
                ocationDate = moment(currentOccasion).format("x");
            } else {
                ocationYear = moment(nextOccation).format("YYYY");
                ocationDate = moment(nextOccation).format("x");
            }
        } else {
            ocationYear = moment(nextOccation).format("YYYY");
            ocationDate = moment(nextOccation).format("x");
            if (isSameMonth) {
                estimatedDelivery = moment(nextOccation).add(5, "days").format("MMM D[th]");
            } else {
                status = "blocked";
                explanation = "They're different months";
                estimatedDelivery = `Box month is ${moment().format("MMMM")}`;
            }
        }
    }

    upgradeData = {
        status: status,
        availableDate: {
            text1: explanation,
            text2: estimatedDelivery,
            text3: chargedDay,
            ocationYear: ocationYear,
            ocationDate: ocationDate,
        },
    };

    return upgradeData;
};

const getUnavailableItems = (currentOccasion, currentDay, nextOccation, packages, isHannukah) => {
    let upgradeData = null;
    let finalDate = moment(currentOccasion);
    let monthDifference = finalDate.diff(currentDay, "M") + 2;
    let billingAvailable = Math.ceil(monthDifference / packages);
    let ocationYear = moment(currentOccasion).format("YYYY");
    let ocationDate = moment(currentOccasion).format("x");

    if (billingAvailable <= 1) {
        finalDate = moment(nextOccation);
        ocationYear = moment(nextOccation).format("YYYY");
        ocationDate = moment(nextOccation).format("x");
    }

    if (isNaN(billingAvailable)) {
        if (monthDifference === 1) {
            const dayDifference = finalDate.diff(currentDay, "days") + 1;
            if (dayDifference < 0) {
                monthDifference = 0;
            }
        }
    }

    const shippingDateRanges = getShippingDateRanges(currentOccasion, isHannukah);
    const below = moment(shippingDateRanges.below).format("MMM D[th]");
    const above = moment(shippingDateRanges.above).format("MMM D[th]");
    const estimatedDelivery = `${below} to ${above}`;

    upgradeData = {
        status: "blocked",
        availableDate: {
            text1: "Deliver between",
            text2: estimatedDelivery,
            text3: "It will be charged today",
            monthDifference: monthDifference,
            ocationDate: ocationDate,
            ocationYear: ocationYear,
        },
    };

    return upgradeData;
};

export const getUpgradeName = (upgradeType, parents) => {
    let upgradeName = null;

    switch (upgradeType) {
        case "birthdayGrandma":
            const grandma = parents.find((parent) => parent.parentType === CONSTANTS.GRANDMA.toUpperCase());
            if (grandma) {
                upgradeName = `${grandma.name}'s birthday`;
            } else {
                upgradeName = "birthdayGrandma";
            }
            break;
        case "birthdayGrandpa":
            const grandpa = parents.find((parent) => parent.parentType === CONSTANTS.GRANDPA.toUpperCase());
            if (grandpa) {
                upgradeName = `${grandpa.name}'s birthday`;
            } else {
                upgradeName = "birthdayGrandpa";
            }
            break;
        case "fathersDay":
            upgradeName = "Fathers' Day";
            break;
        case "mothersDay":
            upgradeName = "Mother's Day";
            break;
        case "anniversary":
            upgradeName = "Anniversary";
            break;
        case "christmas":
            upgradeName = "Christmas";
            break;
        case "christmasCouple":
            upgradeName = "Couple Christmas";
            break;
        case "birthday":
            upgradeName = "birthday";
            break;
        case "hanukkah":
            upgradeName = "Hanukkah";
            break;
        case "valentineDay":
            upgradeName = "Valentine";
            break;
        default:
            break;
    }

    return upgradeName;
};

export const upgradeTypeCompose = (upgrade, grandmaData) => {
    let upgradeComposed = "";

    if (upgrade === "birthday") {
        if (grandmaData.birthday) {
            upgradeComposed = `${upgrade}Grandma`;
        } else {
            upgradeComposed = `${upgrade}Grandpa`;
        }
    } else {
        upgradeComposed = upgrade;
    }
    return upgradeComposed;
};
