import React from "react";
import { Card, List } from "semantic-ui-react";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/components/_cards.scss";

export function SettingCard(props) {
    const { hasIcon, title, items, routerAction, type, showBenefits, changePhotosQuantity } = props;

    const itemKeys = Object.keys(items);
    const itemValues = Object.values(items);

    let editButton = null;

    if (hasIcon) {
        editButton = <span className="edit-button">Edit</span>;
    }

    let overlay = null;

    switch (type) {
        case "overlay":
            overlay = <div className="card-overlay" onClick={routerAction ? () => routerAction(items) : null} />;
            break;
        default:
            break;
    }

    let benefitsCard = null;
    let cardClasses = "settings-card";

    if (showBenefits) {
        cardClasses += " cards-together";
        let photosQuantity = 5;
        if (changePhotosQuantity) {
            photosQuantity = 4;
        }

        benefitsCard = (
            <Card className="settings-card benefits" fluid>
                <Card.Header>Information</Card.Header>
                <List bulleted>
                    {CONSTANTS.IMPORTANT_INFORMATION_TEXT.map((text, index) => {
                        if (index === 0) {
                            return (
                                <List.Item key={index}>
                                    <p>{text}</p>
                                </List.Item>
                            );
                        } else {
                            return <List.Item key={index}>{text}</List.Item>;
                        }
                    })}
                </List>
                <Card.Header>Remember your subscription benefits</Card.Header>
                <List bulleted>
                    {CONSTANTS.BENEFITS_TEXT.map((text, index) => {
                        const subscriptionAmount = items.unit_amount / 100;
                        const boxAmount = subscriptionAmount / items.packages;
                        const discountAmount = Math.round(100 - (boxAmount * 100) / CONSTANTS.GIFT_BOX_PRICE).toFixed(
                            0
                        );
                        let formattedText = text;

                        if (index === 0) {
                            formattedText = `You will get a box of GrandBox monthly with ${discountAmount}% discount + FREE shipping ($${
                                subscriptionAmount / items.packages
                            }/month by box) with your ${items.packages} month subscription.`;
                        }

                        if (index === 2) {
                            formattedText = `Personalize your box adding a personal note and ${photosQuantity} photos.`;
                        }

                        return <List.Item key={index}>{formattedText}</List.Item>;
                    })}
                </List>
            </Card>
        );
    }

    return (
        <>
            <Card className={cardClasses} fluid>
                {overlay}
                <Card.Content className="header-container">
                    <Card.Header>{title}</Card.Header>
                    {editButton}
                </Card.Content>
                <Card.Content>
                    {itemKeys.map((item, index) => {
                        if (!["packages", "unit_amount"].includes(item)) {
                            const itemSplited = item.split("_");
                            const itemName = itemSplited[0];
                            const itemClass = itemSplited[1];
                            let setting = null;
                            let dataClassName = "info-data";

                            if (itemClass === "link") {
                                dataClassName += " link";
                            }

                            switch (itemName) {
                                case "anniversary":
                                    break;
                                case "trackingLink":
                                    break;
                                case "Track your Box":
                                    if (itemValues[index] !== "0" && itemValues[3] === "SHIPPED") {
                                        setting = (
                                            <div key={index} className="info-container">
                                                <p className="info-title">{itemName}:</p>
                                                <p className={dataClassName}>
                                                    <a href={itemValues[2]} target="_blank" rel="noopener noreferrer">
                                                        {itemValues[index]}
                                                    </a>
                                                </p>
                                            </div>
                                        );
                                    }
                                    break;
                                default:
                                    setting = (
                                        <div key={index} className="info-container">
                                            <p className="info-title">{itemName}:</p>
                                            <p className={dataClassName}>{itemValues[index]}</p>
                                        </div>
                                    );
                                    break;
                            }

                            return setting;
                        } else {
                            return null;
                        }
                    })}
                </Card.Content>
            </Card>
            {benefitsCard}
        </>
    );
}
