import React from "react";
import { Header, Card } from "semantic-ui-react";
import "../../scss/components/_cards.scss";

export function PlanCard(props) {
    const { plan, selectedPlan, onSelection } = props;
    const { unit_amount, metadata } = plan;
    const { tradename } = metadata;

    const priceInfo = unit_amount / 100;
    const packagePrice = `$${priceInfo.toFixed(2)}`;
    let cardClasses = "plan-card";

    if (selectedPlan) {
        cardClasses = "plan-card selected-plan";
    }

    return (
        <Card className={cardClasses}>
            <div className="card-overlay" onClick={() => onSelection(plan)}></div>
            <Card.Content>
                <div className="card-content">
                    <div className="card-data-section">
                        <Header as="h4">{tradename}</Header>
                        <Card.Meta className="text-center">{packagePrice}</Card.Meta>
                    </div>
                </div>
            </Card.Content>
        </Card>
    );
}
