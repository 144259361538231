import React from "react";
import { Divider, Header, Image, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUpgradeName } from "../services/UpgradeBoxService";
import * as ROUTES from "../navigation/Routes";
import "../scss/components/_subheader.scss";

export function SubHeader(props) {
    const {
        mainTitle,
        specialText,
        secondSpecialText,
        subtitle,
        hasDivider,
        image,
        shortPadding,
        lesserMarginText,
        showBackButton,
        isBoxTitle,
    } = props;
    const subscriptions = useSelector((state) => state.subscriptions);
    const user = useSelector((state) => state.user);
    const history = useHistory();
    const pathName = history.location.pathname;
    const areAccountRoutes = pathName.includes(ROUTES.ACCOUNT);
    const isSummaryRoute = pathName.includes(ROUTES.SUBSCRIPTION_SUMMARY);

    let headerH1 = null;
    let headerH4 = null;
    let headerP = null;
    let divider = null;
    let secondText = null;
    let imageSection = null;
    let giftNameTitle = null;

    if (mainTitle) {
        let headerClassName = "subscribe-title text-center";
        if (lesserMarginText) {
            headerClassName += " margin-bottom-lesser ";
        }
        headerH1 = (
            <Header as="h1" className={headerClassName}>
                {mainTitle}
            </Header>
        );
    }

    if (secondSpecialText) {
        let className = "subscribe-text secondary-text text-center";
        if (lesserMarginText) {
            className += " margin-top-0 margin-bottom-0 subheader-simple lesser";
        }
        secondText = <p className={className}>{secondSpecialText}</p>;
    }

    if (specialText) {
        let className = "subscribe-text text-center";
        if (isBoxTitle) {
            className += " box-title";
        }
        if (lesserMarginText) {
            className += " margin-top-0 margin-bottom-0 subheader-simple";
        }
        headerP = (
            <>
                <p className={className}>{specialText}</p>
                {secondText}
                <br />
            </>
        );
    }

    if (subtitle) {
        headerH4 = (
            <Header as="h6" textAlign="left" className="subtitle">
                {subtitle}
            </Header>
        );
    }

    if (hasDivider) {
        divider = <Divider />;
    }

    if (image) {
        imageSection = (
            <>
                <br />
                <Image src={image} centered size="small" />
            </>
        );
    }

    if (subscriptions.giftType && !ROUTES.HIDE_GIFT_TITLE_ROUTES.includes(pathName)) {
        const giftInfo = `Gift - ${getUpgradeName(subscriptions.giftType)} box`;

        giftNameTitle = (
            <p className="color-cta">
                <strong>{giftInfo.toUpperCase()}</strong>
            </p>
        );
    }

    if (subscriptions.upgradeType && !ROUTES.HIDE_UPGRADE_TITLE_ROUTES.includes(pathName)) {
        const upgradeInfo = `Subscription with ${getUpgradeName(subscriptions.upgradeType)} box`;

        giftNameTitle = (
            <>
                <p className="color-cta gift-name-title">
                    <strong>{upgradeInfo.toUpperCase()}</strong>
                </p>
            </>
        );
    }

    let className = "sub-header-container align-left";

    if (areAccountRoutes) {
        className = "sub-header-container align-left container-padding-small";
    }

    if (shortPadding) {
        className += " short-padding";
    }

    let backButton = null;

    if (showBackButton) {
        let historyBack = () => history.goBack();

        if (pathName.includes("box-description")) {
            historyBack = () => history.replace(ROUTES.SUBSCRIPTION_SUMMARY, false);
        }

        backButton = <Button className="back-arrow" size="massive" icon="arrow left" onClick={historyBack}></Button>;
    }

    let userInfo = null;

    if (user.userData && isSummaryRoute) {
        className = "sub-header-container align-left sub-header-padding-small";

        headerH1 = (
            <Header as="h1" className="subscribe-title text-left">
                {mainTitle}
            </Header>
        );
    }

    return (
        <div className={className}>
            <div className="sub-header">
                {headerH1}
                {headerP}
                {imageSection}
                {userInfo}
                {giftNameTitle}
                {backButton}
            </div>

            {divider}
            {headerH4}
        </div>
    );
}
