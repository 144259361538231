import React from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { Label } from "semantic-ui-react";
import "../../scss/components/_input.scss";

export function CustomCardInput(props) {
    const {
        label,
        type,
        placeholder,
        onChangeAction,
        cardNumberEmpty,
        cardExpiryEmpty,
        cardCvcEmpty,
        errorMessage,
    } = props;

    const CARD_OPTIONS = {
        placeholder: placeholder,
        showIcon: true,
        iconStyle: "solid",
        style: {
            base: {
                iconColor: "#b98b55",
                color: "rgba(0, 0, 0, 0.87)",
                fontSize: "18px",
                "::placeholder": {
                    color: "rgba(0, 0, 0, 0.25)",
                },
            },
            invalid: {
                iconColor: "#d2142d",
                color: "#d2142d",
            },
        },
    };

    let inputLabel = null;
    let element = null;

    if (type === "expiration") {
        if (!cardExpiryEmpty) {
            inputLabel = <Label floating>{label}</Label>;
        }

        delete CARD_OPTIONS.showIcon
        delete CARD_OPTIONS.iconStyle
        element = <CardExpiryElement options={CARD_OPTIONS} onChange={onChangeAction} />;
    } else if (type === "cvv") {
        if (!cardCvcEmpty) {
            inputLabel = <Label floating>{label}</Label>;
        }

        delete CARD_OPTIONS.showIcon
        delete CARD_OPTIONS.iconStyle
        element = <CardCvcElement options={CARD_OPTIONS} onChange={onChangeAction} />;
    } else {
        if (!cardNumberEmpty) {
            inputLabel = <Label floating>{label}</Label>;
        }

        CARD_OPTIONS.disableLink = true
        element = <CardNumberElement options={CARD_OPTIONS} onChange={onChangeAction} />;
    }

    let className = "input-content bordered";
    let errorInfo = null;

    const showError = errorMessage && errorMessage.length > 0;

    if (showError) {
        errorInfo = <p className="input-error">{errorMessage}</p>;
        className += " input-error";
    }

    return (
        <div className={className}>
            {inputLabel}
            {element}
            {errorInfo}
        </div>
    );
}
