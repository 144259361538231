export const FOUR_PHOTOS_DATE = "2021-03-03";
export const DISCOUNT_AMOUNT = 10;
export const ENV_DEVELOPMENT = "development";
export const ENV_PRODUCTION = "production";
export const GRANDMA = "grandma";
export const GRANDPA = "grandpa";
export const GRANDPARENTS = "grandparents";
export const IMPORTANT_INFORMATION_TEXT = [
    "Your first box will be shipped in 5 days. You must add photos and the note within the next 2 days for your first box.",
    "The following boxes will be shipped 15th each month.",
];
export const BENEFITS_TEXT = ["discount text", "Every month a different theme for your GrandBox.", "photosQuantity"];

export const GIFT_BOX_PRICE = 59.99;

export const OPTIONS_SELECT = [
    {
        key: 0,
        text: "Grandma",
        value: "grandma",
    },
    {
        key: 1,
        text: "Grandpa",
        value: "grandpa",
    },
    {
        key: 2,
        text: "Grandparents",
        value: "grandparents",
    },
];
export const DIETARY_OPTIONS = [
    {
        key: 1,
        text: "No restriction",
        value: "UNRESTRICTED",
    },
    {
        key: 2,
        text: "Diabetic diet",
        value: "DIABETIC_DIET",
    },
    {
        key: 3,
        text: "Gluten free diet",
        value: "GLUTEN_FREE",
    },
    {
        key: 4,
        text: "Lactose diet",
        value: "LACTOSE_FREE",
    },
    {
        key: 5,
        text: "Low-sodium diet",
        value: "LOW_SODIUM_DIET",
    },
];
export const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const SUBSCRIPTION_STATUS = {
    ACTIVE: "ACTIVE",
    CANCELLED: "CANCELLED",
    INCOMPLETE: "INCOMPLETE",
    PAUSED: "PAUSED",
};
export const SUBSCRIPTION_TYPES = {
    ONE_TIME: "one_time",
};
export const BOX_TYPES = {
    UPGRADE: "upgrade",
    SUBSCRIPTION: "subscription",
    GIFT: "gift",
};
export const BOX_STATUS = {
    SHIPPED: "SHIPPED",
    UNSHIPPED: "UNSHIPPED",
    CANCELLED: "CANCELLED",
    DONATED: "DONATED",
    INCOMPLETE: "INCOMPLETE",
};
export const FEATURES_STATUS = {
    EMPTY: "EMPTY",
    FILLED: "FILLED",
    SKIPPED: "SKIPPED",
};
export const CANCEL_TYPES = {
    DONATE: "donate",
    CANCEL: "cancel",
};
export const CANCEL_SUBSCRIPTIONS_REASONS = [
    {
        text: "It's to expensive",
        value: "EXPENSIVE",
    },
    {
        text: "I'm using another service",
        value: "OTHER_SERVICE",
    },
    {
        text: "Death of my loved one",
        value: "DEATH_LOVED_ONE",
    },
    {
        text: "I am having technical problems",
        value: "TECHNICAL_PROBLEMS",
    },
    {
        text: "I had a bad service",
        value: "BAD_SERVICE",
    },
    {
        text: "It was not the quality we expected",
        value: "LACK_QUALITY",
    },
    {
        text: "Other reason",
        value: "OTHER",
    },
];
export const PAUSE_SUBSCRIPTION_TIME = [
    {
        text: "30 days",
        value: 30,
    },
    {
        text: "60 days",
        value: 60,
    },
    {
        text: "90 days",
        value: 90,
    },
];
export const CANCEL_SUBSCRIPTIONS_OPTIONS = {
    DISCOUNT: "DISCOUNT",
    REFOUND: "REFUND",
    DONATE: "DONATE",
    FINISH: "FINISH",
    PAUSE: "PAUSE",
};
export const SUBSCRIPTIONS_PLANS = {
    TWELVE_MONTH: "12 Month Prepay",
    SIX_MONTH: "6 Month Prepay",
    THREE_MONTH: "3 Month Prepay",
    MONTH_TO_MONTH: "Month to Month",
};
export const INITAL_PARAMS = {
    RESET: "mode=resetPassword",
    SUBSCRIBE: "mode=subscribe",
    MIGRATION: "mode=migration",
    EMAIL_VALIDATION: "mode=verifyEmail",
};
export const ONE_TIME_GIFT_OPTIONS = ["anniversary", "birthday", "fathersDay", "mothersDay"];
export const INVOICE_STATUS = {
    PAID: "paid",
    UNPAID: "invoice_unpaid",
};
export const MAX_FEATURES_UPDATE_DAY = 11;
