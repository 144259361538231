import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { ShippingAddressForm, ErrorModal } from "../../components";
import { saveUserSubscriptions } from "../../store/user/actions";
import {
    validateInputFilled,
    phoneNumberValidator,
    getFormattedPhoneNumber,
} from "../../services/DataValidationService";
import { waitForGlobal } from "../../services/LoadScriptService";
import { returnAddressInfoList } from "../../services/GoogleService";
import { updateBox } from "../../services/SetupService";
import { updateBoxSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import * as ERRORS from "../../constants/Errors";
import "../../scss/pages/get_a_box.scss";
const inputsIds = [
    { name: "localFirstName", error: "Please enter First Name" },
    { name: "localLastName", error: "Please enter Last Name" },
    { name: "localDeliveryPlace", error: "Please enter a Street Address" },
    { name: "localDeliveryPlace", error: "Please enter a Street Address" },
    { name: "localCity", error: "Please enter a City" },
    { name: "localState", error: "Please enter a State" },
    { name: "localPostalCode", error: "Please enter a Postal Code" },
];
let localGeometry = null;

class ShippingAddress extends Component {
    constructor(props) {
        super(props);

        const shippingAddress = this.props.location.state.address;

        const {
            firstName,
            lastName,
            country,
            city,
            state,
            address,
            apt,
            postalCode,
            phoneNumber,
            geometry,
        } = shippingAddress;

        const localFirstName = firstName.length > 0 ? firstName : "";
        const localLastName = lastName.length > 0 ? lastName : "";
        const localPhoneNumber = phoneNumber && phoneNumber.length > 0 ? phoneNumber : "";
        const localCountry = country.length > 0 ? country : "";
        const localCity = city.length > 0 ? city : "";
        const localState = state.length > 0 ? state : "";
        const localDeliveryPlace = address.length > 0 ? address : "";
        const localApt = apt && apt.length > 0 ? apt : "";
        const localPostalCode = postalCode.length > 0 ? postalCode : "";

        let showAddressMap = false;

        if (geometry && Object.keys(geometry).length > 0) {
            showAddressMap = true;
            localGeometry = geometry;
        }

        this.state = {
            ...this.state,
            localFirstName: localFirstName,
            localLastName: localLastName,
            localPhoneNumber: localPhoneNumber,
            localCountry: localCountry,
            localCity: localCity,
            localState: localState,
            localDeliveryPlace: localDeliveryPlace,
            localApt: localApt,
            localPostalCode: localPostalCode,
            localGeometry: localGeometry,
            localFirstNameError: false,
            localLastNameError: false,
            localDeliveryPlaceError: false,
            localPhoneNumberError: false,
            showAddressMap: showAddressMap,
            loadingUpdate: false,
            localCityError: false,
            localStateError: false,
            localPostalCodeError: false,
            errorUpdatingBox: "",
            writedAddress: false,
        };
    }

    componentDidMount() {
        this.getMissingGeometry(this.state.localDeliveryPlace);
    }

    componentDidUpdate(lastProps) {
        const loacationState = this.props.location.state;

        if (lastProps.location.state !== loacationState) {
            const {
                firstName,
                lastName,
                country,
                city,
                state,
                address,
                apt,
                postalCode,
                phoneNumber,
                geometry,
            } = loacationState.address;

            const localFirstName = firstName.length > 0 ? firstName : "";
            const localLastName = lastName.length > 0 ? lastName : "";
            const localPhoneNumber = phoneNumber && phoneNumber.length > 0 ? phoneNumber : "";
            const localCountry = country.length > 0 ? country : "";
            const localCity = city.length > 0 ? city : "";
            const localState = state.length > 0 ? state : "";
            const localDeliveryPlace = address.length > 0 ? state : "";
            const localApt = apt && apt.length > 0 ? apt : "";
            const localPostalCode = postalCode.length > 0 ? postalCode : "";

            let showAddressMap = false;
            let localGeometry = null;

            if (geometry && Object.keys(geometry).length > 0) {
                showAddressMap = true;
                localGeometry = geometry;
            }

            this.setState({
                localFirstName: localFirstName,
                localLastName: localLastName,
                localCountry: localCountry,
                localCity: localCity,
                localState: localState,
                localDeliveryPlace: localDeliveryPlace,
                localApt: localApt,
                localPostalCode: localPostalCode,
                localPhoneNumber: localPhoneNumber,
                localGeometry: localGeometry,
                showAddressMap: showAddressMap,
            });
        }
    }

    onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;
        const blockedInputs = ["localCity", "localPostalCode", "localState"];

        if (this.state.writedAddress) {
            this.setState({
                [inputId]: value,
                [`${inputId}Error`]: false,
            });

            if (inputId === "localDeliveryPlace") {
                this.getMissingGeometry(value);
            }
        } else {
            if (!blockedInputs.includes(inputId)) {
                this.setState({
                    [inputId]: value,
                    [`${inputId}Error`]: false,
                });
            }
        }
    };

    onChangeSelectOptions = (data) => {
        const inputId = data.id;

        this.setState({
            [inputId]: data.value,
            [`${inputId}Error`]: false,
        });
    };

    saveShippingAddress = async () => {
        const { history, user, saveUserSubscriptions } = this.props;
        const boxData = this.props.location.state;

        const {
            localFirstName,
            localLastName,
            localCountry,
            localCity,
            localState,
            localDeliveryPlace,
            localApt,
            localPostalCode,
            localPhoneNumber,
            localGeometry,
        } = this.state;

        const formattedPhoneNumber = getFormattedPhoneNumber(localPhoneNumber);

        const dataUpload = {
            input: {
                boxId: boxData.boxId,
                shippingAddress: {
                    firstName: localFirstName,
                    lastName: localLastName,
                    country: localCountry,
                    city: localCity,
                    state: localState,
                    address: localDeliveryPlace,
                    apt: localApt,
                    postalCode: localPostalCode,
                    phoneNumber: formattedPhoneNumber,
                    geometry: localGeometry,
                },
            },
        };

        const inputsRequiredAreFilled = this.checkInputsRequiredAreFilled();

        if (inputsRequiredAreFilled) {
            this.setState({
                loadingUpdate: true,
            });
            delete dataUpload.input.shippingAddress.geometry.bounds;

            const boxUpdated = await updateBox(dataUpload);

            if (boxUpdated) {
                const subscriptions = user.subscriptions;
                const updated = updateBoxSubscriptionsStore(saveUserSubscriptions, subscriptions, boxUpdated);

                if (updated) {
                    history.goBack();
                }
            } else {
                console.log(boxUpdated);
                this.setState({
                    errorUpdatingBox: ERRORS.ERROR_UPDATING_SHIPPING_ADDRESS,
                    loadingUpdate: false,
                });
            }
        }
    };

    checkInputsRequiredAreFilled = () => {
        const inputsFilledList = this.checkIfInputAreFilledAndCreateList();
        const quantityInputsFilled = inputsFilledList.filter((isFilled) => isFilled === true);

        if (quantityInputsFilled.length === inputsIds.length) {
            return true;
        }
        return false;
    };

    checkIfInputAreFilledAndCreateList = () => {
        const inputsFilledQuantity = [];
        inputsIds.forEach((inputId) => {
            const inputFilled = validateInputFilled(this.state[inputId.name]);
            if (inputId.name !== "localApt" && inputId.name !== "localPhoneNumber") {
                inputsFilledQuantity.push(inputFilled);
            }

            if (!this.state.writedAddress) {
                if (
                    (inputId.name === "localCity" && !inputFilled) ||
                    (inputId.name === "localPostalCode" && !inputFilled) ||
                    (inputId.name === "localState" && !inputFilled)
                ) {
                    const inputFilled = validateInputFilled(this.state["localDeliveryPlace"]);

                    if (inputFilled) {
                        this.setState({
                            localDeliveryPlaceError: "Sorry, that address is not valid",
                        });
                    }
                }
            }

            if (inputId.name === "localPhoneNumber" && inputFilled) {
                const isValidPhoneNumber = phoneNumberValidator(this.state[inputId.name]);

                if (isValidPhoneNumber) {
                    inputsFilledQuantity.push(inputFilled);
                } else {
                    this.setState({
                        localPhoneNumberError: "Invalid phone number",
                    });
                }
            }

            if (!inputFilled) {
                this.setState({
                    [`${inputId.name}Error`]: inputId.error,
                });
            }
        });

        return inputsFilledQuantity;
    };

    onSelectedPlace = (selectedPlace) => {
        const addressComponents = selectedPlace.address_components;
        const geometry = selectedPlace.geometry;

        if (geometry) {
            this.showMapInformation(geometry);
        }

        if (addressComponents) {
            this.updateFormAddressItems(addressComponents);
        } else {
            if (selectedPlace.name) {
                this.setState({
                    localDeliveryPlace: selectedPlace.name,
                    localDeliveryPlaceError: "Sorry, that address is not valid",
                });
            } else {
                const inputId = selectedPlace.target.id;
                const value = selectedPlace.target.value;
                this.setState({
                    [inputId]: value,
                    showAddressMap: false,
                    localDeliveryPlaceError: "",
                });

                if (value === "") {
                    this.setState({
                        localCity: "",
                        localState: "",
                        localPostalCode: "",
                    });
                }
            }
        }
    };

    updateFormAddressItems = (addressComponents) => {
        const address = returnAddressInfoList(addressComponents);
        this.setState({
            localCity: address.city,
            localState: address.state,
            localDeliveryPlace: address.address,
            localPostalCode: address.postalCode,
            localDeliveryPlaceError: false,
            localPostalCodeError: false,
        });
    };

    showMapInformation = (geometry) => {
        this.setState({
            showAddressMap: true,
            localGeometry: geometry,
        });
    };

    resetUpdateError = () => {
        this.setState({
            errorUpdatingBox: "",
        });
    };

    writedAddressManually = (event) => {
        event.preventDefault();

        this.setState((state, props) => ({
            writedAddress: !state.writedAddress,
            localCity: "",
            localState: "",
            localDeliveryPlace: "",
            localPostalCode: "",
            showAddressMap: false,
            localFirstNameError: false,
            localLastNameError: false,
            localDeliveryPlaceError: false,
            localPhoneNumberError: false,
            localCityError: false,
            localStateError: false,
            localPostalCodeError: false,
        }));
    };

    getMissingGeometry = (localDeliveryPlace) => {
        const { localCity, localState, localPostalCode } = this.state;
        let self = this;

        const googleIsLoaded = waitForGlobal("google");

        googleIsLoaded.then((isLoaded) => {
            if (isLoaded && localDeliveryPlace) {
                const placeToFind = `${localDeliveryPlace} ${localCity} ${localState} ${localPostalCode}`;

                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ address: placeToFind }, function (results, status) {
                    if (status === "OK") {
                        const geometry = results[0].geometry;
                        delete geometry["location_type"];

                        self.setState({
                            localGeometry: geometry,
                            showAddressMap: true,
                        });
                    } else {
                        console.log("Geocode was not successful for the following reason: " + status);
                    }
                });
            }
        });
    };

    render() {
        const { history, location } = this.props;
        const { parents, name, donationTitle } = location.state;

        let parentsNames = [];
        let parentsString = "";
        let subHeaderType = "box";

        if (donationTitle) {
            subHeaderType = "donation";
        }

        if (parents && !donationTitle) {
            parentsNames = parents.map((parent) => {
                return parent.name;
            });

            parentsString = parentsNames.join(" or ");
        }

        let errorModal = null;

        if (this.state.errorUpdatingBox !== "") {
            errorModal = (
                <ErrorModal
                    open={this.state.errorUpdatingBox !== ""}
                    message={this.state.errorUpdatingBox}
                    closeFunction={this.resetUpdateError}
                />
            );
        }

        return (
            <>
                {errorModal}

                <Container>
                    <ShippingAddressForm
                        parentsString={parentsString}
                        state={this.state}
                        onChangeWriteOptions={this.onChangeWriteOptions}
                        onChangeSelectOptions={this.onChangeSelectOptions}
                        onSelectedPlace={this.onSelectedPlace}
                        submitAction={this.saveShippingAddress}
                        submitButtonTitle="Save"
                        subHeaderType={subHeaderType}
                        boxSection="Shipping"
                        donationTitle={donationTitle}
                        boxName={name}
                        history={history}
                        writedAddressManually={this.writedAddressManually}
                        showBackButton={true}
                    />
                </Container>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    saveUserSubscriptions: (subscriptionsData) => dispatch(saveUserSubscriptions(subscriptionsData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(ShippingAddress));
