import React, { useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import StepThreeComponent from "../../components/screenComponents/subscribe/StepThreeComponent";
import { saveStepThree } from "../../store/subscriptions/actions";
import * as CONSTANTS from "../../constants/Global";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/pages/get_a_box.scss";
import "../../scss/components/_utilities.scss";

function StepThree(props) {
    const { subscriptions, history, saveStepThree } = props;
    const { grandpaData, grandmaData, relationship, giftType } = subscriptions;

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        grandpaDiet: [],
        grandmaDiet: [],
        grandpaDietError: false,
        grandmaDietError: false,
    });

    useEffect(() => {
        const grandpaDiet = grandpaData.hasOwnProperty("dietaryRestriction") ? grandpaData.dietaryRestriction : [];
        const grandmaDiet = grandmaData.hasOwnProperty("dietaryRestriction") ? grandmaData.dietaryRestriction : [];

        setStateForm({
            grandpaDiet: grandpaDiet,
            grandmaDiet: grandmaDiet,
        });
    }, [grandmaData, grandpaData]);

    const onChangeDietaryAction = (data) => {
        const personId = data.id;

        setStateForm({
            [personId]: data.value,
        });
    };

    const saveStepThreeAndNext = () => {
        if (relationship === CONSTANTS.GRANDPARENTS) {
            const checkGrandmaDiet = checkGrandmaInput();
            const checkGrandPaDiet = checkGrandpaInput();

            if (checkGrandmaDiet && checkGrandPaDiet) {
                routeNextPage();
            }
        } else {
            if (relationship === CONSTANTS.GRANDMA) {
                const checkGrandmaDiet = checkGrandmaInput();
                if (checkGrandmaDiet) {
                    routeNextPage();
                }
            } else {
                const checkGrandPaDiet = checkGrandpaInput();
                if (checkGrandPaDiet) {
                    routeNextPage();
                }
            }
        }
    };

    const routeNextPage = () => {
        if (giftType) {
            history.push(ROUTES.STEP_SIX);
        } else {
            history.push(ROUTES.STEP_FOUR);
        }
    };

    const checkGrandmaInput = () => {
        const { grandmaDiet } = formState;

        let dietary = [];
        if (typeof grandmaDiet === "string") {
            dietary = [grandmaDiet];
        } else {
            dietary = grandmaDiet;
        }

        Object.assign(grandmaData, {
            dietaryRestriction: dietary,
        });

        if (grandmaDiet.length > 0) {
            saveStepThree({
                grandmaData: grandmaData,
            });
            return true;
        } else {
            setStateForm({
                grandmaDietError: true,
            });
            return false;
        }
    };

    const checkGrandpaInput = () => {
        const { grandpaDiet } = formState;

        let dietary = [];
        if (typeof grandpaDiet === "string") {
            dietary = [grandpaDiet];
        } else {
            dietary = grandpaDiet;
        }

        Object.assign(grandpaData, {
            dietaryRestriction: dietary,
        });

        if (grandpaDiet.length > 0) {
            saveStepThree({
                grandpaData: grandpaData,
            });
            return true;
        } else {
            setStateForm({
                grandpaDietError: true,
            });
            return false;
        }
    };

    return (
        <>
            <Container>
                <StepThreeComponent
                    state={formState}
                    history={history}
                    subscriptions={subscriptions}
                    saveStepThreeAndNext={saveStepThreeAndNext}
                    onChangeDietaryAction={onChangeDietaryAction}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepThree: (stepThree) => dispatch(saveStepThree(stepThree)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepThree));
