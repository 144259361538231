import { combineReducers } from 'redux';
import SubscriptionsReducer from './subscriptions';
import UserReducer from './user';
import BusinessReducer from './business';

const todoApp = combineReducers({
	subscriptions: SubscriptionsReducer,
	user: UserReducer,
	business: BusinessReducer
});

export default todoApp;
