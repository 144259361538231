import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { Loader, ErrorModal, SuccessModal } from "../../components";
import moment from "moment";
import UpgradeMarketComponent from "../../components/screenComponents/account/UpgradeMarketComponent";
import NoSubscriptionsComponent from "../../components/screenComponents/account/NoSubscriptionsComponent";
import { getSpecialOccasionBoxes } from "../../services/UpgradeBoxService";
import { getOrdersSummaryData, getPricesIds, renewSessionId } from "../../services/SubscriptionPlanService";
import { updateSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import { getLocalStorageItem, setLocalStorageItem } from "../../services/LocalStorageService";
import { saveStepFive, saveStepFour } from "../../store/subscriptions/actions";
import { saveUserSubscriptions } from "../../store/user/actions";
import { addUpgrades } from "../../services/SetupService";
import { SELECTED_SUBSCRIPTION } from "../../constants/LocalStorage";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import * as ERRORS from "../../constants/Errors";
import "../../scss/pages/get_a_box.scss";

function UpgradeMarket(props) {
    const { history, upgrades, plans, user, saveUserSubscriptions } = props;
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [selectedUpgrades, setSelectedUpgrades] = useState([]);
    const [upgradeOptions, setUpgradeOptions] = useState([]);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [grandmaData, setGrandmaData] = useState(null);
    const [grandpaData, setGrandpaData] = useState(null);
    const [showUpgradesEmpty, setShowUpgradesEmpty] = useState(false);
    const [ordersSummaryData, setOrdersSummaryData] = useState([]);
    const [loadingUpgradesBuy, setLoadingUpgradesBuy] = useState(false);
    const [errorMessage, setErrorMeessage] = useState("");
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [refreshSelectedSubscription, setRefreshSelectedSubscription] = useState(true);

    useEffect(() => {
        const selectedSubscription = getLocalStorageItem(SELECTED_SUBSCRIPTION);

        if (selectedSubscription && refreshSelectedSubscription) {
            const grandpaData = selectedSubscription.parents.find(
                (parent) => parent.parentType === CONSTANTS.GRANDPA.toUpperCase()
            );
            const grandmaData = selectedSubscription.parents.find(
                (parent) => parent.parentType === CONSTANTS.GRANDMA.toUpperCase()
            );
            setGrandmaData(grandmaData);
            setGrandpaData(grandpaData);
            setSelectedSubscription(selectedSubscription);
            setRefreshSelectedSubscription(false);
        }
    }, [refreshSelectedSubscription]);

    useEffect(() => {
        if (selectedSubscription && upgrades.length > 0) {
            const planPackages = Number(selectedSubscription.billingSubscription.replace(/\D+/g, ""));
            const grandpaData = selectedSubscription.parents.find(
                (parent) => parent.parentType === CONSTANTS.GRANDPA.toUpperCase()
            );
            const grandmaData = selectedSubscription.parents.find(
                (parent) => parent.parentType === CONSTANTS.GRANDMA.toUpperCase()
            );

            const specialOcationBoxes = getSpecialOccasionBoxes(
                selectedSubscription.relationship,
                planPackages,
                grandmaData?.birthday,
                grandpaData?.birthday,
                grandmaData?.anniversary || grandpaData?.anniversary
            );

            const getUpgradePrices = (upgrades) => {
                const customUpgrades = upgrades.map((upgrade) => {
                    const type = selectedSubscription.relationship === CONSTANTS.GRANDPARENTS ? "couple" : "single";
                    return {
                        ...upgrade,
                        price: upgrade.prices.find(
                            (price) => price.metadata.priceType === type && Boolean(price.metadata.upgrade)
                        ),
                        priceId: upgrade.prices.find(
                            (price) => price.metadata.priceType === type && Boolean(price.metadata.upgrade)
                        ).id,
                    };
                });

                return customUpgrades;
            };

            const customUpgrades = getUpgradePrices(upgrades);
            const upgradeOptions = specialOcationBoxes
                .map((x) =>
                    Object.assign(
                        x,
                        customUpgrades.find((y) => x.tradename.includes(y.price.metadata.upgradeType))
                    )
                )
                .filter((upgrade) => upgrade.status !== "blocked")
                .filter((upgrade) => {
                    const currentDay = moment();
                    const boxMonth = selectedSubscription.boxes.find((box) => {
                        const samePreviousUpgrade = box.upgrades.find(
                            (boxUpgrade) => boxUpgrade.upgradeType === upgrade.tradename
                        );
                        const upgradeMonth = moment(Number(upgrade.availableDate.ocationDate)).format("M");
                        const boxMonth = moment(box.deliverDate).format("M");
                        return upgradeMonth === boxMonth && !samePreviousUpgrade;
                    });

                    if (boxMonth) {
                        if (boxMonth.status === CONSTANTS.BOX_STATUS.UNSHIPPED) {
                            if (boxMonth?.welcomeBox) {
                                const deliverDateThreeDaysLess = moment(boxMonth?.deliverDate).subtract(3, "days");

                                const showUpgrede = moment(currentDay).isBefore(deliverDateThreeDaysLess);

                                if (showUpgrede) {
                                    return upgrade;
                                }
                            } else {
                                const upgradeDate = moment(Number(upgrade.availableDate.ocationDate));
                                const deliverDateThreeDaysLess = moment(upgradeDate).subtract(2, "days");
                                const dayValidation = moment(currentDay).isBefore(deliverDateThreeDaysLess);

                                const isSameMonth =
                                    Number(moment(Number(upgrade.availableDate.ocationDate)).format("M")) <=
                                    Number(moment(currentDay).format("M"));

                                const showUpgrede =
                                    !isSameMonth ||
                                    (isSameMonth && Number(moment(currentDay).format("D")) < 11) ||
                                    (isSameMonth && dayValidation);

                                if (showUpgrede) {
                                    return upgrade;
                                }
                            }
                        }
                    }

                    return null;
                });

            if (upgradeOptions.length > 0 && upgradeOptions[0].id) {
                setUpgradeOptions(upgradeOptions);
                setShowUpgrade(true);
            } else {
                setShowUpgradesEmpty(true);
            }
        }
    }, [selectedSubscription, upgrades, history]);

    useEffect(() => {
        if (selectedUpgrades.length > 0) {
            const ordersSummaryData = getOrdersSummaryData(selectedUpgrades, grandmaData, grandpaData);

            setOrdersSummaryData(ordersSummaryData);
        }
    }, [selectedUpgrades, grandmaData, grandpaData]);

    const onSelectUpgrade = (selection) => {
        const upgradeExists = selectedUpgrades.some((upgrade) => upgrade.id === selection.price.id);

        if (upgradeExists) {
            const cancelledUpgrade = selection.price.id;
            const filteredList = selectedUpgrades.filter((upgrade) => upgrade.id !== cancelledUpgrade);

            setSelectedUpgrades(filteredList);
        } else {
            setSelectedUpgrades((old) => [
                ...old,
                {
                    ...selection.price,
                    autoRenewing: true,
                },
            ]);
        }
    };

    const setUpgradeAutoRenewing = (selection) => {
        const newUpgradeList = ordersSummaryData.map((upgrade) => {
            if (selection.priceId === upgrade.priceId) {
                return {
                    ...upgrade,
                    autoRenewing: !upgrade.autoRenewing,
                };
            } else {
                return upgrade;
            }
        });

        setOrdersSummaryData(newUpgradeList);
    };

    const sendUpgradeRequest = async () => {
        setLoadingUpgradesBuy(true);
        const pricesIdsList = getPricesIds(ordersSummaryData, grandmaData, grandpaData);
        delete pricesIdsList.recurring;
        const sessionId = renewSessionId();

        const prices = pricesIdsList.upgrades.map((price, index) => {
            const id = pricesIdsList.prices.find((price, priceIndex) => index === priceIndex);
            return id;
        });

        const upgradesToPay = {
            input: {
                sessionId: sessionId,
                subscriptionId: selectedSubscription.subscriptionId,
                prices: prices,
                upgrades: pricesIdsList.upgrades,
            },
        };

        const buyUpgradesResponse = await addUpgrades(upgradesToPay);

        if (buyUpgradesResponse) {
            setLoadingUpgradesBuy(false);
            if (typeof buyUpgradesResponse === "string") {
                setErrorMeessage(buyUpgradesResponse);
            } else {
                const updated = updateSubscriptionsStore(
                    saveUserSubscriptions,
                    user.subscriptions,
                    buyUpgradesResponse
                );

                if (updated) {
                    setSelectedUpgrades([]);
                    setOrdersSummaryData([]);
                    setOpenSuccessModal(true);
                    setRefreshSelectedSubscription(true);
                    setLocalStorageItem(SELECTED_SUBSCRIPTION, buyUpgradesResponse);
                }
            }
        }
    };

    let stepFiveComponent = null;

    const errorModal = errorMessage !== "" && (
        <ErrorModal open={errorMessage !== ""} message={errorMessage} closeFunction={() => setErrorMeessage("")} />
    );

    const successModal = openSuccessModal && (
        <SuccessModal
            open={openSuccessModal}
            message="Your selected upgrade(s) has been added to your subscription successfully."
            successAction={() => setOpenSuccessModal(false)}
            closeAction={() => setOpenSuccessModal(false)}
        />
    );

    if (!showUpgrade && !showUpgradesEmpty) {
        stepFiveComponent = <Loader />;
    } else {
        if (showUpgrade) {
            stepFiveComponent = (
                <UpgradeMarketComponent
                    plans={plans}
                    selectedUpgrades={selectedUpgrades}
                    upgradeOptions={upgradeOptions}
                    onSelectUpgrade={onSelectUpgrade}
                    history={history}
                    grandmaData={grandmaData}
                    grandpaData={grandpaData}
                    relationship={selectedSubscription.relationship}
                    ordersSummaryData={ordersSummaryData}
                    setUpgradeAutoRenewing={setUpgradeAutoRenewing}
                    sendUpgradeRequest={sendUpgradeRequest}
                    loadingUpgradesBuy={loadingUpgradesBuy}
                />
            );
        }

        if (showUpgradesEmpty) {
            stepFiveComponent = (
                <NoSubscriptionsComponent
                    actionFunction={() => history.push(ROUTES.SUBSCRIPTION_SUMMARY, { tabIndex: 1 })}
                    header="Upgrade Market"
                    message={ERRORS.ERROR_NOT_USER_UPGRADES}
                    actionTitle="Go Back"
                />
            );
        }
    }

    return (
        <Container>
            {errorModal}
            {successModal}
            {stepFiveComponent}
        </Container>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
    saveStepFive: (stepFive) => dispatch(saveStepFive(stepFive)),
    saveUserSubscriptions: (subscriptionsData) => dispatch(saveUserSubscriptions(subscriptionsData)),
});

const mapStateToProps = (state) => {
    return {
        upgrades: state.business.upgrades,
        plans: state.business.plans,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(UpgradeMarket));
