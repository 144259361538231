import React from "react";
import "../../scss/components/_input.scss";

export function CustomRadioButton(props) {
    const { id, label, onChangeAction, value, isSmallSize, onClickAction } = props;

    let labelClasses = "";
    if (isSmallSize) {
        labelClasses = "smallSize";
    }

    return (
        <div className="radio-content">
            <input
                id={id}
                type="radio"
                className="hidden"
                value={value}
                onChange={onChangeAction}
                checked={id === value}
                onClick={onClickAction}
                readOnly
            />
            <label className={labelClasses} htmlFor={id}>
                {label}
            </label>
        </div>
    );
}
