import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import Navigator from "./navigation/Navigator";
import { ErrorModal, Loader, Header } from "./components";
import { getProductsList } from "./services/SetupService";
import { loadGoogleScript } from "./services/LoadScriptService";
import ReactPixel from "react-facebook-pixel";
import { getCurrentUser } from "./services/AuthService";
import { getSubscriptionStoragedData } from "./store/subscriptions/actions";
import { saveProductsList } from "./store/business/actions";
import { getUserStoredData } from "./store/user/actions";
import * as ERORRS from "./constants/Errors";
import * as ROUTES from "./navigation/Routes";
const jwtDecode = require("jwt-decode");

function App(props) {
    const { getSubscriptionStoragedData, getUserStoredData, saveProductsList, history } = props;
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState();
    const pathname = window.location.pathname;

    useEffect(() => {
        const checkIsLoggedIn = async () => {
            const currentUser = await getCurrentUser();

            if (currentUser) {
                const decoded = jwtDecode(currentUser);
                getUserStoredData();

                if (decoded.firebase.sign_in_provider === "password") {
                    if (decoded.email_verified) {
                        setIsLoggedIn(currentUser);
                    } else {
                        setIsLoggedIn("notLoggedIn");
                    }
                } else {
                    setIsLoggedIn(currentUser);
                }
            } else {
                setIsLoggedIn("notLoggedIn");
            }
        };

        checkIsLoggedIn();
    }, [getUserStoredData]);

    useEffect(() => {
        const pathname = window.location.pathname;

        if (!ROUTES.ROUTES_LIST.includes(pathname)) {
            window.location = ROUTES.GRANDBOX_URLS[404];
        }
    }, []);

    useEffect(() => {
        const getInitialData = async () => {
            getSubscriptionStoragedData();
            loadGoogleScript();
        };

        const getSubscriptionProducstList = async () => {
            const productsList = await getProductsList();

            if (productsList) {
                saveProductsList(productsList);
            } else {
                setShowErrorModal(true);
            }
        };

        if (![ROUTES.RESET_PASSWORD, ROUTES.EMAIL_VALIDATION].includes(window.location.pathname)) {
            getInitialData();
            getSubscriptionProducstList();
        }
    }, [getSubscriptionStoragedData, getUserStoredData, saveProductsList, history]);

    let prevPath = null;

    useEffect(() => {
        // listen and notify Segment of client-side page updates
        history.listen((location) => {
            if (location.pathname !== prevPath) {
                // eslint-disable-next-line
                prevPath = location.pathname;
                window.analytics.page();
            }
        });
    }, []);

    let errorModal = null;

    if (showErrorModal) {
        errorModal = <ErrorModal message={ERORRS.ERROR_INITIALIZING_PAGE} open={showErrorModal} closeFunction={null} />;
    }

    let navigation = null;
    if (isLoggedIn) {
        navigation = (
            <>
                {pathname !== ROUTES.SUBSCRIPTION_SUMMARY && <Header history={history} />}
                <Navigator isLoggedIn={isLoggedIn} />
            </>
        );
    } else {
        navigation = <Loader />;
    }

    window.dataLayer &&
        window.dataLayer.push({
            event: "pageview",
        });

    ReactPixel.init("410322644167904");
    ReactPixel.pageView();


    return (
        <>
            {errorModal}
            {navigation}
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getSubscriptionStoragedData: () => dispatch(getSubscriptionStoragedData()),
    saveProductsList: (productsList) => dispatch(saveProductsList(productsList)),
    getUserStoredData: () => dispatch(getUserStoredData()),
});

export default compose(connect(null, mapDispatchToProps))(withRouter(App));
