export const ProductList = `
query productsList{
    productsList{
        id
        active
        name
        description
        metadata
        prices{
            id
            active
            billing_scheme
            currency
            recurring{
                interval_count
                interval
            }
            type
            unit_amount
            metadata
        }
    }
}
`;