import React from "react";
import { Message } from "semantic-ui-react";
import "../scss/components/_messages.scss";

export function Messages(props) {
    const { message, visible, buttonAction, floating, actionTitle } = props;

    let button = null;

    if (buttonAction) {
        button = <button onClick={buttonAction}>{actionTitle}</button>;
    }

    let className = "message-container container-padding";

    if (floating) {
        className += " floating-message";
    }

    return (
        <div className={className}>
            <Message visible={visible}>
                <p>{message}</p>
                {button}
            </Message>
        </div>
    );
}
