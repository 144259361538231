import React from "react";
import { Image } from "semantic-ui-react";
import * as CONSTANTS from "../../constants/Global";

import "../../scss/components/_cards.scss";

export function DonateBoxCards(props) {
    const { box } = props;
    const { boxMonth, boxImage } = box;
    const monthIndex = Number(boxMonth) - 1;
    const monthName = CONSTANTS.MONTHS[monthIndex];

    let boxShowName = `${monthName} Box`;

    return (
        <div className="donate-box-card">
            <div className="image-container">
                <Image src={boxImage} />
            </div>
            <p className="box-name">{boxShowName}</p>
        </div>
    );
}
