import React from "react";
import { Image } from "semantic-ui-react";
import ErrorImage from "../images/gifs/error-404.gif";

export function Error(props) {
    const { message } = props;

    return (
        <div className="flex-center flex-column margin-bottom margin-top">
            <Image src={ErrorImage} size="small" />
            <p>{message}.</p>
        </div>
    );
}
