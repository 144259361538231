import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { getChristmasDay, getFathersDay, getMonthersDay, getHanukkahDay, getValentineDay } from "./UpgradeBoxService";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "./LocalStorageService";
import * as CONSTANTS from "../constants/Global";
import * as LOCALSTORAGE from "../constants/LocalStorage";

export const divideProductsList = (productsList) => {
    let products = null;
    if (productsList.length > 0) {
        const upgrades = productsList.filter((product) => product.metadata.productType === CONSTANTS.BOX_TYPES.UPGRADE);
        const plans = productsList.filter(
            (product) => product.metadata.productType === CONSTANTS.BOX_TYPES.SUBSCRIPTION
        );
        const OTGUpgrades = upgrades.map((upgrade) => {
            return upgrade.prices.find((price) => price.metadata.gift);
        });

        const planPrices = plans[0].prices.filter((price) => !price.metadata.gift);

        const OTGPlans = plans[0].prices.filter((plan) => plan.metadata.grandboxType === CONSTANTS.BOX_TYPES.GIFT);

        products = {
            upgrades: upgrades,
            plans: planPrices,
            OTGPlans: OTGPlans,
            OTGUpgrades: OTGUpgrades,
        };
    } else {
        products = [];
    }

    return products;
};

export const getOrdersSummaryData = (selectedPlans, grandmaData, grandpaData) => {
    const isGift = selectedPlans.some((plan) => plan.metadata.name === "GrandBox Gift");
    let plansToPay = null;

    const subscriptionOrderSummary = selectedPlans.map((plan) => {
        let deliveries = null;
        let detail = plan.metadata.tradename;

        if (plan.metadata.packageQty) {
            if (plan.metadata.packageQty > 1) {
                deliveries = `${plan.metadata.packageQty} deliveries`;
            } else {
                deliveries = `${plan.metadata.packageQty} delivery`;
            }
        } else {
            deliveries = `1 delivery`;
        }

        let name = null;
        let type = null;
        let parent = null;

        if (plan.metadata.grandboxType === "subscription") {
            name = "GrandBox Subscription";
            type = CONSTANTS.BOX_TYPES.SUBSCRIPTION;
        } else {
            if (plan.metadata.name === "GrandBox Gift") {
                type = CONSTANTS.BOX_TYPES.SUBSCRIPTION;
            } else {
                type = CONSTANTS.BOX_TYPES.UPGRADE;
                if (plan.metadata.tradename.includes("Birthday")) {
                    if (plan.metadata.tradename.includes("Grandpa")) {
                        name = `${grandpaData.name}'s Birthday`;
                        parent = "grandpa";
                    } else {
                        name = `${grandmaData?.name}'s Birthday`;
                        parent = "grandma";
                    }
                } else {
                    if (plan.metadata.tradename.includes("Christmas")) {
                        if (grandmaData?.name && grandpaData?.name) {
                            name = "Couple Christmas";
                        } else {
                            name = "Christmas Box";
                        }
                    } else {
                        const customName = plan.metadata.tradename.replace("GrandBox ", "");
                        name = customName.replace(" Upgrade", "");
                    }
                }
            }
        }

        const planData = {
            priceId: plan.id,
            name: name,
            detail: detail,
            price: plan.unit_amount / 100,
            deliveries: deliveries,
            packages: plan.metadata.packageQty,
            autoRenewing: plan.autoRenewing,
            type: type,
            parent: parent,
            priceType: plan.metadata.priceType,
            billingType: plan.type,
            upgradeType: plan.metadata.upgradeType,
            giftData: null,
        };

        const upgradeInfo = getUpgradeInfo(planData, grandmaData, grandpaData);

        if (planData.type === CONSTANTS.BOX_TYPES.UPGRADE) {
            const isSameYear = moment().isSame(upgradeInfo.deliverDate, "year"); // false, different year
            const isSameMonth = moment().isSame(upgradeInfo.deliverDate, "month"); // false, different month
            const upgradeMonthToMonthBlocked = !isSameYear && !isSameMonth;

            planData.upgradeMonthToMonthBlocked = upgradeMonthToMonthBlocked;
        }

        return planData;
    });

    if (isGift) {
        plansToPay = getGiftOrderSummary(subscriptionOrderSummary);
    } else {
        plansToPay = subscriptionOrderSummary;
    }

    return plansToPay;
};

const getGiftOrderSummary = (plansToPay) => {
    const price = plansToPay[0].price + plansToPay[1].price;

    const planData = {
        priceId: plansToPay[0].priceId,
        name: plansToPay[1].name,
        detail: "One Time Purchase",
        price: price,
        deliveries: plansToPay[0].deliveries,
        packages: plansToPay[0].packages,
        autoRenewing: null,
        type: plansToPay[0].type,
        parent: plansToPay[1].parent,
        priceType: plansToPay[0].priceType,
        billingType: plansToPay[1].billingType,
        upgradeType: plansToPay[1].upgradeType,
        giftData: plansToPay,
    };

    return [planData];
};

export const prepareSubmitSubscriptionData = (
    subscriptionsData,
    paymentData,
    ordersSummaryData,
    subscribeNewsLetter
) => {
    const { grandmaData, grandpaData, couponData } = subscriptionsData;
    const relationship = subscriptionsData.relationship.toUpperCase();
    const sessionId = getLocalStorageItem(LOCALSTORAGE.SESSION_ID);
    let parents = [];

    if (subscriptionsData.relationship === CONSTANTS.GRANDPARENTS) {
        parents = [
            {
                ...grandmaData,
                parentType: CONSTANTS.GRANDMA.toUpperCase(),
            },
            {
                ...grandpaData,
                parentType: CONSTANTS.GRANDPA.toUpperCase(),
            },
        ];
    } else {
        if (subscriptionsData.relationship === CONSTANTS.GRANDPA) {
            parents = [
                {
                    ...grandpaData,
                    parentType: relationship,
                },
            ];
        } else {
            parents = [
                {
                    ...grandmaData,
                    parentType: relationship,
                },
            ];
        }
    }

    const pricesData = ordersSummaryData[0].giftData ? ordersSummaryData[0].giftData : ordersSummaryData;
    const paymentMethod = paymentData && paymentData.id;
    const pricesIds = getPricesIds(pricesData, grandmaData, grandpaData);
    const anniversary = grandpaData.anniversary ? grandpaData.anniversary : "1900/01/01";

    if (subscriptionsData.shippingAddress.geometry.bounds) {
        delete subscriptionsData.shippingAddress.geometry.bounds;
    }

    const subscriptionData = {
        input: {
            sessionId: sessionId,
            anniversary: anniversary,
            parents: parents,
            relationship: relationship,
            shippingAddress: subscriptionsData.shippingAddress,
            recurring: pricesIds.recurring ? pricesIds.recurring : false,
            paymentInfo: {
                coupon: couponData ? couponData.id : null,
                couponCode: couponData ? couponData.code : null,
                paymentMethod: paymentMethod,
                prices: pricesIds.prices,
                upgrades: pricesIds.upgrades,
            },
            subscribeNewsLetter: subscribeNewsLetter,
            subscriptionId: sessionId,
        },
    };

    if (!paymentMethod) {
        delete subscriptionData.input.paymentInfo.paymentMethod;
    }

    return subscriptionData;
};

export const getPricesIds = (ordersSummaryData, grandmaData, grandpaData) => {
    const prices = [];
    const upgrades = [];
    let recurring = null;

    ordersSummaryData.forEach((order) => {
        const priceId = order.priceId;

        if (order.type === CONSTANTS.BOX_TYPES.UPGRADE) {
            const upgradeInfo = getUpgradeInfo(order, grandmaData, grandpaData);
            upgrades.push(upgradeInfo);
        } else {
            recurring = order.autoRenewing ? order.autoRenewing : false;
        }
        prices.push(priceId);
    });

    const pricesIdsList = {
        prices: prices,
        upgrades: upgrades,
        recurring: recurring ? recurring : false,
    };

    return pricesIdsList;
};

const getUpgradeInfo = (order, grandmaData, grandpaData) => {
    const currentYear = moment().format("YYYY");
    const nextYear = moment().add(1, "year").format("YYYY");
    const grandmaBMonthDay = moment(grandmaData?.birthday).format("MM-DD");
    const grandpaBMonthDay = moment(grandpaData?.birthday).format("MM-DD");
    const anniversaryMonthDay = moment(grandpaData?.anniversary).format("MM-DD");

    let upgradeData = {};
    const upgradeType = order.name ? order.name : "";

    upgradeData.recurring = order.autoRenewing ? order.autoRenewing : false;

    if (upgradeType.includes("Christmas")) {
        let christmasDay = getChristmasDay();
        const isBefore = checkIsBefore(christmasDay);
        if (isBefore) christmasDay = getChristmasDay(nextYear);

        upgradeData.upgradeType = order.upgradeType;
        upgradeData.deliverDate = moment(christmasDay).toISOString();
    }

    if (upgradeType.includes("Hanukkah")) {
        let hanukkahDay = getHanukkahDay();
        const isBefore = checkIsBefore(hanukkahDay);
        if (isBefore) hanukkahDay = getChristmasDay(nextYear);

        upgradeData.upgradeType = order.upgradeType;
        upgradeData.deliverDate = moment(hanukkahDay).toISOString();
    }

    if (upgradeType.includes("Father")) {
        let fathersDay = getFathersDay();
        const isBefore = checkIsBefore(fathersDay);
        if (isBefore) fathersDay = getFathersDay(nextYear);

        upgradeData.upgradeType = order.upgradeType;
        upgradeData.deliverDate = moment(fathersDay).toISOString();
    }

    if (upgradeType.includes("Mother")) {
        let mothersDay = getMonthersDay();
        const isBefore = checkIsBefore(mothersDay);
        if (isBefore) mothersDay = getMonthersDay(nextYear);

        upgradeData.upgradeType = order.upgradeType;
        upgradeData.deliverDate = moment(mothersDay).toISOString();
    }

    if (upgradeType.includes("Anniversary")) {
        let anniversaryDay = `${currentYear}-${anniversaryMonthDay}`;
        const isBefore = checkIsBefore(anniversaryDay);
        if (isBefore) anniversaryDay = moment(anniversaryDay).add(1, "year").format("YYYY-MM-DD");

        upgradeData.upgradeType = order.upgradeType;
        upgradeData.deliverDate = moment(anniversaryDay).toISOString();
    }

    if (upgradeType.includes("Birthday")) {
        if (order.parent === CONSTANTS.GRANDMA) {
            let grandmaBDay = `${currentYear}-${grandmaBMonthDay}`;
            const isBefore = checkIsBefore(grandmaBDay);
            if (isBefore) grandmaBDay = moment(grandmaBDay).add(1, "year").format("YYYY-MM-DD");

            upgradeData.upgradeType = order.upgradeType;
            upgradeData.deliverDate = moment(grandmaBDay).toISOString();
        } else {
            let grandpaBDay = `${currentYear}-${grandpaBMonthDay}`;
            const isBefore = checkIsBefore(grandpaBDay);
            if (isBefore) grandpaBDay = moment(grandpaBDay).add(1, "year").format("YYYY-MM-DD");

            upgradeData.upgradeType = order.upgradeType;
            upgradeData.deliverDate = moment(grandpaBDay).toISOString();
        }
    }

    if (upgradeType.includes("Valentine")) {
        let valentineDay = getValentineDay();
        const isBefore = checkIsBefore(valentineDay);
        if (isBefore) valentineDay = getValentineDay(nextYear);

        upgradeData.upgradeType = order.upgradeType;
        upgradeData.deliverDate = moment(valentineDay).toISOString();
    }

    return upgradeData;
};

const checkIsBefore = (date) => {
    const today = moment().format("YYYY-MM-DD");

    const isBefore = moment(date).isBefore(today);

    return isBefore;
};

export const renewSessionId = () => {
    removeLocalStorageItem(LOCALSTORAGE.SESSION_ID);

    const sessionId = uuidv4();

    if (sessionId) setLocalStorageItem(LOCALSTORAGE.SESSION_ID, sessionId);

    return sessionId;
};
