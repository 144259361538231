import { useEffect } from "react";
import { withRouter } from "react-router";
import * as ROUTES from "../../navigation/Routes";
function Account(props) {
    const { history } = props;

    useEffect(() => {
        history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
    }, [history]);

    return null;
}

export default withRouter(Account);