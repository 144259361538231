import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "semantic-ui-react";
import StepOneComponent from "../../components/screenComponents/subscribe/StepOneComponent";
import { saveStepOne, saveStepFour } from "../../store/subscriptions/actions";
import { validateInputFilled } from "../../services/DataValidationService";
import { getLocalStorageItem } from "../../services/LocalStorageService";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import "../../scss/pages/get_a_box.scss";

function StepOne(props) {
    const { subscriptions, history, saveStepOne, saveStepFour, plans } = props;
    const { grandpaData, grandmaData, relationship, giftType, upgradeType } = subscriptions;
    const location = useLocation()

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        grandma: "",
        grandpa: "",
        inputTo: "",
        grandmaError: false,
        grandpaError: false,
    });

    let relationshipOptions = CONSTANTS.OPTIONS_SELECT;
    let selectedRelationShip = "";
    let selectOptions = giftType ? giftType : upgradeType ? upgradeType : null;
    const parent = getLocalStorageItem(LOCALSTORAGE.PARENT);

    switch (selectOptions) {
        case CONSTANTS.ONE_TIME_GIFT_OPTIONS[0]:
            relationshipOptions = CONSTANTS.OPTIONS_SELECT.filter((option) => option.value === CONSTANTS.GRANDPARENTS);
            selectedRelationShip = CONSTANTS.GRANDPARENTS;
            break;
        case CONSTANTS.ONE_TIME_GIFT_OPTIONS[1]:
            relationshipOptions = CONSTANTS.OPTIONS_SELECT.filter((option) => option.value === parent);
            break;
        case CONSTANTS.ONE_TIME_GIFT_OPTIONS[2]:
            relationshipOptions = CONSTANTS.OPTIONS_SELECT.filter((option) => option.value === CONSTANTS.GRANDPA);
            selectedRelationShip = CONSTANTS.GRANDPA;

            break;
        case CONSTANTS.ONE_TIME_GIFT_OPTIONS[3]:
            relationshipOptions = CONSTANTS.OPTIONS_SELECT.filter((option) => option.value === CONSTANTS.GRANDMA);
            selectedRelationShip = CONSTANTS.GRANDMA;
            break;
        default:
            break;
    }

    useEffect(() => {
        setStateForm({
            grandma: grandmaData.name ? grandmaData.name : "",
            grandpa: grandpaData.name ? grandpaData.name : "",
            inputTo: relationship ? relationship : selectedRelationShip,
        });
    }, [grandmaData, grandpaData, relationship, selectedRelationShip]);

    useEffect(() => {
        if (parent) {
            setStateForm({
                inputTo: parent,
            });
        }
    }, [parent]);

    const onchangeRelationship = (data) => {
        setStateForm({
            inputTo: data.value,
        });
    };

    const onchangeGrandparendName = (event, { value }) => {
        const stateName = event.target.id;

        setStateForm({
            [stateName]: value,
            [`${stateName}Error`]: false,
        });
    };

    const saveStepOneAndNext = () => {
        const checkGrandma = checkGrandmaInput();
        const checkGrandpa = checkGrandpaInput();
        if (formState.inputTo === CONSTANTS.GRANDPARENTS) {
            if (checkGrandma && checkGrandpa) {
                history.push(ROUTES.STEP_TWO);
            }
        } else {
            if (checkGrandma || checkGrandpa) {
                history.push(ROUTES.STEP_TWO);
            }
        }

        const selectedPackage = getLocalStorageItem(LOCALSTORAGE.PLAN_TYPE);

        if (selectedPackage) {
            const priceType = formState.inputTo === CONSTANTS.GRANDPARENTS ? "couple" : "single";

            const preselectedPlan = plans.filter(
                (plan) => plan.metadata.priceType === priceType && plan.metadata.packageQty === selectedPackage
            );

            saveStepFour({
                subscriptionPlan: preselectedPlan,
                oneTimeGift: false,
            });
        }
    };

    const checkGrandmaInput = () => {
        const nameValidated = validateInputFilled(formState.grandma);

        Object.assign(grandmaData, {
            name: formState.grandma,
        });

        if (nameValidated) {
            saveStepOne({
                grandmaData: grandmaData,
                relationship: formState.inputTo,
            });
        } else {
            setStateForm({
                grandmaError: "Grandma name is very short",
            });
        }

        return nameValidated;
    };

    const checkGrandpaInput = () => {
        const nameValidated = validateInputFilled(formState.grandpa);

        Object.assign(grandpaData, {
            name: formState.grandpa,
        });

        if (nameValidated) {
            saveStepOne({
                grandpaData: grandpaData,
                relationship: formState.inputTo,
            });
        } else {
            setStateForm({
                grandpaError: "Grandpa name is very short",
            });
        }

        return nameValidated;
    };

    return (
        <>
            <Container>
                <StepOneComponent
                    state={formState}
                    saveStepOneAndNext={saveStepOneAndNext}
                    onchangeGrandparendName={onchangeGrandparendName}
                    onchangeRelationship={onchangeRelationship}
                    relationshipOptions={relationshipOptions}
                    fromWebsite={location?.state?.from === 'website'}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepOne: (stepOne) => dispatch(saveStepOne(stepOne)),
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        plans: state.business.plans,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepOne));
