import React from "react";
import { Button } from "semantic-ui-react";
import "../../scss/components/_button.scss";

export function LinkButton(props) {
    const { buttonAction, title, textBefore, textAfter, disabled, align, dark, textSmall } = props;

    let beforeText = null;
    if (textBefore) {
        beforeText = <p>{textBefore}</p>;
    }

    let afterText = null;
    if (textAfter) {
        afterText = <p>{textAfter}</p>;
    }

    let buttonClasses = "link-container-padding";

    if (align === "left") {
        buttonClasses += " flex-start";
    }

    let linkButtonClasses = "link-button";

    if (dark) {
        linkButtonClasses += " dark";
    }

    if (textSmall) {
        linkButtonClasses += " text-small";
    }

    return (
        <div className={buttonClasses}>
            {beforeText}
            <Button className={linkButtonClasses} onClick={buttonAction} disabled={disabled}>
                {title}
            </Button>
            {afterText}
        </div>
    );
}
