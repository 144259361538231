import React, { useReducer } from "react";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { SuccessModal } from "../components";
import ResetPasswordComponent from "../components/screenComponents/ResetPasswordComponent";
import { firebaseService, firebaseSignOut } from "../services/AuthService";
import { resetPassword } from "../services/SetupService";
import * as ERRORS from "../constants/Errors";
import * as ROUTES from "../navigation/Routes";
import "../scss/pages/get_a_box.scss";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";

function ResetPassword(props) {
    const { history, location } = props;
    const { searchParams, accountRoutes, userData } = location.state;

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        newPassword: "",
        confirmPassword: "",
        newPasswordError: false,
        confirmPasswordError: false,
        errorMessage: "",
        inputError: false,
        loadingResetPassword: false,
        openSuccessModal: false,
    });

    const onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;

        if (inputId === "newPassword") {
            checkPasswordLength(inputId, value);
        } else if (inputId === "confirmPassword") {
            checkSamePassword(value);
        }
    };

    const checkPasswordLength = (inputId, value) => {
        setStateForm({
            [inputId]: value,
        });
        if (value.length < 6) {
            setStateForm({
                newPasswordError: "Your password must be at least 6 characters",
                inputError: true,
            });
        } else {
            setStateForm({
                newPasswordError: false,
            });
        }
    };

    const checkSamePassword = (value) => {
        setStateForm({
            confirmPassword: value,
        });

        if (formState.newPassword !== value) {
            setStateForm({
                newPasswordError: "Those passwords didn't match",
                confirmPasswordError: "Those passwords didn't match",
                inputError: true,
            });
        } else {
            setStateForm({
                newPasswordError: false,
                confirmPasswordError: false,
                inputError: false,
            });
        }
    };

    const doResetPasswoird = () => {
        setStateForm({ loadingResetPassword: true });
        const oobCode = searchParams.find((param) => param.includes("oobCode")).split("=")[1];
        verifyPasswordResetCode(firebaseService.auth, oobCode)
            .then(function (email) {
                confirmPasswordReset(firebaseService.auth, oobCode, formState.newPassword)
                    .then(function (resp) {
                        setStateForm({
                            loadingResetPassword: false,
                            openSuccessModal: true,
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        setStateForm({
                            newPassword: "",
                            confirmPassword: "",
                        });
                        setStateForm({ loadingResetPassword: false, errorMessage: error.message });
                    });
            })
            .catch(function (error) {
                setStateForm({
                    newPassword: "",
                    confirmPassword: "",
                });
                switch (error.code) {
                    case "auth/expired-action-code":
                        setStateForm({
                            loadingResetPassword: false,
                            errorMessage: ERRORS.ERROR_RESET_PASSWORD_EXPIRED,
                        });
                        break;
                    default:
                        setStateForm({
                            loadingResetPassword: false,
                            errorMessage: ERRORS.ERROR_RESET_PASSWORD_DEFAULT,
                        });
                        break;
                }
            });
    };

    const goToLogin = () => {
        history.replace(ROUTES.LOGIN, {
            accountRoutes: accountRoutes,
        });
        firebaseSignOut(true);
    };

    const manageResetPassword = async () => {
        setStateForm({ loadingResetPassword: true });
        const passwordData = {
            ownerId: userData.user_id,
            password: formState.newPassword,
        };

        try {
            const resetPasswordResponse = await resetPassword(passwordData);

            if (resetPasswordResponse) {
                setStateForm({ loadingResetPassword: false });

                if (typeof resetPasswordResponse === "string") {
                    setStateForm({ errorMessage: resetPasswordResponse });
                } else {
                    setStateForm({
                        openSuccessModal: true,
                    });
                }
            }
        } catch (error) {
            setStateForm({ loadingResetPassword: false, errorMessage: error.message });
        }
    };

    const closeErrorModal = () => {
        setStateForm({ errorMessage: "" });
    };

    let successModal = null;

    if (formState.openSuccessModal) {
        successModal = (
            <SuccessModal
                open={formState.openSuccessModal}
                message="Password changed successfully."
                successAction={goToLogin}
                closeAction={goToLogin}
            />
        );
    }

    return (
        <>
            {successModal}
            <Container>
                <ResetPasswordComponent
                    state={formState}
                    onChangeWriteOptions={onChangeWriteOptions}
                    doResetPasswoird={doResetPasswoird}
                    goToLogin={goToLogin}
                    userData={userData}
                    manageResetPassword={manageResetPassword}
                    closeErrorModal={closeErrorModal}
                />
            </Container>
        </>
    );
}

export default withRouter(ResetPassword);
