import React, { useRef } from "react";
import {
    BoxSubHeader,
    CustomTextArea,
    DefaultButton,
    LinkButton,
    OutlineButton,
    CustomFileInput,
    PdfViewer,
    Error,
} from "../..";
import * as CONSTANTS from "../../../constants/Global";
import * as ERRORS from "../../../constants/Errors";

function AddBoxNoteComponent(props) {
    const {
        history,
        customerNote,
        pdfFile,
        onchangeCustomerNote,
        noteError,
        onChangeFile,
        cleanPdfFile,
        loadingSaveNote,
        getPDFFromNote,
        parentsNames,
        handleConfirmationModal,
        buttonLoading,
        boxStatus,
        boxMonth,
    } = props;

    let pdfContainerRef = useRef(null);

    let noteTextArea = (
        <>
            <CustomTextArea
                id="box-note"
                onChangeAction={onchangeCustomerNote}
                value={customerNote}
                errorMessage={noteError}
                rows={10}
                maxLength={2000}
                label="Add Personal Note"
            />
            <CustomFileInput id="pdf-file" label="Attach PDF file" onChangeAction={onChangeFile} />
        </>
    );

    let pdfPreview = null;
    let cleanPdfButton = null;

    const existPdfFile = pdfFile.hasOwnProperty("base64");

    if (existPdfFile) {
        let url = null;

        if (pdfFile.hasOwnProperty("base64")) {
            url = pdfFile.base64;
        } else {
            url = pdfFile;
        }
        pdfPreview = <PdfViewer url={url} reference={pdfContainerRef} />;
        noteTextArea = null;

        if (pdfFile.type === "write") {
            cleanPdfButton = (
                <OutlineButton title="Change note text or Attach a PDF file" buttonAction={cleanPdfFile} />
            );
        } else {
            cleanPdfButton = <OutlineButton title="Change PDF file or Write a note" buttonAction={cleanPdfFile} />;
        }
    }

    let buttonDisabled = true;

    if (existPdfFile || customerNote !== "") {
        buttonDisabled = false;
    }

    let primaryButton = null;

    if (existPdfFile) {
        primaryButton = (
            <DefaultButton
                title="Save"
                disabled={buttonDisabled}
                loading={loadingSaveNote}
                buttonAction={() => handleConfirmationModal("SAVE")}
            />
        );
    } else {
        primaryButton = (
            <DefaultButton
                title="Update note"
                disabled={buttonDisabled}
                loading={loadingSaveNote}
                buttonAction={getPDFFromNote}
            />
        );
    }

    let noteSection = null;

    if (boxStatus === CONSTANTS.BOX_STATUS.UNSHIPPED) {
        noteSection = (
            <>
                <div className="container-padding-feature feature-section">
                    <div className="input-pdf-container" ref={pdfContainerRef}>
                        {noteTextArea}
                        {pdfPreview}
                    </div>
                    {cleanPdfButton}
                </div>
                <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                    {primaryButton}
                    <br />
                    <LinkButton
                        title="Skip for this month"
                        dark={true}
                        loading={buttonLoading}
                        buttonAction={handleConfirmationModal}
                    />
                </div>
            </>
        );
    } else {
        noteSection = <Error message={ERRORS.ERROR_SHIPPED_BOX_FEATURES} />;
    }

    return (
        <section className="padding-page">
            <BoxSubHeader
                title={`${boxMonth} Box Note`}
                boxName={`Personal Note to ${parentsNames}`}
                history={history}
            />
            {noteSection}
        </section>
    );
}

export default AddBoxNoteComponent;
