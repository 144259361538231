import React from "react";
import { Image } from "semantic-ui-react";
import UploadPhotosGif from "../../images/gifs/upload-photos.gif";
import "../../scss/components/_input.scss";

export function CustomImageInput(props) {
    const { id, onChangeAction } = props;

    return (
        <div className="input-pictures-content">
            <div className="ui label">
                <label htmlFor={id}>
                    <Image src={UploadPhotosGif} size="small" />
                    <p>Click to upload your photos</p>
                </label>
            </div>
            <input
                id={id}
                type="file"
                accept=".jpg,.jpeg,.png"
                multiple
                style={{ display: "none" }}
                onChange={onChangeAction}
            />
        </div>
    );
}
