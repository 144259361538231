import React from "react";
import { Card, Header, Divider } from "semantic-ui-react";
import { CustomCheckbox } from "../../components";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/components/_cards.scss";
import "../../scss/components/_utilities.scss";

export function OrderCard(props) {
    const { plan, removeUpgrade, removeSubscription, setAutoRenewing } = props;
    const { name, price, detail, deliveries, autoRenewing, packages, availableDate, status } = plan;

    const priceFixed = `$${price.toFixed(2)}`;

    let priceString = priceFixed;
    let removeAction = removeUpgrade;

    if (name === "GrandBox Subscription") {
        removeAction = removeSubscription;
        const monthlyPrice = price / Number(packages);
        priceString = `$${monthlyPrice.toFixed(2)} / mo`;
    }

    if (status === "future") {
        priceString = `${priceFixed} (${availableDate})`;
    }

    const hasAutoRenewing = typeof autoRenewing === "boolean";

    return (
        <Card className="order-card" fluid>
            <Card.Content>
                <div className="order-card-header">
                    {removeAction && hasAutoRenewing && plan.type !== CONSTANTS.BOX_TYPES.SUBSCRIPTION && (
                        <button className="remove-button" onClick={() => removeAction(plan)}>
                            &#x2715;
                        </button>
                    )}
                    <Header as="h3">{name}</Header>
                    <span>{priceFixed}</span>
                </div>
                <Card.Description>{detail}</Card.Description>
                <Card.Description>{priceString}</Card.Description>
                <Card.Meta>{deliveries}</Card.Meta>
                {hasAutoRenewing && (
                    <CustomCheckbox
                        label="Auto-Renewing"
                        value={autoRenewing}
                        onChangeAction={() => setAutoRenewing(plan)}
                    />
                )}
            </Card.Content>
            <Divider />
        </Card>
    );
}
