import React from "react";
import { Header } from "semantic-ui-react";
import { SubHeader, DefaultButton, OutlineButton } from "../..";
import SubscriptionSuccessful from "../../..//images/subscription-successful.svg";

function SuccessfulComponent(props) {
    const { goToManageSubscriptions } = props;

    return (
        <section className="padding-page">
            <SubHeader mainTitle="Congratulations" image={SubscriptionSuccessful} />
            <div className="input-container container-padding">
                <Header as="h3" textAlign="center" content="Your first GrandBox is almost ready" />
                <p className="successful-note margin-left">
                    Remember that you can add a little more love to your gift, add your note and, personalize a photo
                </p>
            </div>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton title="Add Now!" buttonAction={() => goToManageSubscriptions(true)} />
                <OutlineButton title="Skip, add later" buttonAction={() => goToManageSubscriptions(false)} />
            </div>
        </section>
    );
}

export default SuccessfulComponent;
