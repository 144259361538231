import React from "react";

export function PdfViewer(props) {
    const { url } = props;

    return (
        <iframe
            src={`${url}#toolbar=0`}
            title="pdf-viewport"
            alt="preview"
            style={{
                width: "100%",
                minHeight: "40vh"
            }}
        />
    );
}
