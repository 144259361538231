import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { SUBSCRIPTION_SUMMARY } from "../../navigation/Routes";
import "../../scss/components/_button.scss";

export function BackButton(props) {
    const { disabled, title, tabIndex, fromWebsite } = props;

    const history = useHistory();
    const location = useLocation();

    const goBack = () => {
        if (typeof tabIndex === "number") {
            history.push(SUBSCRIPTION_SUMMARY, { tabIndex: tabIndex });
        } else {
            if (location.state && location.state.backRoute) {
                window.location.href = location.state.backRoute;
            } else {
                if (fromWebsite) {
                    window.location.href = process.env.REACT_APP_GRANDBOX_URL;
                } else {
                    history.goBack();
                } 
            }
        }
    };

    return (
        <Button className="back-button" fluid onClick={goBack} disabled={disabled}>
            {title}
        </Button>
    );
}
