import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container, Icon, Tab } from "semantic-ui-react";
import moment from "moment";
import { Header, Loader, ErrorModal, PauseSubscriptionModal } from "../../components";
import ManageSubscriptionComponent from "../../components/screenComponents/account/ManageSubscriptionComponent";
import NoSubscriptionsComponent from "../../components/screenComponents/account/NoSubscriptionsComponent";
import UserInformationComponent from "../../screens/account/UserInformation";
import { getUserSubscriptions } from "../../services/SetupService";
import { setLocalStorageItem, getLocalStorageItem } from "../../services/LocalStorageService";
import { saveUserSubscriptions } from "../../store/user/actions";
import { resetSubscriptionStore } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import * as LOADINGS from "../../constants/Loadings";
import * as ERRORS from "../../constants/Errors";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/pages/account.scss";

function ManageSubscription(props) {
    const { history, user, location, saveUserSubscriptions, resetSubscriptionStore } = props;
    const recentSubscriptionId = location.state?.subscriptionId;
    const outsideTabIndex = location.state?.tabIndex;

    const { subscriptions } = user;
    const [loadingSubscriptions, setLoadingSubscription] = useState(false);
    const [subscriptionOptions, setSubscriptionOptions] = useState(null);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [showActiveSubscriptions, setShowActiveSubscriptions] = useState(true);
    const [showChat, setShowChat] = useState(null);
    const [tabIndex, setTabIndex] = useState(1);
    const [usingTabs, setUsingTabs] = useState(null);
    const [openPauseSubscriptionModal, setOpenPauseSubscriptionModal] = useState(false);

    useEffect(() => {
        if (typeof outsideTabIndex === "number") {
            if (outsideTabIndex !== tabIndex) {
                if (!usingTabs) {
                    setTabIndex(outsideTabIndex);
                    setUsingTabs(false);
                }
            }
        }
    }, [outsideTabIndex, tabIndex, usingTabs]);

    useEffect(() => {
        const getSubscriptionsList = async () => {
            setLoadingSubscription(true);
            const rawUserSubscriptions = await getUserSubscriptions();

            const userSubscriptions = rawUserSubscriptions ? rawUserSubscriptions.filter((subscription) => {
                const boxesFilter = subscription.boxes.some((box) => box.status === "UNSHIPPED")
                if (subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE || subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.PAUSED || (subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.CANCELLED && boxesFilter)) {
                    return subscription
                }

                return false
            }) : undefined

            if (userSubscriptions) {
                setLoadingSubscription(false);
                if (userSubscriptions.length > 0) {
                    saveUserSubscriptions(userSubscriptions);
                } else {
                    saveUserSubscriptions([]);
                }
            } else {
                setOpenErrorModal(true);
                setLoadingSubscription(false);
            }
        };

        getSubscriptionsList();
    }, [recentSubscriptionId, saveUserSubscriptions]);

    useEffect(() => {
        const activeSubscriptions = subscriptions?.filter((subscription) =>
            [CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE, CONSTANTS.SUBSCRIPTION_STATUS.PAUSED].includes(subscription.status)
        ).length;

        let showInactiveSubscriptions = false;

        if (activeSubscriptions === 0) {
            setShowActiveSubscriptions(false);
            showInactiveSubscriptions = true;
        }

        const getSubscriptionsOptions = () => {
            if (subscriptions && subscriptions.length > 0) {
                const subscriptionOptions = subscriptions
                    .map((subscription, index) => {
                        const parentsNames = subscription.parents.map((parent) => {
                            return parent.name;
                        });
                        let subscriptionName = "";

                        if (subscription.currentPlan.includes("GrandBox Subscription ")) {
                            subscriptionName = `${subscription.currentPlan.replace("GrandBox Subscription ", "")}`;
                        } else {
                            subscriptionName = `One Time Gift`;
                        }

                        return {
                            key: index,
                            value: subscription.subscriptionId,
                            text: `${parentsNames.join(" | ")} / ${subscriptionName}`,
                            status: subscription.status,
                            boxes: subscription.boxes
                        };
                    })
                    .filter((subscription) => {
                        if (subscription.value === recentSubscriptionId) {
                            return subscription;
                        } else {
                            const hasActiveBoxes = subscription.boxes.some((box) => box.status === CONSTANTS.BOX_STATUS.UNSHIPPED)
                            if (showInactiveSubscriptions && hasActiveBoxes) {
                                return subscription
                            } else {
                                return [
                                    CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE,
                                    CONSTANTS.SUBSCRIPTION_STATUS.PAUSED,
                                ].includes(subscription.status);
                            }
                        }
                    });

                subscriptionOptions.push({
                    key: subscriptions.length + 3,
                    value: "add",
                    text: "Add New Subscription",
                    status: "",
                    content: (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon name="add" />
                            {"  "}
                            <p style={{ textDecoration: "underline", margin: 0, fontWeight: "bold" }}>
                                Add New Subscription
                            </p>
                        </div>
                    ),
                    boxes: []
                });

                setSubscriptionOptions(subscriptionOptions);
            }
        };

        getSubscriptionsOptions();
    }, [subscriptions, recentSubscriptionId]);

    useEffect(() => {
        const storagedSelectedSubscription = getLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION);
        if (subscriptionOptions) {
            if (subscriptionOptions.length > 0) {
                let newSelectedSubscription = null;

                const getCurrentSubscription = () => {
                    const lastSubscription = Math.max.apply(
                        Math,
                        subscriptions.map((subscription) => {
                            const createdAt = Number(moment(subscription.createdAt).format("x"));
                            return createdAt;
                        })
                    );

                    if (recentSubscriptionId) {
                        newSelectedSubscription = subscriptions.find(
                            (subscription) => subscription.subscriptionId === recentSubscriptionId
                        );
                    } else {
                        if (storagedSelectedSubscription) {
                            newSelectedSubscription = storagedSelectedSubscription;
                        } else {
                            newSelectedSubscription = subscriptions.find(
                                (subscription) =>
                                    Number(moment(subscription.createdAt).format("x")) === lastSubscription
                            );
                        }
                    }
                    if (newSelectedSubscription) {
                        setLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION, newSelectedSubscription);
                        setSelectedSubscription(newSelectedSubscription);
                    }
                };

                getCurrentSubscription();
            } else {
                setLoadingSubscription(false);
            }
        }
    }, [subscriptionOptions, subscriptions, recentSubscriptionId]);

    useEffect(() => {
        if (selectedSubscription) {
            const getCurrentBox = () => {
                const currentBox = selectedSubscription.boxes.find((box) => box.boxNumber === 1);
                return currentBox;
            };

            const goToBoxDetail = (box) => {
                history.push({
                    pathname: ROUTES.BOX_DESCRIPTION,
                    search: `${box.subscriptionId}&&${box.boxId}`,
                });
            };

            if (recentSubscriptionId) {
                setLoadingSubscription(false);

                const currentBox = getCurrentBox();
                goToBoxDetail(currentBox);
            } else {
                setLoadingSubscription(false);
            }
        }
    }, [selectedSubscription, recentSubscriptionId, history]);

    useEffect(() => {
        if (subscriptionOptions && selectedSubscription) {
            const subscriptionInList = subscriptionOptions.length && subscriptionOptions.some((option) => option.value !== selectedSubscription.subscriptionId) 
            const selectExist = subscriptionOptions.length && subscriptionOptions.some((option) => option.value === "select");

            if (subscriptionInList && !selectExist) {
                const newOption = {
                    key: subscriptionOptions.length + 1,
                    value: "select",
                    text: "Please select an active subscription",
                    status: "",
                    boxes: []
                };
    
                subscriptionOptions.unshift(newOption);
            }

            if ((selectedSubscription.status === CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE) || selectedSubscription.status === CONSTANTS.SUBSCRIPTION_STATUS.PAUSED) {
                subscriptionOptions.shift()
            }
        }
    }, [subscriptionOptions, selectedSubscription, showActiveSubscriptions]);

    const handleShowActiveSubscriptions = () => {
        setShowActiveSubscriptions(!showActiveSubscriptions);
        const subscriptionOptions = subscriptions
            .map((subscription, index) => {
                const parentsNames = subscription.parents.map((parent) => {
                    return parent.name;
                });
                let subscriptionName = "";

                if (subscription.currentPlan.includes("GrandBox Subscription ")) {
                    subscriptionName = `${subscription.currentPlan.replace("GrandBox Subscription ", "")}`;
                } else {
                    subscriptionName = `One Time Gift`;
                }

                return {
                    key: index,
                    value: subscription.subscriptionId,
                    text: `${parentsNames.join(" | ")} / ${subscriptionName}`,
                    status: subscription.status,
                    boxes: subscription.boxes
                };
            })
            .filter((subscription) => {
                const hasActiveBoxes = subscription.boxes.some((box) => box.status === CONSTANTS.BOX_STATUS.UNSHIPPED)
                if (showActiveSubscriptions && hasActiveBoxes) {
                    return subscription;
                } else {
                    if (subscriptions.length === 1) {
                        return subscription;
                    } else {
                        return [CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE, CONSTANTS.SUBSCRIPTION_STATUS.PAUSED].includes(
                            subscription.status
                        );
                    }
                }
            });

        subscriptionOptions.push({
            key: subscriptions.length + 3,
            value: "add",
            text: "Add New Subscription",
            status: "",
            content: (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Icon name="add" />
                    {"  "}
                    <p style={{ textDecoration: "underline", margin: 0, fontWeight: "bold" }}>Add New Subscription</p>
                </div>
            ),
            boxes: []
        });

        setSubscriptionOptions(subscriptionOptions);
    };

    const goToBoxDetail = (box) => {
        history.push({
            pathname: ROUTES.BOX_DESCRIPTION,
            search: `${box.subscriptionId}&&${box.boxId}`,
        });
    };

    const handleSelectedSubscription = (data) => {
        if (data.value === "add") {
            goToSubscriptions();
        } else {
            const selectedSubscription = subscriptions.find(
                (subscription) => subscription.subscriptionId === data.value
            );
            setSelectedSubscription(selectedSubscription);
            setLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION, selectedSubscription);
        }
    };

    const goToCancelSubscription = () => {
        history.push(ROUTES.CANCEL_SUBSCRIPTION_REMAINING, selectedSubscription);
    };

    const goToSubscriptions = () => {
        resetSubscriptionStore();
        history.replace(ROUTES.STEP_ONE, { from: ROUTES.SUBSCRIPTION_SUMMARY });
    };
    const goToUpgrades = () => {
        history.replace(ROUTES.ADD_NEW_UPGRADE);
    };

    const closeErrorModal = () => {
        setOpenErrorModal(false);
    };

    const showChatToCancelSubs = () => {
        setShowChat(selectedSubscription.subscriptionId);
    };

    const handleSetTabIndex = (event, { activeIndex }) => {
        setTabIndex(activeIndex);
        setUsingTabs(true);
    };

    const handleTabIndexFromMenu = (index) => {
        setTabIndex(index);
    };

    const handlePauseSubscriptionModal = () => {
        setOpenPauseSubscriptionModal(!openPauseSubscriptionModal);
    };

    let loader = null;
    let manageSubscription = null;

    const pauseSubscriptionModal = openPauseSubscriptionModal && (
        <PauseSubscriptionModal
            open={openPauseSubscriptionModal}
            closeAction={handlePauseSubscriptionModal}
            subscriptionId={selectedSubscription.subscriptionId}
            subscriptions={subscriptions}
            selectedSubscription={selectedSubscription}
            saveUserSubscriptions={saveUserSubscriptions}
        />
    );

    if (loadingSubscriptions) {
        loader = <Loader message={LOADINGS.LOADING_SUBSCRIPTIONS} />;
    } else {
        if (selectedSubscription && subscriptionOptions.length > 0) {
            manageSubscription = (
                <ManageSubscriptionComponent
                    goToBoxDetail={goToBoxDetail}
                    selectedSubscription={selectedSubscription}
                    subscriptionOptions={subscriptionOptions}
                    handleSelectedSubscription={handleSelectedSubscription}
                    goToCancelSubscription={goToCancelSubscription}
                    handleShowActiveSubscriptions={handleShowActiveSubscriptions}
                    showActiveSubscriptions={showActiveSubscriptions}
                    showChatToCancelSubs={showChatToCancelSubs}
                    goToSubscriptions={goToSubscriptions}
                    goToUpgrades={goToUpgrades}
                    handlePauseSubscriptionModal={handlePauseSubscriptionModal}
                />
            );
        } else {
            manageSubscription = (
                <NoSubscriptionsComponent
                    actionFunction={goToSubscriptions}
                    header="Manage Subscription"
                    message={ERRORS.ERROR_NOT_USER_SUBSCRIPTIONS}
                    actionTitle="GET A SUBSCRIPTION"
                />
            );
        }
    }

    let erroModal = null;

    if (openErrorModal) {
        erroModal = (
            <ErrorModal
                open={openErrorModal}
                closeFunction={closeErrorModal}
                message={ERRORS.ERROR_LOADING_USER_SUBSCRIPTIONS}
            />
        );
    }

    const tabPanes = [
        {
            menuItem: "MANAGE MY ACCOUNT",
            render: () => (
                <Tab.Pane>
                    <UserInformationComponent />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "MANAGE SUBSCRIPTION",
            render: () => <Tab.Pane>{manageSubscription}</Tab.Pane>,
        },
    ];

    return (
        <>
            {erroModal}
            {pauseSubscriptionModal}
            <Header
                history={history}
                hidePromo={true}
                openChat={showChat}
                handleTabIndexFromMenu={handleTabIndexFromMenu}
            />
            <Container>
                {loader}

                {!loadingSubscriptions && (
                    <div className="tabs-container">
                        <Tab panes={tabPanes} activeIndex={tabIndex} onTabChange={handleSetTabIndex} />
                    </div>
                )}
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveUserSubscriptions: (userSubscriptions) => dispatch(saveUserSubscriptions(userSubscriptions)),
    resetSubscriptionStore: () => dispatch(resetSubscriptionStore()),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(ManageSubscription));
