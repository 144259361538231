import { isJsonString } from "./DataValidationService";

export const setLocalStorageItem = (storage, data) => {
    return localStorage.setItem(storage, JSON.stringify(data));
};

export const removeLocalStorageItem = (storage) => {
    const isAnExistingStorage = getLocalStorageItem(storage);

    if (isAnExistingStorage) {
        return localStorage.removeItem(storage);
    }

    localStorage.removeItem(storage);
};

export const getLocalStorageItem = (storage) => {
    const data = localStorage.getItem(storage);
    if (data) {
        try {
            const jsonVerified = isJsonString(data);
            if (jsonVerified) {
                return JSON.parse(data);
            }
        } catch (e) {
            return false;
        }
    }
};

export const mergeDataToLocalStorage = (storage, data) => {
    let mergedData = {};
    const lastData = getLocalStorageItem(storage);

    if (lastData) {
        mergedData = Object.assign(lastData, data);
    } else {
        mergedData = data;
    }

    return setLocalStorageItem(storage, mergedData);
};
