import axios from "axios";
import moment from "moment";
import { create } from "apisauce";
import * as CONFIG from "./ConfigService";
import { getIdToken, getCurrentUser, firebaseSignOut } from "./AuthService";
const jwtDecode = require("jwt-decode");

const API_URL = CONFIG.getApiUrl();

let apisauceInstance = null;

const customAxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 60000,
    withCredentials: false,
});

const getConfig = async () => {
    const NEW_TOKEN_ID = await getIdToken();
    const STORED_TOKEN_ID = await getCurrentUser();
    let TOKEN_ID = null;

    if (NEW_TOKEN_ID) {
        TOKEN_ID = NEW_TOKEN_ID;
    } else {
        TOKEN_ID = STORED_TOKEN_ID;
        const decoded = jwtDecode(TOKEN_ID);
        const tokenExpired = moment().isAfter(moment(decoded.exp * 1000));

        if (tokenExpired) {
            firebaseSignOut(true);
        }
    }

    if (TOKEN_ID) {
        customAxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${TOKEN_ID}`;
        apisauceInstance = create({ axiosInstance: customAxiosInstance });

        return true;
    }
};

export const graphqlServiceAuthSauce = async (query, variables) => {
    const instanceReady = await getConfig();

    if (instanceReady) {
        try {
            const httpResquest = await apisauceInstance.post("/graphql", {
                query: query,
                variables: variables,
            });
            return httpResquest;
        } catch (error) {
            return error;
        }
    }
};

export const graphqlServiceSauce = async (query, variables) => {
    apisauceInstance = create({ axiosInstance: customAxiosInstance });

    try {
        const httpResquest = await apisauceInstance.post("/graphql", {
            query: query,
            variables: variables,
        });
        return httpResquest;
    } catch (error) {
        return error;
    }
};
