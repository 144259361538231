import React from "react";
import { Card } from "semantic-ui-react";
import { SubHeader, DefaultButton, BackButton } from "../..";
import { PlanCard } from "../..";

function StepFourComponent(props) {
    const { subscription, saveStepFourAndNext, onSelectPlan, subscriptionPlansOptions } = props;

    let nextStepDisdabled = true;
    if (subscription.length > 0) {
        nextStepDisdabled = false;
    }

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle="Pick Your Suitable Plan"
                specialText="Get the first box in 5 days and new theme every month"
                secondSpecialText="Welcome box + a new theme every month"
            />
            <div className="cards-container">
                <Card.Group textAlign="center" centered itemsPerRow={4}>
                    {subscriptionPlansOptions.map((plan, index) => {
                        const selectedPlan = subscription[0] && subscription[0].id === plan.id;
                        return (
                            <PlanCard key={index} plan={plan} onSelection={onSelectPlan} selectedPlan={selectedPlan} />
                        );
                    })}
                </Card.Group>
            </div>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton title="NEXT STEP" disabled={nextStepDisdabled} buttonAction={saveStepFourAndNext} />
                <br />
                <BackButton title="Back"/>
            </div>
        </section>
    );
}

export default StepFourComponent;
