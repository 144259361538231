import React from "react";
import { Image } from "semantic-ui-react";
import { SubHeader, DefaultButton } from "../..";
import SadImage from "../../../images/gifs/sad-oldman.gif";

function NoSubscriptionsComponent(props) {
    const { actionFunction, header, message, actionTitle } = props;

    return (
        <section className="padding-page">
            <SubHeader mainTitle={header} />
            <div className="manage-subscription-container">
                <Image src={SadImage} size="small" centered />
                <p className="text-center">{message}</p>
            </div>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton title={actionTitle} buttonAction={actionFunction} />
            </div>
        </section>
    );
}

export default NoSubscriptionsComponent;
