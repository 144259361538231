import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { ErrorModal } from "../../components";
import UserInformationComponent from "../../components/screenComponents/account/UserInformationComponent";
import { getLastPaymentMethod } from "../../services/SetupService";
import "../../scss/pages/account.scss";
import { UPDATE_PAYMENT_METHOD, RESET_PASSWORD } from "../../navigation/Routes";
import { ERROR_NOT_PAYMENT_METHOD } from "../../constants/Errors";

function UserInformation(props) {
    const { user, history } = props;
    const { userData } = user;

    const [paymentMethod, setPaymentMethod] = useState(null);
    const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);

    useEffect(() => {
        setLoadingPaymentMethod(true);
        const getLastPayment = async () => {
            try {
                const lastPaymentMethod = await getLastPaymentMethod({
                    userId: userData.user_id,
                });

                if (typeof lastPaymentMethod !== "string") {
                    setPaymentMethod(lastPaymentMethod);
                    setLoadingPaymentMethod(false);
                } else {
                    if (
                        lastPaymentMethod.includes("No such customer") ||
                        lastPaymentMethod === "INFORMACION NO ENCONTRADA"
                    ) {
                        setShowAddPaymentMethod(true);
                        setLoadingPaymentMethod(false);
                    } else {
                        setLoadingPaymentMethod(false);
                        setErrorMessage(ERROR_NOT_PAYMENT_METHOD);
                        setShowAddPaymentMethod(true);
                    }
                }
            } catch (error) {}
        };

        if (userData && userData.user_id) {
            getLastPayment();
        }
    }, [userData]);

    const goToUpdatePaymentMethod = () => {
        history.push(UPDATE_PAYMENT_METHOD, { userData: userData });
    };

    const goToResetPassword = () => {
        history.push(RESET_PASSWORD, { userData: userData });
    };

    const handleErrorModalClose = () => {
        setErrorMessage("");
    };

    let errorModal = null;

    if (errorMessage.length > 0) {
        errorModal = (
            <ErrorModal open={errorMessage.length > 0} closeFunction={handleErrorModalClose} message={errorMessage} />
        );
    }

    return (
        <>
            {errorModal}
            <UserInformationComponent
                userData={userData}
                paymentMethod={paymentMethod}
                loadingPaymentMethod={loadingPaymentMethod}
                goToUpdatePaymentMethod={goToUpdatePaymentMethod}
                showAddPaymentMethod={showAddPaymentMethod}
                goToResetPassword={goToResetPassword}
            />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, null))(withRouter(UserInformation));
