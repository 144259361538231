export const SUBSCRIPTION_DATA = "subscriptionData";
export const USER_DATA = "userData";
export const SUBSCRIPTION_INFO = "subscriptionInfo";
export const STORAGED_USER_SUBSCRIPTIONS = "storagedUserSubscriptions";
export const SELECTED_SUBSCRIPTION = "selectedSubscription";
export const SESSION_ID = "sessionId";
export const OTP_VALIDATOR = "otpValidation";
export const LAST_ROUTE = "lastRoute";
export const GIFT_TYPE = "giftType";
export const PLAN_TYPE = "planType";
export const REMEMBER_ME = "rememberMe";
export const PARENT = "parent";
export const OTP_SENDED = "otpSended";
