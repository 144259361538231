import React from "react";
import { Image, Modal } from "semantic-ui-react";
import "../../scss/components/_modal.scss";

export function ImagePreviewModal(props) {
    const { open, photo, closeFunction } = props;

    return (
        <Modal className="image-preview-modal" closeIcon open={open} size="small" onClose={closeFunction}>
            <Modal.Content>
                <Image src={photo} centered />
            </Modal.Content>
        </Modal>
    );
}
