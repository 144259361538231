import React, { useEffect, useRef } from 'react';
import { waitForGlobal } from '../services/LoadScriptService';
import '../scss/components/_map.scss';

let map = null;
let marker = null;

const loadScript = (mapRefernce, geometry) => {
	const googleIsLoaded = waitForGlobal('google');

	googleIsLoaded.then((isLoaded) => {
		if (isLoaded) {
			handleScriptLoad(mapRefernce, geometry);
		}
	});
};

const handleScriptLoad = (mapRefernce, geometry) => {
	if (mapRefernce && mapRefernce.current) {
		map = new window.google.maps.Map(mapRefernce.current, {
			center: { lat: -33.8688, lng: 151.2195 },
			zoom: 17,
			panControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			overviewMapControl: false,
			rotateControl: false
		});

		marker = new window.google.maps.Marker({
			map: map,
			anchorPoint: new window.google.maps.Point(0, -29)
		});

		if (geometry.viewport) {
			map.fitBounds(geometry.viewport);
		} else {
			map.setCenter(geometry.location);
		}

		marker.setPosition(geometry.location);
		marker.setVisible(true);
	}
};

export function Map(props) {
	const { geometry } = props;

	const mapRefernce = useRef(null);

	useEffect(() => {
		loadScript(mapRefernce, geometry);
	}, [geometry]);

	return <div className="map-frame" ref={mapRefernce} />;
}
