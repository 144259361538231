import React from "react";
import { Header, Button } from "semantic-ui-react";
import "../scss/components/_subheader.scss";

export function BoxSubHeader(props) {
    const { title, boxName, history } = props;

    const goToBack = () => {
        history.goBack();
    };

    const backButton = (
        <Button className="back-close" size="huge" onClick={goToBack}>
            &#x2715;
        </Button>
    );

    return (
        <div className="box-sub-header-container container-padding">
            <div className="sub-header">
                <Header as="h1" textAlign="center" className="box-title">
                    {title}
                </Header>
                <p className="box-subtitle">{boxName}</p>
                {backButton}
            </div>
        </div>
    );
}
