import React from "react";
import moment from "moment";
import { Card, Icon, Popup, Button } from "semantic-ui-react";
import "../../scss/components/_cards.scss";
import * as CONSTANTS from "../../constants/Global";

export function SettingLinksCard(props) {
    const { hasIcon, title, items, history, blockAddFeatures } = props;

    const itemKeys = Object.keys(items);
    const itemValues = Object.values(items);

    let chevronIcon = null;

    if (hasIcon) {
        chevronIcon = <Icon name="chevron right" size="large" />;
    }

    return (
        <>
            <Card className="settings-card" fluid>
                <Card.Content className="header-container">
                    <Card.Header>{title}</Card.Header>
                    {chevronIcon}
                </Card.Content>
                <Card.Content>
                    {itemKeys.map((item, index) => {
                        const itemSplited = item.split("_");
                        const itemName = itemSplited[0];
                        const itemRoute = itemSplited[1];
                        let maxPhotosQuantity = 5;

                        const changePhotosQuantity = moment(items.createdAt).isAfter(CONSTANTS.FOUR_PHOTOS_DATE);

                        if (changePhotosQuantity) {
                            maxPhotosQuantity = 4;
                        }

                        let dataClassName = "info-data link";
                        let photosLeftText = "";

                        if (itemName === "Photos" || itemName === "Note") {
                            let routeFunction = () => history.push(itemRoute, items);
                            if (itemName === "Photos") {
                                const photosLeft = maxPhotosQuantity - items["features"].photos.files.length;
                                if (photosLeft > 0 && photosLeft < maxPhotosQuantity) {
                                    photosLeftText = `(Left ${photosLeft})`;
                                }
                                if (itemValues[index].includes("uploaded")) {
                                    photosLeftText = "";
                                }
                                if (itemValues[index].includes("Skipped")) {
                                    routeFunction = null;
                                }
                            } else {
                                if (itemValues[index].includes("uploaded") || itemValues[index].includes("Skipped")) {
                                    routeFunction = null;
                                }
                            }

                            if (itemValues[index].includes("Pending") && !blockAddFeatures) {
                                dataClassName += " red-color";
                            } else if (itemValues[index].includes("uploaded")) {
                                dataClassName += " green-color";
                            }

                            if (blockAddFeatures) {
                                dataClassName += " color-gray";
                                routeFunction = null;
                            }

                            return (
                                <div key={index} className="info-container">
                                    <p className="info-title">{itemName}:</p>
                                    <p className={dataClassName} onClick={routeFunction}>
                                        {`${itemValues[index]} ${photosLeftText}`}
                                        {blockAddFeatures && (
                                            <Popup
                                                content="Currently time's up to add this feature. (Remember you has until 10th of every month in normal boxes or 3 days before deliver date on special boxes like Welcome Box or Upgrade Box"
                                                trigger={<Button className="popup-button" circular basic icon="info" />}
                                            />
                                        )}
                                    </p>
                                </div>
                            );
                        }
                        return null;
                    })}
                </Card.Content>
            </Card>
        </>
    );
}
