import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container, Grid } from "semantic-ui-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "../../components/screenComponents/subscribe/stepSix/StripeForm";
import { SubHeader, BackButton, LoadingModal, ErrorModal, SuccessModal } from "../../components";
import { updatePaymentMethod } from "../../services/SetupService";
import { ERROR_CHANGING_PAYMENT_METHOD_DEFAULT } from "../../constants/Errors";
import { SUBSCRIPTION_SUMMARY } from "../../navigation/Routes";

function UpdatePaymentMethod(props) {
    const { history, user } = props;
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

    const [loadingNewPaymentMethod, setLoadingNewPaymentMethod] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [cardElementsState, setCardElementsStates] = useReducer((state, newState) => ({ ...state, ...newState }), {
        cardCvcEmpty: true,
        cardExpiryEmpty: true,
        cardNumberEmpty: true,
        cardNumberEmptyError: "",
        cardExpiryEmptyError: "",
        cardCvcEmptyError: "",
    });
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        localFirstName: "",
        localLastName: "",
        localPostalCode: "",
        localFirstNameError: false,
        localLastNameError: false,
        localPostalCodeError: false,
    });

    const onChangeCardNumber = (event) => {
        const cardElement = event.elementType;

        if (event.complete) {
            setCardElementsStates({
                [`${cardElement}Empty`]: event.empty,
                [`${cardElement}EmptyError`]: false,
            });
        } else {
            setCardElementsStates({
                [`${cardElement}Empty`]: event.empty,
                [`${cardElement}EmptyError`]: event.error && event.error.message,
            });
        }
    };

    const onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;
        setStateForm({
            [inputId]: value,
            [`${inputId}Error`]: false,
        });
    };

    const submitPaymentMethod = async (response) => {
        if (response) {
            setLoadingNewPaymentMethod(true);

            const updatePaymentMethodResponse = await updatePaymentMethod({
                paymentMethod: response.id,
                ownerId: user.userData.user_id,
            });

            if (updatePaymentMethodResponse) {
                setLoadingNewPaymentMethod(false);
                if (typeof updatePaymentMethodResponse === "boolean") {
                    setOpenSuccessModal(true);
                } else {
                    setShowErrorModal(true);
                }
            }
        }
    };

    const goToManageAccount = () => {
        setOpenSuccessModal(false);
        setShowErrorModal(false);
        history.push(SUBSCRIPTION_SUMMARY, { tabIndex: 0 });
    };

    const loadingModal = loadingNewPaymentMethod && (
        <LoadingModal open={loadingNewPaymentMethod} message="Updating your new payment method, please wait..." />
    );

    const errorMoodal = showErrorModal && (
        <ErrorModal
            open={showErrorModal}
            message={ERROR_CHANGING_PAYMENT_METHOD_DEFAULT}
            closeFunction={goToManageAccount}
        />
    );

    const successModal = openSuccessModal && (
        <SuccessModal
            open={openSuccessModal}
            message="Your payment method was changed successfully."
            successAction={goToManageAccount}
            closeAction={goToManageAccount}
        />
    );

    return (
        <Container>
            {successModal}
            {loadingModal}
            {errorMoodal}
            <Grid centered style={{ margin: 0 }}>
                <SubHeader mainTitle="Payment method" hasDivider={true} />
                <Grid.Row>
                    <Grid.Column computer={7} mobile={16}>
                        <Elements
                            stripe={stripePromise}
                            options={{
                                locale: "en",
                                cssSrc: "https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap",
                            }}
                        >
                            <StripeForm
                                showCreditCardForm={true}
                                onChangeCardNumber={onChangeCardNumber}
                                onChangeWriteOptions={onChangeWriteOptions}
                                cardElementsState={cardElementsState}
                                formState={formState}
                                submitButtonText="Update Payment Method"
                                submitPaymentMethod={submitPaymentMethod}
                                loadingNewPaymentMethod={loadingNewPaymentMethod}
                            />
                        </Elements>
                    </Grid.Column>
                    <BackButton title="Back" tabIndex={0} />
                </Grid.Row>
            </Grid>
        </Container>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, null))(withRouter(UpdatePaymentMethod));
