import CancelSubscription from "../../screens/cancel-subscriptions/CancelSubscription";
import CancelSurvey from "../../screens/cancel-subscriptions/CancelSurvey";
import ConfirmCancelation from "../../screens/cancel-subscriptions/ConfirmCancelation";
import * as ROUTES from "../Routes";

export const CancelSubscriptionStack = [
    {
        path: ROUTES.CANCEL_SUBSCRIPTION_REMAINING,
        component: CancelSubscription,
    },
    {
        path: ROUTES.CANCEL_SURVEY,
        component: CancelSurvey,
    },
    {
        path: ROUTES.CONFIRM_CANCELATION,
        component: ConfirmCancelation,
    },
];
