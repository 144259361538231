import React from "react";
import { Label, Dropdown, Icon } from "semantic-ui-react";
import "../../scss/components/_dropdown.scss";

export function DropdownCheckbox(props) {
    const { id, label, options, placeholder, onChangeAction, value, destroyMultipleWith, reference, isTwoDropdowns } =
        props;

    let dropdownLabel = null;
    if (label) {
        dropdownLabel = <Label>{label}</Label>;
    }

    let iconDisabled = true;
    let selectorClasess = "dropdown-container";

    if (value.length > 0) {
        iconDisabled = false;
        selectorClasess += " filled";
    }

    if (isTwoDropdowns) {
        selectorClasess += " with-z-index-99";
    }

    const onChange = (event, { value }) => {
        let dietaryList = value;
        const isMultiple = Array.isArray(value);

        if (isMultiple) {
            const noRestrictionsSelected = value.find((selection) => selection === destroyMultipleWith);

            if (noRestrictionsSelected) {
                dietaryList = noRestrictionsSelected;
            } else {
                dietaryList = value;
            }
        } else {
            if (value !== destroyMultipleWith) {
                dietaryList = [value];
            }
        }

        const data = {
            id: id,
            value: dietaryList,
        };

        onChangeAction(data);
    };

    const isMultiple = Array.isArray(value);

    return (
        <div className={selectorClasess}>
            {dropdownLabel}
            <Dropdown
                ref={reference}
                closeOnChange
                selection
                multiple={isMultiple}
                fluid
                options={options}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                icon={<Icon disabled={iconDisabled} name="chevron down" />}
            />
        </div>
    );
}
