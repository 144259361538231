import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import moment from "moment";
import { ErrorModal, LoadingModal, ConfirmationModal } from "../../components";
import AddBoxPhotosComponent from "../../components/screenComponents/account/AddBoxPhotosComponent";
import { uploadFiles } from "../../services/AuthService";
import { updateBox } from "../../services/SetupService";
import { updateBoxSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import { saveUserSubscriptions } from "../../store/user/actions";
import * as ERRORS from "../../constants/Errors";
import * as LOADINGS from "../../constants/Loadings";
import * as CONSTANTS from "../../constants/Global";
import * as CONFIRMATIONS from "../../constants/Confirmations";
import "../../scss/pages/account.scss";

function AddBoxPhotos(props) {
    const { history, location, user, saveUserSubscriptions } = props;
    const boxData = location.state;
    const selectedSubscription =
        user.subscriptions &&
        user.subscriptions.find((subscription) => subscription.subscriptionId === boxData.subscriptionId);

    const [photosPreview, setPhotosPreview] = useState([]);
    const [loadingUploadPhotos, setLoadingUploadPhotos] = useState(false);
    const [showErrorModal, setShowErroModal] = useState(false);
    const [parentsNames, setParentNames] = useState("");
    const [openConfirmModal, setOpenConfirmationModal] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [photosAction, setPhotosAction] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [photosQuantity, setPhotosQuantity] = useState(5);

    useEffect(() => {
        if (selectedSubscription) {
            const changePhotosQuantity = moment(selectedSubscription.createdAt).isAfter(CONSTANTS.FOUR_PHOTOS_DATE);

            if (changePhotosQuantity) {
                setPhotosQuantity(4);
            }
        }
    }, [selectedSubscription]);

    useEffect(() => {
        const boxPhotos = boxData.features.photos.files;

        if (boxPhotos.length > 0) {
            setPhotosPreview(boxPhotos);
        }

        if (boxData.parents.length > 0) {
            const parentsNames = boxData.parents.map((parent) => {
                return parent.name;
            });

            const parentsString = parentsNames.join(" and ");

            setParentNames(parentsString);
        }
    }, [boxData]);

    const onChangeFile = async (event) => {
        const photos = event.target.files;

        const photosPreviewLength = photosPreview.length + photos.length;

        if (photosPreviewLength <= photosQuantity) {
            for (let i = 0; i < photos.length; i++) {
                (function (photo) {
                    const fileName = photo.name;
                    const reader = new FileReader();
                    reader.onload = function (event) {
                        const img = new Image();
                        const dataURL = event.target.result;
                        img.src = dataURL;
                        img.onload = () => {
                            const decoded = fileName.split(".");
                            const extention = decoded[decoded.length - 1];
                            const indexToDelete = dataURL.indexOf(",");
                            const encodeString = dataURL.substring(indexToDelete + 1);

                            const photoData = {
                                base64: dataURL,
                                encodeString: encodeString,
                                name: `${moment().format("x")}-${i}.${extention}`,
                                metadata: {
                                    contentType: `image/${extention}`,
                                },
                            };

                            setPhotosPreview((old) => [...old, photoData]);
                        };
                    };
                    reader.readAsDataURL(photo);
                })(photos[i]);
            }
        } else {
            setShowErroModal(true);
            setErrorMessage(
                <>
                    <span>{ERRORS.ERROR_ONLY_CAN_UPLOAD_FIVE_PHOTOS(photosQuantity)}</span> <br />{" "}
                    <strong className="color-cta">
                        Can select {photosQuantity - photosPreview.length} photo(s) more.
                    </strong>
                </>
            );
        }
    };

    const removePhotoPreview = (event) => {
        const photoName = event.target.id;
        const newPhotosList = photosPreview.filter((photo) => photo.name !== photoName);

        setPhotosPreview(newPhotosList);
    };

    const savePhotos = async () => {
        setLoadingUploadPhotos(true);
        handleConfirmationModal();
        const photosData = {
            files: photosPreview,
            ownerId: boxData.ownerId,
            boxId: boxData.boxId,
        };

        const uploadImages = await uploadFiles(photosData);

        if (uploadImages) {
            if (uploadImages.filesSaved.length > 0) {
                const uploadedPhotos = photosPreview.filter((file) => !file.hasOwnProperty("base64"));
                const uploadPhotos = uploadedPhotos.concat(uploadImages.filesSaved);

                console.log(boxData.boxId);
                const dataUpload = {
                    input: {
                        boxId: boxData.boxId,
                        features: {
                            photos: {
                                files: uploadPhotos,
                                status: CONSTANTS.FEATURES_STATUS.EMPTY,
                            },
                        },
                    },
                };

                const boxUpdated = await updateBox(dataUpload);

                if (boxUpdated) {
                    const subscriptions = user.subscriptions;
                    setLoadingUploadPhotos(false);
                    const updated = updateBoxSubscriptionsStore(saveUserSubscriptions, subscriptions, boxUpdated);

                    if (updated) {
                        history.goBack();
                    }
                } else {
                    setLoadingUploadPhotos(false);
                    setShowErroModal(true);
                    setErrorMessage(ERRORS.ERROR_UPLOADIND_PHOTOS);
                }
            }

            if (uploadImages.error) {
                setLoadingUploadPhotos(false);
                setShowErroModal(true);
            }
        }
    };

    const changeFeatureStatus = async () => {
        handleConfirmationModal();
        setButtonLoading(true);
        const uploadedPhotos = photosPreview.filter((file) => !file.hasOwnProperty("base64"));

        const dataUpload = {
            input: {
                boxId: boxData.boxId,
                features: {
                    photos: {
                        files: uploadedPhotos,
                        status: photosAction,
                    },
                },
            },
        };

        const boxUpdated = await updateBox(dataUpload);

        if (boxUpdated) {
            const subscriptions = user.subscriptions;
            setButtonLoading(true);
            const updated = updateBoxSubscriptionsStore(saveUserSubscriptions, subscriptions, boxUpdated);

            if (updated) {
                history.goBack();
            }
        } else {
            setButtonLoading(true);
            setShowErroModal(true);
        }
    };

    const closeErrorModal = () => {
        setShowErroModal(false);
        setButtonLoading(false);
        setErrorMessage("");
    };

    const handleConfirmationModal = (action) => {
        setOpenConfirmationModal(!openConfirmModal);

        console.log(action);

        if (action) {
            if (action === "SAVE") {
                setPhotosAction(action);
                setConfirmMessage(CONFIRMATIONS.UPLOD_PHOTOS_CONFIRMATION);
            } else {
                setPhotosAction(action);
                if (action === CONSTANTS.FEATURES_STATUS.SKIPPED) {
                    setConfirmMessage(CONFIRMATIONS.SKIP_PHOTOS);
                } else {
                    setConfirmMessage(CONFIRMATIONS.NO_UPLOAD_MORE_PHOTOS);
                }
            }
        }
    };

    let errorModal = null;

    if (showErrorModal) {
        errorModal = <ErrorModal open={showErrorModal} message={errorMessage} closeFunction={closeErrorModal} />;
    }

    let loadingModal = null;

    if (loadingUploadPhotos) {
        loadingModal = <LoadingModal open={loadingUploadPhotos} message={LOADINGS.LOADING_PHOTOS} type="upload" />;
    }

    let confirmModal = null;

    if (openConfirmModal) {
        confirmModal = (
            <ConfirmationModal
                open={openConfirmModal}
                message={confirmMessage}
                closeAction={handleConfirmationModal}
                confirmAction={photosAction === "SAVE" ? () => savePhotos() : () => changeFeatureStatus()}
            />
        );
    }

    return (
        <>
            {confirmModal}
            {loadingModal}
            {errorModal}
            {showErrorModal}

            <Container>
                <AddBoxPhotosComponent
                    parentsNames={parentsNames}
                    history={history}
                    onChangeFile={onChangeFile}
                    removePhotoPreview={removePhotoPreview}
                    photosPreview={photosPreview}
                    savePhotos={savePhotos}
                    loadingUploadPhotos={loadingUploadPhotos}
                    handleConfirmationModal={handleConfirmationModal}
                    photosStatus={boxData.features.photos.status.toUpperCase()}
                    buttonLoading={buttonLoading}
                    boxStatus={boxData.status}
                    boxMonth={boxData.month}
                    photosQuantity={photosQuantity}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveUserSubscriptions: (subscriptionsData) => dispatch(saveUserSubscriptions(subscriptionsData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(AddBoxPhotos));
