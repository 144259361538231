export const updateBoxSubscriptionsStore = (reduxAction, store, data) => {
    const storeRefreshed = store.map((subscription) => {
        return {
            ...subscription,
            boxes: subscription.boxes.map((box) => {
                if (box.boxId === data.boxId) {
                    return {
                        ...data,
                    };
                } else {
                    return {
                        ...box,
                    };
                }
            }),
        };
    });

    reduxAction(storeRefreshed);
    return true;
};

export const updateSubscriptionsStore = (reduxAction, store, data) => {
    const storeRefreshed = store.map((subscription) => {
        if (subscription.subscriptionId === data.subscriptionId) {
            return data;
        } else {
            return subscription;
        }
    });

    reduxAction(storeRefreshed);
    return true;
};
