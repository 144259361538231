import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import moment from "moment";
import jsPDF from "jspdf";
import { ErrorModal, LoadingModal, ConfirmationModal } from "../../components";
import AddBoxNoteComponent from "../../components/screenComponents/account/AddBoxNoteComponent";
import { uploadFiles } from "../../services/AuthService";
import { updateBox } from "../../services/SetupService";
import { updateBoxSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import { saveUserSubscriptions } from "../../store/user/actions";
import * as ERRORS from "../../constants/Errors";
import * as LOADINGS from "../../constants/Loadings";
import * as CONSTANTS from "../../constants/Global";
import * as CONFIRMATIONS from "../../constants/Confirmations";
import "../../scss/pages/account.scss";
import EBGaramondFont from "../../fonts/EBGaramond-Regular.ttf";

function AddBoxNote(props) {
    const { history, location, user, saveUserSubscriptions } = props;
    const boxData = location.state;
    const [customerNote, setCustomerNote] = useState("");
    const [pdfFile, setPdfFile] = useState({});
    const [loadingUploadNote, setLoadingUploadNote] = useState(false);
    const [showErrorModal, setShowErroModal] = useState(false);
    const [parentsNames, setParentNames] = useState("");
    const [openConfirmModal, setOpenConfirmationModal] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [dinamicFontSize, setDinamicFontSize] = useState(0);
    const [confirmMessage, setConfirmMessage] = useState("");
    const [noteAction, setNoteAction] = useState("");

    useEffect(() => {
        if (boxData.parents.length > 0) {
            const parentsNames = boxData.parents.map((parent) => {
                return parent.name;
            });

            const parentsString = parentsNames.join(" and ");

            setParentNames(parentsString);
        }
    }, [boxData]);

    useEffect(() => {
        const customerNoteLength = customerNote.length;

        getPDFFontSise(customerNoteLength);
    }, [customerNote]);

    const onchangeCustomerNote = (event, { value }) => {
        setCustomerNote(value);
    };

    const onChangeFile = (event) => {
        const fileToLoad = event.target.files[0];
        if (fileToLoad) {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const fileBase64 = fileReader.result;
                const indexToDelete = fileBase64.indexOf(",");
                const encodeString = fileBase64.substring(indexToDelete + 1);

                const pdfFileData = {
                    type: "upload",
                    name: `${moment().format("x")}.pdf`,
                    base64: fileBase64,
                    extention: "pdf",
                    encodeString: encodeString,
                    metadata: {
                        contentType: "aplication/pdf",
                    },
                };

                return setPdfFile(pdfFileData);
            };

            fileReader.readAsDataURL(fileToLoad);
        }
    };

    const cleanPdfFile = () => {
        setPdfFile({});
    };

    const saveNote = async (action) => {
        setLoadingUploadNote(true);
        handleConfirmationModal();

        const noteData = {
            files: [pdfFile],
            ownerId: boxData.ownerId,
            boxId: boxData.boxId,
        };

        const uploadNote = await uploadFiles(noteData);

        if (uploadNote) {
            if (uploadNote.filesSaved.length > 0) {
                const dataUpload = {
                    input: {
                        boxId: boxData.boxId,
                        features: {
                            note: {
                                files: uploadNote.filesSaved,
                                status: CONSTANTS.FEATURES_STATUS.EMPTY,
                            },
                        },
                    },
                };

                const boxUpdated = await updateBox(dataUpload);

                if (boxUpdated) {
                    const subscriptions = user.subscriptions;
                    setLoadingUploadNote(false);
                    const updated = updateBoxSubscriptionsStore(saveUserSubscriptions, subscriptions, boxUpdated);

                    if (updated) {
                        history.goBack();
                    }
                } else {
                    setLoadingUploadNote(false);
                    setShowErroModal(true);
                }
            }

            if (uploadNote.error) {
                setLoadingUploadNote(false);
                setShowErroModal(true);
            }
        }
    };

    const changeFeatureStatus = async () => {
        handleConfirmationModal();
        setButtonLoading(true);
        const dataUpload = {
            input: {
                boxId: boxData.boxId,
                features: {
                    note: {
                        files: [],
                        status: CONSTANTS.FEATURES_STATUS.SKIPPED,
                    },
                },
            },
        };

        const boxUpdated = await updateBox(dataUpload);

        if (boxUpdated) {
            const subscriptions = user.subscriptions;
            setButtonLoading(true);
            const updated = updateBoxSubscriptionsStore(saveUserSubscriptions, subscriptions, boxUpdated);

            if (updated) {
                history.goBack();
            }
        } else {
            setButtonLoading(true);
            setShowErroModal(true);
        }
    };

    const getPDFFromNote = () => {
        const pdfConfig = {
            orientation: "p",
            unit: "in",
            format: "letter",
            floatPrecision: 2,
        };

        const fileName = `${moment().format("x")}.pdf`;
        const documentPDF = new jsPDF(pdfConfig);
        documentPDF.addFileToVFS(EBGaramondFont, "EB Garamond");
        documentPDF.setFont("EB Garamond", "regular");
        const fontSize = dinamicFontSize;
        documentPDF.setFontSize(fontSize);

        let marginTop = 2.3;

        if (fontSize > 20) {
            marginTop = 3.6;
        }

        const paraghaphs = customerNote.split(/\n/g);
        const spacingParagraphs = paraghaphs.map((paraghaph) => {
            if (paraghaph) {
                return paraghaph + "\n";
            } else {
                return "\n";
            }
        });

        documentPDF.text(spacingParagraphs, 1.2, marginTop, {
            maxWidth: 6.2,
        });

        const fileBase64 = documentPDF.output("datauristring", {
            filename: fileName,
        });

        const indexToDelete = fileBase64.indexOf(",");
        const encodeString = fileBase64.substring(indexToDelete + 1);

        const pdfFileData = {
            type: "write",
            name: fileName,
            base64: fileBase64,
            extention: "pdf",
            encodeString: encodeString,
            metadata: {
                contentType: "aplication/pdf",
            },
        };

        setPdfFile(pdfFileData);
    };

    const getPDFFontSise = (textLength) => {
        let fontSize = 0;

        if (textLength <= 300) {
            fontSize = 30;
        }

        if (textLength > 300 && textLength <= 900) {
            fontSize = 14;
        }

        if (textLength > 900 && textLength <= 1400) {
            fontSize = 13;
        }

        if (textLength > 1400 && textLength <= 1800) {
            fontSize = 12;
        }

        if (textLength > 1800 && textLength <= 2000) {
            fontSize = 11;
        }

        setDinamicFontSize(fontSize);
    };

    const closeErrorModal = () => {
        setShowErroModal(false);
    };

    const handleConfirmationModal = (action) => {
        setOpenConfirmationModal(!openConfirmModal);

        if (action) {
            if (action === "SAVE") {
                setNoteAction(action);
                setConfirmMessage(CONFIRMATIONS.UPLOD_NOTE_CONFIRMATION);
            } else {
                setNoteAction(action);
                if (action === CONSTANTS.FEATURES_STATUS.SKIPPED) {
                    setConfirmMessage(CONFIRMATIONS.SKIP_NOTE);
                }
            }
        }
    };

    let errorModal = null;

    if (showErrorModal) {
        errorModal = (
            <ErrorModal open={showErrorModal} message={ERRORS.ERROR_UPLOADIND_PHOTOS} closeFunction={closeErrorModal} />
        );
    }

    let loadingModal = null;

    if (loadingUploadNote) {
        loadingModal = <LoadingModal open={loadingUploadNote} message={LOADINGS.LOADING_NOTE} type="upload" />;
    }

    let confirmModal = null;

    if (openConfirmModal) {
        confirmModal = (
            <ConfirmationModal
                open={openConfirmModal}
                message={confirmMessage}
                closeAction={handleConfirmationModal}
                confirmAction={noteAction === "SAVE" ? () => saveNote() : () => changeFeatureStatus()}
            />
        );
    }

    return (
        <>
            {confirmModal}
            {loadingModal}
            {errorModal}
            <Container>
                <AddBoxNoteComponent
                    parentsNames={parentsNames}
                    history={history}
                    onchangeCustomerNote={onchangeCustomerNote}
                    onChangeFile={onChangeFile}
                    customerNote={customerNote}
                    pdfFile={pdfFile}
                    cleanPdfFile={cleanPdfFile}
                    saveNote={saveNote}
                    loadingSaveNote={loadingUploadNote}
                    getPDFFromNote={getPDFFromNote}
                    handleConfirmationModal={handleConfirmationModal}
                    noteStatus={boxData.features.note.status.toUpperCase()}
                    buttonLoading={buttonLoading}
                    boxStatus={boxData.status}
                    boxMonth={boxData.month}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveUserSubscriptions: (subscriptionsData) => dispatch(saveUserSubscriptions(subscriptionsData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(AddBoxNote));
