import React, { useState } from 'react';
import { Label, Input, Button } from 'semantic-ui-react';
import '../../scss/components/_input.scss';

export function CustomPasswordInput(props) {
	const { id, label, placeholder, onChangeAction, value, errorMessage } = props;
	const iconHidePwd = 'eye slash outline';
	const iconShowPwd = 'eye';

	const [inputIcon, setInputIcon] = useState(iconHidePwd);
	const [inputType, setInputType] = useState('password');

	let inputLabel = null;

	if (value && value.length > 0 && label) {
		inputLabel = <Label floating>{label}</Label>;
	}

	const changeInputIcon = () => {
		if (inputIcon === iconHidePwd) {
			setInputIcon(iconShowPwd);
			setInputType('text');
		} else {
			setInputIcon(iconHidePwd);
			setInputType('password');
		}
	};

	let className = 'input-password-content';
	let errorInfo = null;

	const showError = errorMessage && errorMessage.length > 0;

	if (showError) {
		className += ' input-error';
		errorInfo = <p className="input-error">{errorMessage}</p>;
	}

	return (
		<div className="input-password-container">
			<div className={className}>
				{inputLabel}
				<Input
					id={id}
					type={inputType}
					fluid
					placeholder={placeholder}
					onChange={onChangeAction}
					value={value}
					error={showError}
				/>
				<Button onClick={changeInputIcon} size="big" icon={inputIcon} />
			</div>
			{errorInfo}
		</div>
	);
}
