import React, { useState, useEffect } from "react";
import moment from "moment";
import { useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import {
    CustomRadioButton,
    CustomInput,
    CustomCardInput,
    DefaultButton,
    PaymentResponseModal,
    CreateSubscriptionModal,
    Loader,
} from "../../..";
import { prepareSubmitSubscriptionData } from "../../../../services/SubscriptionPlanService";
import { postSubscription, getUserSubscriptions, checkLastInvoice } from "../../../../services/SetupService";
import * as ROUTES from "../../../../navigation/Routes";
import * as ERRORS from "../../../../constants/Errors";
import * as CONSTANTS from "../../../../constants/Global";

export default function StripeForm(props) {
    const {
        formState,
        cardElementsState,
        paymentMethod,
        ordersSummaryData,
        onChangePaymentMethod,
        onChangeWriteOptions,
        onChangeCardNumber,
        saveAndPay,
        subscriptions,
        history,
        processPayment,
        resetFormValidation,
        handleLoadingSecondaryButton,
        loadingPaymentMethod,
        lastPaymentMethod,
        showCreditCardForm,
        previousIncompleteSubscription,
        submitButtonText,
        submitPaymentMethod,
    } = props;

    const stripe = useStripe();
    const elements = useElements();

    const [loadingPayment, setLoadingPayment] = useState(false);
    const [paymentError, setPaymentError] = useState("");
    const [showPaymentResponseModal, setShowPaymentResponseModal] = useState(false);
    const [paymentLink, setPaymentLink] = useState(false);
    const [showCreateSubsModal, setShowCreateSubsModal] = useState(false);
    const [loadingSubscriptions, setLoadingSubscriptions] = useState(false);
    const [retryPaymentVerification, setRetryPaymentVerification] = useState(false);

    useEffect(() => {
        if (processPayment) {
            setShowCreateSubsModal(true);
        }
    }, [processPayment]);

    useEffect(() => {
        if (previousIncompleteSubscription) {
            setShowPaymentResponseModal(true);
            setPaymentError(previousIncompleteSubscription.message);
            setPaymentLink(previousIncompleteSubscription.link);
        }
    }, [previousIncompleteSubscription]);

    const handleSubmit = async () => {
        resetFormValidation();
        handleLoadingSecondaryButton(true);
        if (showCreditCardForm) {
            if (paymentError === "") {
                if (!stripe || !elements) {
                    return;
                }
                const cardNumberElement = elements.getElement(CardNumberElement);

                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardNumberElement,
                    billing_details: {
                        name: `${formState.localFirstName} ${formState.localLastName}`,
                        address: {
                            postal_code: formState.localPostalCode,
                        },
                    },
                });

                if (error) {
                    setLoadingPayment(false);
                    setShowPaymentResponseModal(true);
                    setPaymentError(error.message);
                    handleLoadingSecondaryButton(false);

                    return;
                } else {
                    setLoadingPayment(true);

                    const submitSubscriptionData = prepareSubmitSubscriptionData(
                        subscriptions,
                        paymentMethod,
                        ordersSummaryData,
                        formState.subscribeNewsLetter
                    );
                    if (submitSubscriptionData) {
                        sendPayment(submitSubscriptionData);
                    }
                }
            }
        } else {
            const submitSubscriptionData = prepareSubmitSubscriptionData(
                subscriptions,
                null,
                ordersSummaryData,
                formState.subscribeNewsLetter
            );
            setLoadingPayment(true);

            if (paymentError === "") {
                sendPayment(submitSubscriptionData);
            }
        }
    };

    const handlePaymentMethodSubmit = async () => {
        if (!stripe || !elements) {
            return;
        }
        const cardNumberElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardNumberElement,
            billing_details: {
                name: `${formState.localFirstName} ${formState.localLastName}`,
                address: {
                    postal_code: formState.localPostalCode,
                },
            },
        });

        if (error) {
            submitPaymentMethod(error);
        } else {
            return submitPaymentMethod(paymentMethod);
        }
    };

    const sendPayment = async (submitSubscriptionData) => {
        if (loadingPayment && !showPaymentResponseModal) {
            const subscriptionResponse = await postSubscription(submitSubscriptionData);
            // console.log(JSON.stringify(submitSubscriptionData));
            if (subscriptionResponse) {
                if (subscriptionResponse.hasOwnProperty("message")) {
                    setLoadingPayment(false);
                    setShowPaymentResponseModal(true);
                    setPaymentError(subscriptionResponse.message);
                    setShowCreateSubsModal(false);
                    handleLoadingSecondaryButton(false);
                    if (subscriptionResponse.hasOwnProperty("link")) {
                        setPaymentLink(subscriptionResponse.link);
                    }
                } else {
                    setLoadingPayment(false);
                    setShowCreateSubsModal(false);
                    handleLoadingSecondaryButton(false);
                    history.replace(ROUTES.SUCCESSFUL, subscriptionResponse.subscriptionId);
                }
            } else {
                setLoadingPayment(false);
                setShowPaymentResponseModal(true);
                setPaymentError(ERRORS.POST_SUBSCRIPTION_ERROR);
                setShowCreateSubsModal(false);
                handleLoadingSecondaryButton(false);
            }
        }
    };

    const closeErroModal = () => {
        setShowPaymentResponseModal(false);
        setPaymentError("");
        setLoadingPayment(false);
    };

    const checkLastPayment = async (sessionId) => {
        // console.log(sessionId);
        setLoadingSubscriptions(true);

        try {
            const subscriptions = await getUserSubscriptions();

            if (subscriptions && subscriptions.length > 0) {
                const lastSubscriptionTime = Math.max.apply(
                    Math,
                    subscriptions.map((subscription) => {
                        const createdAt = Number(moment(subscription.createdAt).format("x"));
                        return createdAt;
                    })
                );

                let lastSubscription = null;

                if (sessionId) {
                    lastSubscription = subscriptions.find((subscription) => subscription.sessionId === sessionId);
                } else {
                    lastSubscription = subscriptions.find(
                        (subscription) => Number(moment(subscription.createdAt).format("x")) === lastSubscriptionTime
                    );
                }

                if (lastSubscription) {
                    const checkInvoice = await checkLastInvoice({
                        subscriptionId: lastSubscription.subscriptionId,
                    });

                    if (checkInvoice === CONSTANTS.INVOICE_STATUS.PAID) {
                        setRetryPaymentVerification(false);
                        setLoadingPayment(false);
                        setShowCreateSubsModal(false);
                        handleLoadingSecondaryButton(false);
                        history.replace(ROUTES.SUCCESSFUL, lastSubscription.subscriptionId);
                        setLoadingSubscriptions(false);
                    } else {
                        if (checkInvoice === CONSTANTS.INVOICE_STATUS.UNPAID) {
                            setLoadingSubscriptions(false);
                            setRetryPaymentVerification(true);
                        }
                    }
                }
            }
        } catch (error) {}
    };

    let paymentResponseModal = null;

    if (showPaymentResponseModal) {
        paymentResponseModal = (
            <PaymentResponseModal
                open={showPaymentResponseModal}
                history={history}
                message={paymentError}
                paymentLink={paymentLink}
                closeFunction={closeErroModal}
                checkLastPayment={checkLastPayment}
                loadingSubscriptions={loadingSubscriptions}
                retryPaymentVerification={retryPaymentVerification}
            />
        );
    }

    let createSubscriptionModal = null;

    if (showCreateSubsModal) {
        createSubscriptionModal = (
            <CreateSubscriptionModal
                open={showCreateSubsModal}
                confirmAction={handleSubmit}
                paymentError={paymentError}
                setShowCreateSubsModal={setShowCreateSubsModal}
            />
        );
    }

    let cardInputForm = null;

    if (showCreditCardForm) {
        cardInputForm = (
            <div className="input-container container-padding-half">
                <CustomInput
                    id="localFirstName"
                    placeholder="First Name"
                    onChangeAction={onChangeWriteOptions}
                    value={formState.localFirstName}
                    errorMessage={formState.localFirstNameError}
                    type="text"
                    label="First Name"
                />
                <CustomInput
                    id="localLastName"
                    placeholder="Last Name"
                    onChangeAction={onChangeWriteOptions}
                    value={formState.localLastName}
                    errorMessage={formState.localLastNameError}
                    type="text"
                    label="Last Name"
                />
                <CustomCardInput
                    label="Card Number"
                    placeholder="Card Number"
                    onChangeAction={onChangeCardNumber}
                    cardNumberEmpty={cardElementsState.cardNumberEmpty}
                    errorMessage={cardElementsState.cardNumberEmptyError}
                />
                <div className="multi-input-line">
                    <div className="input-half">
                        <CustomCardInput
                            label="MM / YY"
                            onChangeAction={onChangeCardNumber}
                            cardExpiryEmpty={cardElementsState.cardExpiryEmpty}
                            type="expiration"
                            errorMessage={cardElementsState.cardExpiryEmptyError}
                        />
                    </div>
                    <div className="input-half">
                        <CustomCardInput
                            label="CVV"
                            onChangeAction={onChangeCardNumber}
                            cardCvcEmpty={cardElementsState.cardCvcEmpty}
                            type="cvv"
                            errorMessage={cardElementsState.cardCvcEmptyError}
                        />
                    </div>
                </div>
                <CustomInput
                    id="localPostalCode"
                    placeholder="Postal Code"
                    onChangeAction={onChangeWriteOptions}
                    value={formState.localPostalCode}
                    errorMessage={formState.localPostalCodeError}
                    type="text"
                    label="Postal Code"
                />
            </div>
        );
    }

    let lastPaymentRadioButton = null;

    if (lastPaymentMethod) {
        lastPaymentRadioButton = (
            <>
                <CustomRadioButton
                    id="lastPayment"
                    label={`Last used: XXXX XXXX XXXX ${lastPaymentMethod.last4}`}
                    onChangeAction={onChangePaymentMethod}
                    value={paymentMethod}
                    isSmallSize={true}
                />
                <CustomRadioButton
                    id="anotherCard"
                    label="Use another card"
                    onChangeAction={onChangePaymentMethod}
                    value={paymentMethod}
                    isSmallSize={true}
                />
            </>
        );
    }

    const cardDataError = [
        cardElementsState.cardCvcEmptyError,
        cardElementsState.cardExpiryEmptyError,
        cardElementsState.cardNumberEmptyError,
    ].some((item) => item);

    const cardDataInputsEmpty = [
        cardElementsState.cardCvcEmpty,
        cardElementsState.cardExpiryEmpty,
        cardElementsState.cardNumberEmpty,
    ].some((item) => item);

    const userDataInputs = [formState.localFirstName, formState.localLastName, formState.localPostalCode].some(
        (item) => !item
    );

    const disabledPaymenButton = (cardDataError || cardDataInputsEmpty || userDataInputs) && showCreditCardForm;

    let paymentMethodComponent = null;

    if (loadingPaymentMethod) {
        paymentMethodComponent = <Loader />;
    } else {
        let containerStyles = "margin-top padding-bottom  container-padding";

        if (!submitButtonText) {
            containerStyles += " show-computer";
        }

        paymentMethodComponent = (
            <>
                {cardInputForm}
                <div className={containerStyles}>
                    <DefaultButton
                        title={submitButtonText ? submitButtonText : "Buy Now"}
                        disabled={disabledPaymenButton || loadingPayment}
                        buttonAction={() =>
                            submitButtonText ? handlePaymentMethodSubmit() : saveAndPay(showCreditCardForm)
                        }
                    />
                </div>
            </>
        );
    }

    return (
        <>
            {createSubscriptionModal}
            {paymentResponseModal}
            <div className="input-container container-padding-half">{lastPaymentRadioButton}</div>
            {paymentMethodComponent}
        </>
    );
}
