import React from "react";
import { Image, Modal, Header } from "semantic-ui-react";
import { DefaultButton } from "../../components";
import SuccessImage from "../../images/gifs/success.gif";
import "../../scss/components/_modal.scss";

export function SuccessModal(props) {
    const { message, open, successAction, closeAction, title } = props;

    return (
        <Modal className="confirm-modal" closeIcon open={open} size="small" onClose={closeAction}>
            <Modal.Content>
                {!title && <Image src={SuccessImage} size="small" centered />}
                <Header as="h1" textAlign="center">
                    {title ? title : "Awesome!"}
                </Header>
                <p className="message">{message}</p>
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton title="Ok" buttonAction={successAction} />
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
