import React, { useState } from "react";
import moment from "moment";
import { Modal, Header } from "semantic-ui-react";
import "../../scss/components/_modal.scss";
import { DefaultButton, CustomRadioButton, SuccessModal, ErrorModal, ConfirmationModal } from "..";
import { cancelSubscription } from "../../services/SetupService";
import { updateSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import * as CONSTANTS from "../../constants/Global";
import * as CONFIRMATIONS from "../../constants/Confirmations";
import * as ERRORS from "../../constants/Errors";

export function PauseSubscriptionModal(props) {
    const { open, closeAction, subscriptionId, saveUserSubscriptions, selectedSubscription, subscriptions } = props;

    const [selectedPause, setSelectedPause] = useState("");
    const [loadingPause, setLoadingPause] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const currentPeriodEnd = moment(selectedSubscription.currentPeriodEnd * 1000).startOf("M");

    const onChangePause = (event) => {
        const value = event.target.id;
        setSelectedPause(Number(value));
    };

    const processCancelation = async () => {
        setLoadingPause(true);
        const cancelDataObj = {
            input: {
                subscriptionId: subscriptionId,
                treatment: CONSTANTS.CANCEL_SUBSCRIPTIONS_OPTIONS.PAUSE,
                pauseTimeDays: String(selectedPause),
                reason: "ONLY_PAUSE",
                coments: "Paused by client.",
            },
        };

        const pauseSubscriptionResponse = await cancelSubscription(cancelDataObj);

        if (pauseSubscriptionResponse) {
            updateSubscriptionsStore(saveUserSubscriptions, subscriptions, pauseSubscriptionResponse);
            setOpenSuccessModal(true);
            setLoadingPause(false);
        } else {
            setLoadingPause(false);
            setOpenErrorModal(true);
        }
    };

    const handleSuccessModal = () => {
        closeAction();
        setOpenSuccessModal(false);
    };

    const handleConfirmModal = () => {
        setOpenConfirmModal(true);
    };

    const successModal = openSuccessModal && (
        <SuccessModal
            open={openSuccessModal}
            message="We'll really appreciate your confidence, we will wait for you, when your selected pause time elapses, you will enjoy your GrandBox again."
            successAction={handleSuccessModal}
            closeAction={handleSuccessModal}
            title="Success!"
        />
    );

    const errorModal = openErrorModal && (
        <ErrorModal
            open={openErrorModal}
            message={ERRORS.ERROR_PAUSING_SUBSCRIPTION}
            closeFunction={() => setOpenErrorModal(false)}
        />
    );

    const nextRenewalDate = moment(currentPeriodEnd)
        .utc()
        .add(selectedPause / 30, "M")
        .startOf("M")
        .format("MMMM/DD/YYYY");

    const confirmationModal = openConfirmModal && (
        <ConfirmationModal
            open={openConfirmModal}
            message={CONFIRMATIONS.PAUSE_SUBSCRIPTION(selectedPause, nextRenewalDate)}
            closeAction={() => setOpenConfirmModal(false)}
            confirmAction={() => {
                processCancelation();
                setOpenConfirmModal(false);
            }}
            sadIcon={true}
        />
    );

    return (
        <Modal className="pause-subscription-modal" closeIcon open={open} size="small" onClose={closeAction}>
            {confirmationModal}
            {successModal}
            {errorModal}
            <Modal.Content>
                <Header as="h3" textAlign="center" content="Pause your subscription" />
                <div className="recovering-card">
                    <div className="text">
                        <p className="detault-paragraph">
                            Sometimes, you just need a break. Keep your benefits and pause payments for the next:
                        </p>
                        <div className="radio-actions">
                            {CONSTANTS.PAUSE_SUBSCRIPTION_TIME.map((option, index) => {
                                return (
                                    <CustomRadioButton
                                        key={index}
                                        id={option.value}
                                        label={option.text}
                                        value={selectedPause}
                                        onChangeAction={onChangePause}
                                        isSmallSize={true}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton
                            title="Pause subscription"
                            buttonAction={handleConfirmModal}
                            loading={loadingPause}
                            disabled={loadingPause || selectedPause === ""}
                        />{" "}
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
}
