import React from "react";
import moment from "moment";
import { Grid, Image, Header, Popup } from "semantic-ui-react";
import { SubHeader, SettingCard, SettingLinksCard } from "../..";
import { stringCapitalization } from "../../../services/DataValidationService";
import { getDeliverDate } from "../../../services/ManageSubscriptionService";
import * as ROUTES from "../../../navigation/Routes";
import * as CONSTANTS from "../../../constants/Global";

function BoxDescriptionComponent(props) {
    const { history, boxData } = props;
    const {
        name,
        boxName,
        parents,
        shippingAddress,
        shippingSubscriptionAddress,
        features,
        boxSettings,
        boxType,
        boxImage,
        boxNumber,
        deliverDate,
        status,
        upgrades,
        welcomeBox,
        boxMonth,
        upgradeImages,
        upgradeNames,
        withUpgrades,
        createdAt,
    } = boxData;

    const { note, photos } = features;
    const isSubscription = boxType === CONSTANTS.BOX_TYPES.SUBSCRIPTION;

    const deliverDateText = getDeliverDate(deliverDate, upgrades);

    delete boxSettings["Thank you for being since"];
    boxSettings["Deliver Date"] = deliverDateText;

    let address = shippingSubscriptionAddress;
    if (shippingAddress && Object.keys(shippingAddress).length > 0) {
        address = shippingAddress;
    }

    const goToShippingAddress = () => {
        if (status === CONSTANTS.BOX_STATUS.UNSHIPPED) {
            history.push(ROUTES.SHIPPING_ADDRESS, {
                address: address,
                ...boxData,
            });
        }
    };

    const goToBoxDetail = (parent) => {
        if (status === CONSTANTS.BOX_STATUS.UNSHIPPED) {
            history.push(ROUTES.BOX_DETAILS, {
                parent: parent,
                subscriptionId: boxData.subscriptionId,
                boxName: boxData.name,
            });
        }
    };

    const getParentData = (parent) => {
        const dietaryNames = parent.dietaryRestriction.map((parentDietary) => {
            const filteredDietary = CONSTANTS.DIETARY_OPTIONS.find((dietary) => dietary.value === parentDietary);

            return filteredDietary.text;
        });

        const parentData = {
            Relationship: stringCapitalization(parent.parentType.toLowerCase()),
            Nickname: parent.name,
            Birthday: parent.birthday,
            Dietary: dietaryNames.join(", "),
            anniversary: parent.anniversary,
        };

        return parentData;
    };

    let boxUpgradeSection = null;

    if (upgradeImages && upgradeImages.length > 0) {
        boxUpgradeSection = (
            <>
                <Header as="h3" content="Upgrades" />
                <div className="upgrade-images">
                    {upgradeImages.map((upgrade, index) => {
                        return (
                            <Popup
                                key={index}
                                trigger={<Image centered src={upgrade.image} size="tiny" />}
                                content={upgrade.name}
                                position="bottom center"
                            />
                        );
                    })}
                </div>
            </>
        );
    }

    const boxMonthImage = (
        <div className="box-image-section">
            <Image centered src={boxImage} />
            {boxUpgradeSection}
        </div>
    );

    const shippingAddressData = {
        Recipient: `${address.firstName} ${address.lastName}`,
        Address: `${address.address}, ${address.city}, ${address.state}  `,
    };

    let blockAddFeatures = null;
    let featuresData = {
        ...boxData,
    };

    if (note.status.toUpperCase() === CONSTANTS.FEATURES_STATUS.EMPTY) {
        featuresData[`Note_${ROUTES.ADD_FEATURE_NOTE}`] = "Pending to add";
    } else if (note.status.toUpperCase() === CONSTANTS.FEATURES_STATUS.FILLED) {
        featuresData[`Note_${ROUTES.ADD_FEATURE_NOTE}`] = "Note uploaded";
    } else {
        featuresData[`Note_${ROUTES.ADD_FEATURE_NOTE}`] = "Skipped this month";
    }

    const changePhotosQuantity = moment(createdAt).isAfter(CONSTANTS.FOUR_PHOTOS_DATE);
    let photosUpdated = false;

    if (changePhotosQuantity && photos.files.length === 4) {
        photosUpdated = true;
    }

    if (photos.status.toUpperCase() === CONSTANTS.FEATURES_STATUS.EMPTY) {
        if (photosUpdated) {
            featuresData[`Photos_${ROUTES.ADD_FEATURE_PHOTOS}`] = "Photos uploaded";
        } else {
            featuresData[`Photos_${ROUTES.ADD_FEATURE_PHOTOS}`] = "Pending to add";
        }
    } else if (photos.status.toUpperCase() === CONSTANTS.FEATURES_STATUS.FILLED) {
        featuresData[`Photos_${ROUTES.ADD_FEATURE_PHOTOS}`] = "Photos uploaded";
    } else {
        featuresData[`Photos_${ROUTES.ADD_FEATURE_PHOTOS}`] = "Skipped this month";
    }

    if (boxNumber === 1 && welcomeBox) {
        const deliverDateTwoDaysLess = moment(deliverDate).utc().subtract(3, "days");
        const canAddFeatures = moment().isBefore(deliverDateTwoDaysLess);

        if (!canAddFeatures) {
            blockAddFeatures = true;
        }
    } else {
        if (withUpgrades) {
            const deliverDateTwoDaysLess = moment(upgrades[0].deliverDate).utc().subtract(4, "days");
            const canAddFeatures = moment().isBefore(deliverDateTwoDaysLess);

            if (!canAddFeatures) {
                blockAddFeatures = true;
            }
        } else {
            const canAddFeatures = Number(moment().format("D")) < CONSTANTS.MAX_FEATURES_UPDATE_DAY;
            const monthBoxIsGreaterThanThisMonth = Number(boxMonth) > Number(moment().format("MM"));
            const monthBoxIsEqualThisMonth = Number(boxMonth) === Number(moment().format("MM"));

            if(monthBoxIsEqualThisMonth){ 
                if(canAddFeatures){
                    blockAddFeatures = false;
                } else {
                    blockAddFeatures = true;
                }
            } else {
                if(monthBoxIsGreaterThanThisMonth) {
                    blockAddFeatures = false;
                } else {
                   const isSameYear = moment().isSame(deliverDate, 'year');

                   if(isSameYear){
                        blockAddFeatures = true;
                   } else {
                       blockAddFeatures = false;
                   }
                }
            }
        }
    }

    const detailsInfo = (
        <div className="padding-bottom">
            {parents.map((parent, index) => {
                const parentData = getParentData(parent);

                return (
                    <SettingCard
                        key={index}
                        title="Details"
                        hasIcon={true}
                        items={parentData}
                        routerAction={goToBoxDetail}
                        type="overlay"
                    />
                );
            })}
        </div>
    );

    const settingsInfo = (
        <div className="padding-bottom">
            {isSubscription && (
                <SettingLinksCard
                    status={status}
                    title="Customize"
                    items={featuresData}
                    history={history}
                    blockAddFeatures={blockAddFeatures}
                />
            )}
            <SettingCard
                title="Shipping Details"
                hasIcon={true}
                items={shippingAddressData}
                routerAction={goToShippingAddress}
                type="overlay"
            />
            <SettingCard title="Box settings" items={boxSettings} />
        </div>
    );

    let subHeaderMainTitle = "";
    let specialText = "";

    if (isSubscription) {
        if (boxName.includes("Gift")) {
            subHeaderMainTitle = "Manage One Time Gift";
            specialText = upgradeNames;
        } else {
            specialText = name;
            subHeaderMainTitle = "Manage Subscription";
        }
    } else {
        subHeaderMainTitle = `${boxName} - ${name}`;
        specialText = "Manage Subscription";
    }

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle={specialText}
                specialText={subHeaderMainTitle}
                // isBoxTitle={true}
                showBackButton={true}
            />
            <div className="manage-subscription-container">
                <Grid className="subscription-grid">
                    <Grid.Row columns={1} only="mobile tablet">
                        <Grid.Column>
                            <>
                                {boxMonthImage}
                                {settingsInfo}
                                {detailsInfo}
                            </>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2} only="computer">
                        <Grid.Column>
                            {boxMonthImage}
                            {detailsInfo}
                        </Grid.Column>
                        <Grid.Column>{settingsInfo}</Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </section>
    );
}

export default BoxDescriptionComponent;
