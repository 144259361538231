import React from "react";
import { Card, Grid } from "semantic-ui-react";
import { SubHeader, DefaultButton, BackButton, OrderCard } from "../..";
import { UpgradeCard } from "../..";

function UpgradeMarketComponent(props) {
    const {
        selectedUpgrades,
        upgradeOptions,
        grandmaData,
        grandpaData,
        relationship,
        onSelectUpgrade,
        goToStepFour,
        saveStepFour,
        plans,
        ordersSummaryData,
        setUpgradeAutoRenewing,
        sendUpgradeRequest,
        loadingUpgradesBuy,
    } = props;

    let upgradeDisabled = true;

    if (selectedUpgrades.length > 0) {
        upgradeDisabled = false;
    }

    const sorteredUpgrades = upgradeOptions?.sort((a, b) => {
        if (Number(b.availableDate.ocationDate) > Number(a.availableDate.ocationDate)) {
            return -1;
        }

        if (Number(b.availableDate.ocationDate) < Number(a.availableDate.ocationDate)) {
            return 1;
        }

        return 0;
    });

    const sumValuesBy = "unit_amount";
    const totalAmount = selectedUpgrades.reduce((a, b) => a + (b[sumValuesBy] || 0), 0) / 100;

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle="Upgrade Market"
                specialText="Please choose from the following upgrades that are available in your current subscription plan"
            />
            <div className="cards-container upgrade-boxes">
                <Card.Group textAlign="center" centered itemsPerRow={4}>
                    {sorteredUpgrades.map((boxUpgrade, index) => {
                        const selectedBox = selectedUpgrades.find((item) => item.id === boxUpgrade.priceId);
                        return (
                            <UpgradeCard
                                className="upgrade-card"
                                key={index}
                                plans={plans}
                                boxUpgrade={boxUpgrade}
                                onSelection={onSelectUpgrade}
                                selectedBox={selectedBox}
                                grandmaName={grandmaData?.name}
                                grandpaName={grandpaData?.name}
                                relationship={relationship}
                                goToStepFour={goToStepFour}
                                saveStepFour={saveStepFour}
                            />
                        );
                    })}
                </Card.Group>
                {selectedUpgrades.length > 0 && (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column mobile={16} computer={8} tablet={8}>
                                <div className="order-summary-container margin-top">
                                    <h1
                                        className="text-center text-h1"
                                        style={{ paddingBottom: "25px", marginBottom: "25px", paddingTop: "25px" }}
                                    >
                                        Order Summary
                                    </h1>
                                    <Card.Group textAlign="center" centered>
                                        {ordersSummaryData.map((plan, index) => {
                                            return (
                                                <OrderCard
                                                    key={index}
                                                    plan={plan}
                                                    setAutoRenewing={setUpgradeAutoRenewing}
                                                />
                                            );
                                        })}
                                    </Card.Group>

                                    <div className="summary-item">
                                        <p className="bold">Total (USD)</p>
                                        <span className="bold total">{`$${totalAmount.toFixed(2)}`}</span>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton
                    title="YES, UPGRADE PLEASE!"
                    buttonAction={sendUpgradeRequest}
                    disabled={upgradeDisabled || loadingUpgradesBuy}
                    loading={loadingUpgradesBuy}
                />
                <br />
                <BackButton title="Back" tabIndex={1} />
            </div>
        </section>
    );
}

export default UpgradeMarketComponent;
