import React from "react";
import { SubHeader, DefaultButton, CustomPasswordInput, ErrorModal, BackButton } from "..";

function ResetPasswordComponent(props) {
    const {
        onChangeWriteOptions,
        state,
        doResetPasswoird,
        goToLogin,
        manageResetPassword,
        userData,
        closeErrorModal,
    } = props;

    let errorMessage = null;

    if (state.errorMessage.length > 0) {
        errorMessage = (
            <ErrorModal
                open={state.errorMessage.length > 0}
                message={state.errorMessage}
                closeFunction={userData ? closeErrorModal : goToLogin}
            />
        );
    }

    const buttonDisabled =
        state.newPasswordError !== false || state.confirmPasswordError !== false || state.newPassword === "";

    return (
        <section className="padding-page">
            <SubHeader mainTitle="Reset your password" specialText="Welcome to Grandbox reset password system" />
            <div className="input-container container-padding">
                <p className="form-header">Please fill the requered fields:</p>

                <CustomPasswordInput
                    id="newPassword"
                    placeholder="New password"
                    label="New password"
                    onChangeAction={onChangeWriteOptions}
                    value={state.newPassword}
                    errorMessage={state.newPasswordError}
                />
                <CustomPasswordInput
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    onChangeAction={onChangeWriteOptions}
                    value={state.confirmPassword}
                    errorMessage={state.confirmPasswordError}
                />
            </div>
            <div className="padding-bottom flex-center flex-column container-padding">
                <DefaultButton
                    title="Reset password"
                    buttonAction={userData ? manageResetPassword : doResetPasswoird}
                    loading={state.loadingResetPassword}
                    disabled={buttonDisabled}
                />
                <br />
                {userData && <BackButton title="Back" tabIndex={0} />}
            </div>
            {errorMessage}
        </section>
    );
}

export default ResetPasswordComponent;
