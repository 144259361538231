import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import moment from "moment";
import StepTwoComponent from "../../components/screenComponents/subscribe/StepTwoComponent";
import { saveStepTwo } from "../../store/subscriptions/actions";
import * as CONSTANTS from "../../constants/Global";
import * as ROUTES from "../../navigation/Routes";
import "../../scss/pages/get_a_box.scss";

function StepTwo(props) {
    const { subscriptions, history, saveStepTwo } = props;
    const { grandpaData, grandmaData, relationship } = subscriptions;

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        grandmaBDay: "",
        grandpaBDay: "",
        anniversary: "",
        showAnniversarySection: false,
    });

    useEffect(() => {
        const currentDay = moment().subtract(40, "years").format("MM/DD/YYYY");

        setStateForm({
            grandmaBDay: grandmaData.hasOwnProperty("birthday") ? grandmaData.birthday : currentDay,
            grandpaBDay: grandpaData.hasOwnProperty("birthday") ? grandpaData.birthday : currentDay,
            anniversary: grandpaData.hasOwnProperty("anniversary") ? grandpaData.anniversary : currentDay,
        });
    }, [grandmaData, grandpaData]);

    const onChangeDateAction = (info) => {
        setStateForm({
            [info.id]: info.date,
        });
    };

    const skipThisStep = () => {
        const { showAnniversarySection, grandmaBDay, grandpaBDay } = formState;

        if (showAnniversarySection) {
            saveStepTwo({
                grandpaBirthday: grandpaBDay,
                grandmaBirthday: grandmaBDay,
                anniversaryDay: "",
            });
            history.push(ROUTES.STEP_THREE);
        } else {
            if (relationship === CONSTANTS.GRANDPARENTS) {
                setStateForm({
                    showAnniversarySection: true,
                });
                saveStepTwo({
                    grandpaBirthday: "",
                    grandmaBirthday: "",
                });
            } else {
                saveStepTwo({
                    grandpaBirthday: "",
                    grandmaBirthday: "",
                    anniversaryDay: "",
                });
                history.push(ROUTES.STEP_THREE);
            }
        }
    };

    const saveStepTwoAndNext = (event) => {
        const { showAnniversarySection } = formState;

        if (showAnniversarySection) {
            const checkAnniversary = checkAnniversaryInput();
            if (checkAnniversary) {
                history.push(ROUTES.STEP_THREE);
            }
        } else {
            if (relationship === CONSTANTS.GRANDMA) {
                const checkGrandmaBDay = checkGrandmaInput();
                if (checkGrandmaBDay) {
                    history.push(ROUTES.STEP_THREE);
                }
            }

            if (relationship === CONSTANTS.GRANDPA) {
                const checkGrandpaBDay = checkGrandpaInput();

                if (checkGrandpaBDay) {
                    history.push(ROUTES.STEP_THREE);
                }
            }

            if (relationship === CONSTANTS.GRANDPARENTS) {
                const checkGrandmaBDay = checkGrandmaInput();
                const checkGrandpaBDay = checkGrandpaInput();

                if (checkGrandmaBDay && checkGrandpaBDay) {
                    setStateForm({
                        showAnniversarySection: true,
                    });
                }
            }
        }
    };

    const checkGrandmaInput = () => {
        const { grandmaBDay } = formState;

        Object.assign(grandmaData, {
            birthday: grandmaBDay,
        });

        if (grandmaBDay.length > 0) {
            saveStepTwo({
                grandmaData: grandmaData,
            });
            return true;
        }
    };

    const checkGrandpaInput = () => {
        const { grandpaBDay } = formState;

        Object.assign(grandpaData, {
            birthday: grandpaBDay,
        });

        if (grandpaBDay.length > 0) {
            saveStepTwo({
                grandpaData: grandpaData,
            });

            return true;
        }
    };

    const checkAnniversaryInput = () => {
        const { anniversary } = formState;

        Object.assign(grandpaData, {
            anniversary: anniversary,
        });

        Object.assign(grandmaData, {
            anniversary: anniversary,
        });

        if (anniversary.length > 0) {
            saveStepTwo({
                grandmaData: grandmaData,
                grandpaData: grandpaData,
            });
            return true;
        }
    };

    return (
        <Container>
            <StepTwoComponent
                state={formState}
                history={history}
                subscriptions={subscriptions}
                skipThisStep={skipThisStep}
                saveStepTwoAndNext={saveStepTwoAndNext}
                onChangeDateAction={onChangeDateAction}
            />
        </Container>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepTwo: (stepOne) => dispatch(saveStepTwo(stepOne)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepTwo));
