import React, { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import FreshChat from "react-freshchat";
import { ResponsiveImage } from "../components";
import GrandBoxLogo from "../images/logo_grandbox_care_package.svg";
import GrandBoxLogoSmall from "../images/grandbox-favicon.svg";
import { firebaseSignOut } from "../services/AuthService";
import { getLocalStorageItem } from "../services/LocalStorageService";
import * as ROUTES from "../navigation/Routes";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import "../scss/components/_sticky_top.scss";
import CustomMenu from "./menu/Menu";

export function Header(props) {
    const { history, openChat, handleTabIndexFromMenu } = props;
    const user = useSelector((state) => state.user.userData);
    const [showChat, setShowChat] = useState(false);
    const [chatText, setChatText] = useState(false);
    const [chatInitialized, setChatInitialized] = useState(false);
    const pathname = window.location.pathname;
    var stepNumber = Number(pathname.split("-")[1]);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const pathname = window.location.pathname;
        var stepNumber = Number(pathname.split("-")[1]);
        const isSubscriptionFlow = pathname.includes(ROUTES.SUBSCRIBE);
        const subscriptions = getLocalStorageItem(LOCALSTORAGE.SUBSCRIPTION_DATA);


        const goToStartRoute = () => {
            const lastStepCompleted = subscriptions.step;
            const requiredStep = lastStepCompleted + 1;

            if (isSubscriptionFlow) {
                if (subscriptions && subscriptions.step) {
                    if (pathname !== ROUTES.SUCCESSFUL) {
                        if (subscriptions.giftType) {
                            history.push(`${ROUTES.SUBSCRIBE}step-${stepNumber}`, {
                                isJoiningUser: true,
                            });
                        } else {
                            if (stepNumber >= requiredStep) {
                                if (requiredStep === 6) {
                                    history.push(`${ROUTES.SUBSCRIBE}step-7`);
                                } else {
                                    history.push(`${ROUTES.SUBSCRIBE}step-${requiredStep}`);
                                }
                            } else {
                                history.push(`${ROUTES.SUBSCRIBE}step-${stepNumber}`);
                            }
                        }
                    }
                } else {
                    if (pathname !== ROUTES.SUCCESSFUL) {
                        if (pathname !== ROUTES.STEP_ONE) {
                            history.replace(ROUTES.STEP_ONE);
                        }
                    }
                }
            }
        };

        if (subscriptions) {
            goToStartRoute();
        }
    }, [history]);

    useEffect(() => {
        const openChatAction = () => {
            setShowChat(true);

            if (chatInitialized) {
                window.fcWidget.open();
            } else {
                setChatText(true);

                setTimeout(() => {
                    setChatText(false);
                }, 3000);
            }
        };

        if (openChat) {
            openChatAction();
        }
    }, [openChat, chatInitialized]);

    const openChatAction = () => {
        setShowChat(true);

        if (chatInitialized) {
            window.fcWidget.open();
        } else {
            setChatText(true);

            setTimeout(() => {
                setChatText(false);
            }, 3000);
        }
    };

    let chat = null;

    if (showChat) {
        chat = (
            <FreshChat
                token={process.env.REACT_APP_FRESHCHAT_TOKEN}
                onInit={(widget) => {
                    setChatInitialized(true);
                    widget.open();

                    if (user) {
                        const userName = user.name.split(" ");

                        const properties = {
                            email: user.email,
                            first_name: userName[0],
                            last_name: userName[1],
                        };

                        if (openChat) {
                            properties.requirement = "Cancel Cratejoy Subscription";
                            properties.subscriptionId = openChat;
                        }
                        widget.user.setProperties(properties);
                    }
                }}
            />
        );
    }

    const signOut = async () => {
        await firebaseSignOut();
    };

    const openMenu = () => {
        setOpen(true);
    };

    const closeMenu = () => {
        setOpen(false);
    };

    const menu = (
        <CustomMenu
            open={open}
            closeMenu={closeMenu}
            signOut={signOut}
            handleTabIndexFromMenu={handleTabIndexFromMenu}
        />
    );

    if(window.location.pathname === "/account/sign-up")  stepNumber = 6; 
    const stepInformation = <p className="step-information">Step {stepNumber} of 7</p>;

    let logoutButton = null;

    if (![ROUTES.EMAIL_VALIDATION, ROUTES.RESET_PASSWORD].includes(pathname)) {
        if (user) {
            logoutButton = (
                <button className={open ? "active" : "logout-button"} onClick={openMenu}>
                    Manage
                </button>
            );
        }
    }

    return (
        <header className="sticky-top">
            <Container fluid>
                <div className="align-center navbar bg-white">
                    <div className="logo">
                        <a href={ROUTES.GRANDBOX_URLS.HOME}>
                            <ResponsiveImage
                                width="154px"
                                altTag="Grandbox subscription box logo"
                                smallImg={GrandBoxLogoSmall}
                                largeImg={GrandBoxLogo}
                            />
                        </a>
                    </div>
                    <div className="route-container">
                        {!isNaN(stepNumber) && stepInformation}
                        <div className="text-center">
                            <button className="help-chat support " onClick={openChatAction}>
                                {chatText ? "Connecting..." : "Need help"}
                            </button>
                        </div>
                        <div className="log-out">
                            {logoutButton}
                            {open && menu}
                        </div>
                    </div>
                </div>
                {chat}
            </Container>
        </header>
    );
}
