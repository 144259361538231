import React from "react";
import { Button, Icon } from "semantic-ui-react";
import "../../scss/components/_button.scss";

export function SocialButton(props) {
    const { socialNetwork, socialNetworkIcon, buttonAction, title, loading, disabled } = props;
    let className = "social-button";

    if (socialNetwork === "google") {
        className += " google";
    }

    return (
        <Button className={className} onClick={buttonAction} loading={loading} disabled={disabled} fluid>
            <Icon name={socialNetworkIcon} /> {title}
        </Button>
    );
}
