import React from "react";
import { Image, Modal, Header } from "semantic-ui-react";
import { DefaultButton } from "../../components";
import ConfirmImage from "../../images/gifs/confirm.gif";
import SadImage from "../../images/gifs/sad-oldman.gif";
import "../../scss/components/_modal.scss";

export function ConfirmationModal(props) {
    const { message, open, closeAction, confirmAction, sadIcon, donationInfo } = props;

    let confirmGif = null;

    if (sadIcon) {
        confirmGif = SadImage;
    } else {
        confirmGif = ConfirmImage;
    }

    let donationLink = null;

    if (donationInfo) {
        donationLink = (
            <p>
                Do you want to know more about our{" "}
                <a href="https://grandbox.com/donation-program" target="_blank" rel="noopener noreferrer">
                    Donation Program
                </a>
                ?
            </p>
        );
    }

    return (
        <Modal className="confirm-modal" closeIcon open={open} size="small" onClose={closeAction}>
            <Modal.Content>
                <Image src={confirmGif} size="small" centered />
                <Header as="h2" textAlign="center">
                    Confirmation
                </Header>
                <p>{message}</p>
            </Modal.Content>
            <Modal.Actions>
                <div className="action-buttons-container">
                    <div className="action-buttons-content">
                        <DefaultButton title="Cancel" buttonAction={closeAction} inverted={true} />
                    </div>
                    <div className="action-buttons-content">
                        <DefaultButton title="Accept" buttonAction={confirmAction} />
                    </div>
                </div>
                {donationLink}
            </Modal.Actions>
        </Modal>
    );
}
