import { deleteCookie, getCookie } from "./CookieService";
import { removeLocalStorageItem } from "./LocalStorageService";
import FirebaseService from "./FirebaseService";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import * as ERRORS from "../constants/Errors";
import * as ROUTES from "../navigation/Routes";

export const firebaseService = new FirebaseService();

export const getCurrentUser = async () => {
    const user = await getCookie(LOCALSTORAGE.USER_DATA);
    return user;
};

export const getIdToken = async () => {
    const idToken = await firebaseService.getIdToken();
    return idToken;
};

export const signUpEmailAndPassWord = async (userData, action) => {
    const { email, password, fullName } = userData;
    const userInfo = {};

    try {
        const user = await firebaseService.doCreateUserWithEmailAndPassword(email, password, fullName);
        if (user) {
            userInfo.data = user;
            userInfo.message = "";
            
            return userInfo;
        } else {
            userInfo.data = null;
            userInfo.message = "An error has occurred during your login, please try again.";

            return userInfo;
        }
    } catch (error) {
        userInfo.data = null;
        userInfo.message = error.message;

        return userInfo;
    }
};

export const signInWithFacebook = async (action) => {
    const userInfo = {};

    try {
        const user = await firebaseService.doFacebookSignInWithPopup();
        if (user) {
            userInfo.data = user;
            userInfo.message = "";

            action();

            return userInfo;
        } else {
            userInfo.data = null;
            userInfo.message = ERRORS.SIGN_UP_ERROR;

            return userInfo;
        }
    } catch (error) {
        userInfo.data = null;
        userInfo.message = error.message;

        return userInfo;
    }
};

export const signInWithGoogle = async (action) => {
    const userInfo = {};

    try {
        const user = await firebaseService.doGoogleSignIn();
        if (user) {
            userInfo.data = user;
            userInfo.message = "";

            action();

            return userInfo;
        } else {
            userInfo.data = null;
            userInfo.message = ERRORS.SIGN_UP_ERROR;

            return userInfo;
        }
    } catch (error) {
        userInfo.data = null;
        userInfo.message = error.message;

        return userInfo;
    }
};

export const signInEmailAndPassWord = async (userData, action) => {
    const { email, password } = userData;
    const userInfo = {};

    try {
        const user = await firebaseService.doSignInWithEmailAndPassword(email, password);
        if (user) {
            userInfo.data = user;
            userInfo.message = "";

            action();

            return userInfo;
        } else {
            userInfo.data = null;
            userInfo.message = "An error has occurred during your login, please try again.";

            return userInfo;
        }
    } catch (error) {
        userInfo.data = null;
        userInfo.message = error.message;

        return userInfo;
    }
};

export const uploadFiles = async (data) => {
    try {
        const uploadFiles = await firebaseService.uploadFiles(data);

        return uploadFiles;
    } catch (error) {
        console.log(error);
    }
};

export const reloadCurrentUser = async () => {
    try {
        await firebaseService.reloadCurrentUser();
    } catch (error) {
        console.log(error);
    }
};

export const firebaseSignOut = async (login) => {
    const signOut = await firebaseService.doSignOut();
    if (signOut === "successfull") {
        await deleteCookie(LOCALSTORAGE.USER_DATA);
        await removeLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION);
        await removeLocalStorageItem(LOCALSTORAGE.SUBSCRIPTION_DATA);
        await removeLocalStorageItem(LOCALSTORAGE.STORAGED_USER_SUBSCRIPTIONS);
        await removeLocalStorageItem(LOCALSTORAGE.SESSION_ID);
        if (login) {
            window.location = ROUTES.INITIAL;
        } else {
            window.location = ROUTES.GRANDBOX_URLS.HOME;
        }
    }
};

export const resetUserPassword = async (email) => {
    try {
        const signOut = await firebaseService.resetPassword(email);

        return signOut
    } catch (error) {
        console.log(error);
    }
}
