import React from "react";
import { BoxSubHeader, CustomInput, CalendarInput, DropdownCheckbox, DefaultButton } from "../..";
import * as CONSTANTS from "../../../constants/Global";

function BoxDetailsComponent(props) {
    const {
        history,
        state,
        onChangeWriteOptions,
        onChangeDateAction,
        onChangeDietaryAction,
        setDietaryRestrictions,
        loadingDietary,
        boxName,
    } = props;

    let dietaryValues = [];

    if (Array.isArray(state.dietary)) {
        dietaryValues = state.dietary.map((parentDietary) => {
            const filteredDietary = CONSTANTS.DIETARY_OPTIONS.find(
                (dietary) => dietary.text === parentDietary || dietary.value === parentDietary
            );

            return filteredDietary.value;
        });
    } else {
        dietaryValues = state.dietary;
    }

    return (
        <section className="padding-page">
            <BoxSubHeader boxName={boxName} title={`${state.relationship} Details`} history={history} />
            <div className="input-container container-padding">
                <CustomInput
                    id="relationship"
                    placeholder="Relationship"
                    value={state.relationship}
                    type="text"
                    label="Relationship"
                    disabled={true}
                />
                <CustomInput
                    id="nickname"
                    placeholder="What do you call him?"
                    onChangeAction={onChangeWriteOptions}
                    value={state.nickname}
                    errorMessage={state.nicknameError}
                    type="text"
                    label="What do you call him?"
                    disabled={true}
                />
                <CalendarInput
                    id="birthday"
                    placeholder={`When is ${state.nickname}'s birthday`}
                    onChangeDateAction={onChangeDateAction}
                    value={state.birthday}
                    error={state.birthdayError}
                    label={`${state.nickname}'s birthday`}
                    disabled={true}
                />
                <DropdownCheckbox
                    id="dietary"
                    label={`Does ${state.nickname} has a dietary restriction?`}
                    options={CONSTANTS.DIETARY_OPTIONS}
                    placeholder="Select Restriction"
                    onChangeAction={onChangeDietaryAction}
                    value={dietaryValues}
                    destroyMultipleWith="UNRESTRICTED"
                />

                <div className="margin-top padding-bottom flex-center flex-column ">
                    <DefaultButton
                        title="Save"
                        disabled={dietaryValues.length === 0}
                        loading={loadingDietary}
                        buttonAction={setDietaryRestrictions}
                    />
                </div>
            </div>
        </section>
    );
}

export default BoxDetailsComponent;
