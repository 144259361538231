import * as actions from "./action-types";

export const initialState = {
    step: null,
    relationship: "",
    grandmaData: {},
    grandpaData: {},
    subscriptionPlan: [],
    upgradeBoxes: [],
    shippingAddress: {
        firstName: "",
        lastName: "",
        country: "United States",
        city: "",
        state: "",
        address: "",
        apt: "",
        postalCode: "",
        phoneNumber: "",
        geometry: null,
    },
    paymentData: {
        firstName: "",
        lastName: "",
    },
    couponData: null,
    giftType: null,
    oneTimeGift: false,
    upgradeType: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_STORAGE_STEPS:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_STEP_ONE:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_STEP_TWO:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_STEP_THREE:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_STEP_FOUR:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_STEP_FIVE:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_STEP_SIX:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_STEP_SEVEN:
            return {
                ...state,
                ...action.payload,
            };
        case actions.SAVE_COUPON_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case actions.RESET_SUBSCRIPTION_STORE:
            return {
                step: null,
                relationship: "",
                grandmaData: {},
                grandpaData: {},
                subscriptionPlan: [],
                upgradeBoxes: [],
                shippingAddress: {
                    firstName: "",
                    lastName: "",
                    country: "United States",
                    city: "",
                    state: "",
                    address: "",
                    apt: "",
                    postalCode: "",
                    phoneNumber: "",
                    geometry: null,
                },
                paymentData: {
                    firstName: "",
                    lastName: "",
                },
                couponData: null,
                giftType: null,
                oneTimeGift: false,
                upgradeType: null,
            };

        case actions.SAVE_ONE_TIME_GIFT_TYPE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
