import React, { useState } from "react";
import moment from "moment";
import { Card, Grid } from "semantic-ui-react";
import {
    SubHeader,
    LinkButton,
    MonthBoxCard,
    SettingCard,
    ConfirmationModal,
    CustomCheckbox,
    DefaultButton,
    CustomDropdown,
} from "../..";
import { upgradeBoxOptions, getUpgradeName } from "../../../services/UpgradeBoxService";
import { stringCapitalization } from "../../../services/DataValidationService";
import * as CONSTANTS from "../../../constants/Global";
import "../../../scss/components/_utilities.scss";

export default function ManageSubscriptionComponent(props) {
    const {
        goToBoxDetail,
        subscriptionOptions,
        selectedSubscription,
        handleSelectedSubscription,
        goToCancelSubscription,
        handleShowActiveSubscriptions,
        showActiveSubscriptions,
        showChatToCancelSubs,
        goToSubscriptions,
        goToUpgrades,
        handlePauseSubscriptionModal,
    } = props;
    const selectedSubscriptionId = selectedSubscription.subscriptionId;
    const changePhotosQuantity = moment(selectedSubscription.createdAt).isAfter(CONSTANTS.FOUR_PHOTOS_DATE);

    const { memberSince, currentPeriodEnd, currentPlan, billingSubscription, status, subscriptionId, resumeAt } =
        selectedSubscription;
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [changeStatusQuestion, setChangeStatusQuestion] = useState("");

    let renewalDate = moment(currentPeriodEnd * 1000)
        .utc()
        .startOf("M")
        .format("MMMM/DD/YYYY");

    if (status === CONSTANTS.SUBSCRIPTION_STATUS.PAUSED && resumeAt) {
        renewalDate = moment(resumeAt * 1000)
            .utc()
            .startOf("M")
            .format("MMMM/DD/YYYY");
    }

    let billingInfo = "";
    let renewalInfo = "";
    let nextPaymentData = "";

    if (billingSubscription.includes("one_time") || !selectedSubscription.recurring) {
        billingInfo = "One charge";
        renewalInfo = "Without Renewal";
        nextPaymentData = "";
    } else {
        billingInfo = `Every ${billingSubscription}`;
        renewalInfo = renewalDate;
        nextPaymentData = `Next payment ${renewalDate}`;
    }

    let subscriptionName = "";

    if (currentPlan.includes("GrandBox Subscription ")) {
        subscriptionName = `${currentPlan.replace("GrandBox Subscription ", "")}`;
    } else {
        subscriptionName = billingSubscription;
    }
    const settingOne = {
        "Current plan": subscriptionName,
        Billing: billingInfo,
        Renewal_link: renewalInfo,
        "Thank you for being since": moment(memberSince).format("MMMM/DD/YYYY"),
        Status: stringCapitalization(status),
        "Subscription Id": subscriptionId,
        packages: Number(selectedSubscription.price.metadata.packageQty),
        unit_amount: selectedSubscription.price.unit_amount,
    };

    const sorteredBoxesNumber = selectedSubscription.boxes
        .map((box) => {
            return {
                ...box,
                parents: selectedSubscription.parents,
            };
        })
        .sort((boxA, boxB) => {
            return boxA.boxNumber - boxB.boxNumber;
        });

    const upgrades = sorteredBoxesNumber.flatMap((box) => {
        return box.upgrades;
    });

    const upgradeBoxes =
        upgrades.length > 0 &&
        upgrades.map((upgrade) => {
            const month = moment(upgrade.deliverDate).format("M");
            const monthBox = sorteredBoxesNumber.find((box) => Number(box.boxMonth) === Number(month));

            const boxImage = upgradeBoxOptions.find((option) => option.tradename === upgrade.upgradeType).image;
            const boxName = getUpgradeName(upgrade.upgradeType, selectedSubscription.parents);
            return {
                ...upgrade,
                ...monthBox,
                boxImage: boxImage,
                boxType: "upgrade",
                boxName: boxName,
            };
        });

    const handleCancelSubscriptionModal = (action) => {
        const cancelQuestion = `Are you sure you want cancel the ${selectedSubscription.currentPlan}`;

        setChangeStatusQuestion(cancelQuestion);

        setOpenConfirmModal(!openConfirmModal);
    };

    const subscriptionsListDropdown = (
        <div className="select-subscription">
            <div className="choose-text-content">
                <p>Choose your subscription: </p>
            </div>
            <CustomDropdown
                customClass="no-margin"
                options={subscriptionOptions}
                value={selectedSubscriptionId}
                onChangeAction={handleSelectedSubscription}
            />
            <CustomCheckbox
                label="Only active"
                noMargin={true}
                inverted={false}
                onChangeAction={handleShowActiveSubscriptions}
                value={showActiveSubscriptions}
            />
        </div>
    );

    const nextPaymentDate = <p className="next-payment">{nextPaymentData} </p>;
    const isOneTimeGift = selectedSubscription.currentPlan.includes("Gift");

    let boxesSection = (
        <div className="box-sections">
            <p className="ui header subtitle bigger">Subscription Boxes</p>
            {
                <Card.Group textAlign="center" centered>
                    {sorteredBoxesNumber.map((box, index) => {
                        return <MonthBoxCard key={index} box={box} goToBoxDetail={goToBoxDetail} changePhotosQuantity={changePhotosQuantity}/>;
                    })}
                </Card.Group>
            }
        </div>
    );

    let upgradeSection = null;

    if (upgradeBoxes.length > 0) {
        upgradeSection = (
            <div className="box-sections custom-padding-top">
                <p className="ui header subtitle bigger custom-margin-bottom">Upgrades</p>
                {
                    <Card.Group textAlign="center" centered>
                        {upgradeBoxes.map((box, index) => {
                            return <MonthBoxCard key={index} box={box} goToBoxDetail={goToBoxDetail} changePhotosQuantity={changePhotosQuantity}/>;
                        })}
                    </Card.Group>
                }
            </div>
        );
    }

    const subscriptionActive =
        selectedSubscription.status === CONSTANTS.SUBSCRIPTION_STATUS.ACTIVE && !currentPlan.includes("Gift");
    const subscriptionPaused = selectedSubscription.status === CONSTANTS.SUBSCRIPTION_STATUS.PAUSED;
    const showCancelButton = subscriptionActive || subscriptionPaused;

    const subscriptionSettings = (
        <>
            <div className="padding-bottom">
                <SettingCard
                    className="ui header subtitle bigger"
                    title="Subscription Details"
                    items={settingOne}
                    showBenefits={subscriptionActive || subscriptionPaused}
                    changePhotosQuantity={changePhotosQuantity}
                />
            </div>

            <div className="padding-bottom cancel-buttons-container">
                {subscriptionActive && (
                    <>
                        <LinkButton
                            title="Pause Subscription Renewal"
                            buttonAction={handlePauseSubscriptionModal}
                            align="left"
                            textSmall={true}
                        />
                        <span className="separator">or</span>
                    </>
                )}
                {showCancelButton && (
                    <LinkButton
                        title="Cancel Subscription"
                        buttonAction={handleCancelSubscriptionModal}
                        align="left"
                        textSmall={true}
                    />
                )}
            </div>

            <div className="padding-bottom">
                <DefaultButton
                    tenPercent={true}
                    inverted={true}
                    title="ADD NEW SUBSCRIPTION"
                    buttonAction={goToSubscriptions}
                />
            </div>
            {subscriptionActive && (
                <div className="padding-bottom">
                    <DefaultButton
                        tenPercent={true}
                        inverted={true}
                        title="ADD NEW UPGRADE"
                        buttonAction={goToUpgrades}
                    />
                </div>
            )}
        </>
    );

    let confirmationModal = null;
    let cancelSubscriptionAction = null;

    if (selectedSubscriptionId.includes("cra_") || selectedSubscriptionId.includes("test_")) {
        cancelSubscriptionAction = () => {
            showChatToCancelSubs();
            setOpenConfirmModal(false);
        };
    } else {
        cancelSubscriptionAction = () => goToCancelSubscription();
    }

    if (openConfirmModal) {
        confirmationModal = (
            <ConfirmationModal
                open={openConfirmModal}
                closeAction={handleCancelSubscriptionModal}
                confirmAction={cancelSubscriptionAction}
                message={changeStatusQuestion}
                sadIcon={true}
            />
        );
    }

    let sectionTitle = "";

    if (isOneTimeGift) {
        sectionTitle = "Manage One Time Gift";
        boxesSection = null;
        delete settingOne.Billing;
    } else {
        sectionTitle = "Manage Subscription";
    }

    const optionsQuantity = subscriptionOptions.filter((subscription) => subscription.value !== "select").length - 1;

    return (
        <section className="padding-page">
            {confirmationModal}
            <SubHeader mainTitle={sectionTitle} />
            <div className="tab-subscription-container">
                <p className="detault-paragraph eighteen-px-size lesser-padding">
                    {`You have ${optionsQuantity} Plan${optionsQuantity > 1 ? "s" : ""}
                    ${showActiveSubscriptions && optionsQuantity > 0 ? "Active" : ""}`}
                </p>
                <div className="manage-subscription-container lesser">
                    <Grid className="subscription-grid">
                        <Grid className="subscription-grid">
                            <Grid.Row columns={1}>
                                <Grid.Column width={16} only="computer">
                                    {subscriptionsListDropdown}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="manage-subscription-container">
                <Grid className="subscription-grid">
                    <Grid.Column width={16} only="mobile">
                        {subscriptionsListDropdown}
                        {nextPaymentDate}
                    </Grid.Column>

                    <Grid.Row columns={2} only="computer">
                        <Grid.Column>{subscriptionSettings}</Grid.Column>
                        <Grid.Column>
                            {boxesSection}
                            {upgradeSection}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} only="mobile tablet">
                        <Grid.Column>
                            {boxesSection}
                            {upgradeSection}
                            {subscriptionSettings}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </section>
    );
}
