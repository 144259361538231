import React from "react";
import { Header } from "semantic-ui-react";
import { BoxSubHeader, DefaultButton, CustomRadioButton, BackButton, CustomTextArea } from "../..";
import * as CONSTANTS from "../../../constants/Global";

function CancelSurveyComponent(props) {
    const {
        history,
        cancelReason,
        onChangeCancelReason,
        showTextArea,
        userCancelReason,
        onChangeWriteOption,
        goToConfirmCancelation,
    } = props;

    let textArea = null;

    if (showTextArea) {
        textArea = (
            <CustomTextArea
                id="userCancelReason"
                rows={4}
                placeholder="Tell us why you want to cancel"
                maxLength={300}
                onChangeAction={onChangeWriteOption}
                value={userCancelReason}
                errorMessage={""}
            />
        );
    }

    return (
        <section className="padding-page">
            <BoxSubHeader singleTitle="Cancel Subscription" history={history} />
            <div className="manage-subscription-container">
                <p className="detault-paragraph">We're sorry to hear that you're unhappy with your subscription</p>
                <Header as="h3" content="If you have a minute, please tell us why. (optional)" />
                <div className="margin-top margin-bottom">
                    {CONSTANTS.CANCEL_SUBSCRIPTIONS_REASONS.map((item, index) => {
                        return (
                            <CustomRadioButton
                                key={index}
                                id={item.value}
                                label={item.text}
                                isSmallSize={true}
                                value={cancelReason}
                                onClickAction={onChangeCancelReason}
                            />
                        );
                    })}
                    {textArea}
                </div>
            </div>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton
                    title="Cancel Subscription"
                    buttonAction={goToConfirmCancelation}
                    inverted={true}
                    disabled={cancelReason === ""}
                />
                <BackButton title="Back"/>
            </div>
        </section>
    );
}

export default CancelSurveyComponent;
