export const GetMe = `
query GetMe{
    getMe{
        active
        anniversary
        billingSubscription
        sessionId
        boxes{
            active 
            boxGroupId 
            boxId
            boxImage 
            boxMonth 
            boxName 
            boxNumber
            boxType 
            createdAt 
            deliverDate 
            features{
                done
                photos{
                    createdAt 
                    files 
                    modifiedAt 
                    note 
                    status 
                }
                note{
                    createdAt 
                    files 
                    modifiedAt 
                    note 
                    status 
                }
            }
            modifiedAt 
            ownerEmail 
            ownerId 
            shippingAddress{
                address 
                apt 
                city 
                country 
                firstName 
                geometry{
                    location{
                        lat 
                        lng 
                    }
                    viewport{
                        east 
                        north 
                        south 
                        west 
                    }
                }
                lastName 
                phoneNumber 
                postalCode 
                state 
            }
            status 
            subscriptionId 
            trackingId 
            trackingLink
            upgrades{
                deliverDate
                upgradeType
                recurring
            }
            welcomeBox 
            withUpgrades
        }
        boxGroupId 
        brandId 
        createdAt 
        currentPeriodEnd 
        currentPlan 
        memberSince 
        modifiedAt 
        ownerEmail 
        ownerId 
        parents{
            anniversary 
            birthday 
            dietaryRestriction
            name 
            parentType
        }
        paymentInfo{
            coupon 
            paymentMethod 
            prices
        }
        price{
            active 
            billing_scheme 
            brandId 
            currency 
            id
            metadata
            product 
            recurring{
                interval_count
                interval
            }
            type 
            unit_amount 
        }
        recurring 
        relationship 
        renewals 
        shippingAddress{
            address 
            apt 
            city 
            country 
            firstName 
            geometry{
                location{
                    lat 
                    lng 
                }
                viewport{
                    east 
                    north 
                    south 
                    west 
                }
            }
            lastName 
            phoneNumber 
            postalCode 
            state 
        }
        status 
        subscriptionId 
        type 
        upgrades{
            deliverDate 
            upgradeType 
            recurring 
        }
        withUpgrades
        resumeAt
    }
}
`;
