import React, { useState } from "react";
import { Label, Dropdown, Icon } from "semantic-ui-react";
import "../../scss/components/_dropdown.scss";

export function CustomDropdown(props) {
    const { id, label, options, placeholder, onChangeAction, value, error, search, customClass, open } = props;

    let dropdownLabel = null;
    let dropdownClasses = "dropdown-container";
    let iconDisabled = true;

    if (value) {
        if (value.length > 0) {
            dropdownClasses += " filled";
            iconDisabled = false;
            if (label) {
                dropdownLabel = <Label floating>{label}</Label>;
            }
        }
    }

    const [stateIcon, setStateIcon] = useState(<Icon disabled={iconDisabled} name="chevron down" />);
    const [dropDownState, setDropdownState] = useState(open);

    const onChange = (event, { value }) => {
        const data = {
            id: id,
            value: value,
        };
        onChangeAction(data);
    };

    const replaceIcon = (state) => {
        if (state === "open") {
            setStateIcon(<Icon disabled={iconDisabled} name="chevron up" />);
            setDropdownState(true);
        } else {
            setDropdownState(false);
            setStateIcon(<Icon disabled={iconDisabled} name="chevron down" />);
        }
    };

    let newValue = value;
    const subscriptionInList = options.length && options.find((option) => option.value === value);

    if (!subscriptionInList) {
        newValue = "select";
    }

    return (
        <div className={`${dropdownClasses} ${customClass}`}>
            {dropdownLabel}
            <Dropdown
                id={id}
                search={search}
                placeholder={placeholder}
                defaultValue=""
                fluid
                selection
                options={options}
                onChange={onChange}
                value={newValue}
                icon={stateIcon}
                error={error}
                open={dropDownState}
                onOpen={() => replaceIcon("open")}
                onClose={() => replaceIcon("close")}
            />
        </div>
    );
}
