import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Initial from "../screens/Initial";
import SignUp from "../screens/SignUp";
import Login from "../screens/Login";
import ResetPassword from "../screens/ResetPassword";
import ValidateEmail from "../screens/ValidateEmail";
import { SubscribeStack } from "./stacks/SubscribeStack";
import { AccountStack } from "./stacks/AccountStack";
import { CancelSubscriptionStack } from "./stacks/CancelSubscriptionStack";
import { getCookie } from "../services/CookieService";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import * as ROUTES from "./Routes";
const jwtDecode = require("jwt-decode");

function Navigator(props) {
    const { isLoggedIn } = props;
    let loggedIn = false;

    if (isLoggedIn === "notLoggedIn") {
        const user = getCookie(LOCALSTORAGE.USER_DATA);
        if (user) {
            const decoded = jwtDecode(user);
            if (decoded.firebase.sign_in_provider === "password") {
                if (decoded.email_verified) {
                    loggedIn = true;
                } else {
                    loggedIn = false;
                }
            } else {
                loggedIn = true;
            }
        } else {
            loggedIn = false;
        }
    } else {
        loggedIn = true;
    }

    return (
        <Switch>
            <Route path={ROUTES.INITIAL} exact render={() => <Initial />} />
            <Route path={ROUTES.LOGIN} exact render={() => <Login />} />
            <Route path={ROUTES.SIGN_UP} exact render={() => <SignUp />} />
            <Route path={ROUTES.RESET_PASSWORD} exact render={() => <ResetPassword />} />
            <Route path={ROUTES.EMAIL_VALIDATION} exact render={() => <ValidateEmail />} />
            {SubscribeStack.map((route, index) => (
                <Route key={index} path={route.path} exact render={() => <route.component />} />
            ))}
            {AccountStack.map((route, index) => {
                return (
                    <Route key={index} exact path={route.path}>
                        {loggedIn ? (
                            <route.component />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: ROUTES.LOGIN,
                                    state: {
                                        accountRoutes: "manage-subscription",
                                        backRoute: process.env.REACT_APP_GRANDBOX_URL,
                                    },
                                }}
                            />
                        )}
                    </Route>
                );
            })}
            {CancelSubscriptionStack.map((route, index) => {
                return (
                    <Route key={index} exact path={route.path}>
                        {loggedIn ? (
                            <route.component />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: ROUTES.LOGIN,
                                    state: {
                                        accountRoutes: "manage-subscription",
                                        backRoute: process.env.REACT_APP_GRANDBOX_URL,
                                    },
                                }}
                            />
                        )}
                    </Route>
                );
            })}
        </Switch>
    );
}

export default Navigator;
