import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { Loader } from "../../components";
import StepFiveComponent from "../../components/screenComponents/subscribe/StepFiveComponent";
import { getSpecialOccasionBoxes, upgradeTypeCompose } from "../../services/UpgradeBoxService";
import { saveStepFive, saveStepFour } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/pages/get_a_box.scss";

function StepFive(props) {
    const { history, subscriptions, upgrades, user, saveStepFive, saveStepFour, plans } = props;
    const { relationship, grandpaData, grandmaData, subscriptionPlan, upgradeType, upgradeBoxes } = subscriptions;

    const [selectedUpgrades, setSelectedUpgrades] = useState([]);
    const [upgradeOptions, setUpgradeOptions] = useState([]);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [specialOcationBoxes, setSpecialOcationBoxes] = useState([]);

    const buildUpgradeOptions = useCallback(() => {
        const subscription = subscriptionPlan[0];

        const planPackages = Number(subscription.metadata.packageQty);

        const specialOcationBoxes = getSpecialOccasionBoxes(
            relationship,
            planPackages,
            grandmaData.birthday,
            grandpaData.birthday,
            grandmaData.anniversary
        );

        setSpecialOcationBoxes(specialOcationBoxes);

        const getUpgradePrices = (upgrades) => {
            const customUpgrades = upgrades.map((upgrade) => {
                const type = relationship === CONSTANTS.GRANDPARENTS ? "couple" : "single";
                return {
                    ...upgrade,
                    price: upgrade.prices.find(
                        (price) => price.metadata.priceType === type && Boolean(price.metadata.upgrade)
                    ),
                    priceId: upgrade.prices.find(
                        (price) => price.metadata.priceType === type && Boolean(price.metadata.upgrade)
                    ).id,
                };
            });

            return customUpgrades;
        };

        const customUpgrades = getUpgradePrices(upgrades);

        const upgradeOptions = specialOcationBoxes.map((x) =>
            Object.assign(
                x,
                customUpgrades.find((y) => x.tradename.includes(y.price.metadata.upgradeType))
            )
        );

        setUpgradeOptions(upgradeOptions);
        if (upgradeOptions[0].id && upgradeOptions.length > 0) setShowUpgrade(true);
    }, [relationship, grandmaData.birthday, grandpaData.birthday, grandmaData.anniversary, subscriptionPlan, upgrades]);

    useEffect(() => {
        buildUpgradeOptions();
        if (subscriptions.upgradeBoxes.length > 0) {
            setSelectedUpgrades(subscriptions.upgradeBoxes);
        }
    }, [buildUpgradeOptions, subscriptions.upgradeBoxes, upgrades]);

    useEffect(() => {
        if (upgradeType && upgradeOptions.length > 0) {
            const upgradeComposed = upgradeTypeCompose(upgradeType, grandmaData);
            const preselectedUpgrade = upgradeOptions.find((option) => option.tradename === upgradeComposed);

            if (preselectedUpgrade.hasOwnProperty("price")) {
                const upgradeExists = selectedUpgrades.some((upgrade) => upgrade.id === preselectedUpgrade.price.id);
                if (!upgradeExists) {
                    setSelectedUpgrades((old) => [
                        ...old,
                        {
                            ...preselectedUpgrade.price,
                            autoRenewing: true,
                        },
                    ]);
                }
            }
        }
    }, [upgradeType, upgradeOptions, grandmaData, selectedUpgrades]);

    useEffect(() => {
        if (specialOcationBoxes.length > 0 && upgradeBoxes.length > 0) {
            const listComplete = specialOcationBoxes.some((box) => box.hasOwnProperty("price"));
            if (listComplete) {
                const availableUpgrades = upgradeBoxes
                    .map((upgrade) => {
                        const availableId = specialOcationBoxes.find((box) => box.status === "available").price.id;
                        return upgrade.id === availableId && upgrade;
                    })
                    .filter((upgrade) => upgrade);

                setSelectedUpgrades(availableUpgrades);
            }
        }
    }, [specialOcationBoxes, upgradeBoxes]);

    const onSelectUpgrade = (selection) => {
        const upgradeExists = selectedUpgrades.some((upgrade) => upgrade.id === selection.price.id);

        if (upgradeExists) {
            const cancelledUpgrade = selection.price.id;
            const filteredList = selectedUpgrades.filter((upgrade) => upgrade.id !== cancelledUpgrade);

            setSelectedUpgrades(filteredList);
        } else {
            setSelectedUpgrades((old) => [
                ...old,
                {
                    ...selection.price,
                    autoRenewing: true,
                },
            ]);
        }
    };

    const saveStepFiveAndNext = () => {
        if (selectedUpgrades.length > 0) {
            saveStepFive({
                upgradeBoxes: selectedUpgrades,
            });

            routeNextPage();
        }
    };

    const skipStepFiveAndNext = () => {
        saveStepFive({
            upgradeBoxes: [],
        });

        routeNextPage();
    };

    const routeNextPage = () => {
        if (user.userData) {
            if (user.userData.firebase.sign_in_provider === "password") {
                if (user.userData.email_verified) {
                    history.push(ROUTES.STEP_SIX);
                } else {
                    history.push(ROUTES.SIGN_UP, {
                        accountRoutes: "subscription",
                    });
                }
            } else {
                history.push(ROUTES.STEP_SIX);
            }
        } else {
            history.push(ROUTES.SIGN_UP, {
                accountRoutes: "subscription",
            });
        }
    };

    const goToStepFour = (availableWithPlan) => {
        history.push(ROUTES.STEP_FOUR, {
            availableWithPlan: availableWithPlan,
        });
    };

    let stepFiveComponent = null;

    if (showUpgrade) {
        stepFiveComponent = (
            <StepFiveComponent
                plans={plans}
                selectedUpgrades={selectedUpgrades}
                upgradeOptions={upgradeOptions}
                history={history}
                subscriptions={subscriptions}
                saveStepFiveAndNext={saveStepFiveAndNext}
                skipStepFiveAndNext={skipStepFiveAndNext}
                onSelectUpgrade={onSelectUpgrade}
                goToStepFour={goToStepFour}
                saveStepFour={saveStepFour}
            />
        );
    } else {
        stepFiveComponent = <Loader />;
    }

    return (
        <>
            <Container>{stepFiveComponent}</Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
    saveStepFive: (stepFive) => dispatch(saveStepFive(stepFive)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        upgrades: state.business.upgrades,
        plans: state.business.plans,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepFive));
