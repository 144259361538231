import React from "react";
import { Divider } from "semantic-ui-react";
import {
    SubHeader,
    DefaultButton,
    LinkButton,
    CustomInput,
    CustomPasswordInput,
    SocialButton,
    ErrorModal,
    CustomCheckbox,
    BackButton,
} from "..";

function LoginComponent(props) {
    const {
        onChangeWriteOptions,
        state,
        prepareSignUpEmailAndPassword,
        signUpUserControl,
        goToSignUp,
        handleResetEmailModal,
        isSubscriptionRoutes,
        goToSubscrption,
        removeErrorMessage,
        rememberAction,
    } = props;

    let errorMessage = null;

    if (state.errorMessage.length > 0) {
        errorMessage = (
            <ErrorModal
                open={state.errorMessage.length > 0}
                message={state.errorMessage}
                closeFunction={removeErrorMessage}
            />
        );
    }

    return (
        <section className="padding-page">
            <SubHeader textCenter mainTitle="Log In" specialText="With your social network" />
            <div className="flex-center flex-row container-padding">
                <div style={{ width: "40vh" }}>
                    <SocialButton
                        socialNetworkIcon="google"
                        socialNetwork="google"
                        buttonAction={() => signUpUserControl("google")}
                        loading={state.loadingSignInGmail}
                        disabled={state.disableSignUp}
                    />
                </div>
                <br />
                {/* <SocialButton
                    socialNetworkIcon="facebook f"
                    socialNetwork="facebook"
                    buttonAction={() => signUpUserControl("facebook")}
                    loading={state.loadingSignInFacebook}
                    disabled={state.disableSignUp}
                /> */}
            </div>
            <div className="input-container container-padding">
                <Divider horizontal>Or</Divider>
                <CustomInput
                    id="email"
                    placeholder="Email"
                    label="Email"
                    onChangeAction={onChangeWriteOptions}
                    value={state.email}
                    errorMessage={state.emailError}
                />
                <CustomPasswordInput
                    id="password"
                    placeholder="Password"
                    label="Password"
                    onChangeAction={onChangeWriteOptions}
                    value={state.password}
                    errorMessage={state.passwordError}
                />

                <div className="multi-input-line">
                    <CustomCheckbox label="Remember me" onChangeAction={rememberAction} value={state.rememberMe} />
                    <LinkButton title="Forgot your password?" dark={true} buttonAction={handleResetEmailModal} />
                </div>
            </div>

            <div className="flex-center flex-column container-padding">
                <DefaultButton
                    title="Log In"
                    buttonAction={prepareSignUpEmailAndPassword}
                    loading={state.loadingSignUp}
                    disabled={state.disableSignUp}
                />
                <br />
            </div>
            {errorMessage}
            {isSubscriptionRoutes && (
                <div className="flex-center flex-column button-container-padding">
                    <LinkButton title="Sign Up" buttonAction={goToSignUp} textBefore="You do not have an account?" />
                </div>
            )}
            {!isSubscriptionRoutes && (
                <div className="flex-center flex-column">
                    <LinkButton
                        title="Get a Grandbox!"
                        buttonAction={goToSubscrption}
                        textBefore="You do not have a Grandbox subscription?"
                    />
                </div>
            )}
            <div>
                <BackButton title="Back" />
            </div>
        </section>
    );
}

export default LoginComponent;
