import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { saveOneTimeGift } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";

function Subscribe(props) {
    const { history, saveOneTimeGift } = props;

    useEffect(() => {
        const searchParams = window.location.search.replace("?", "").split("&");
        const giftType = searchParams.find((param) => param.includes("giftType"));

        if (giftType) {
            saveOneTimeGift(giftType.split("=")[1]);
        }

        history.push(ROUTES.STEP_ONE);
    }, [history, saveOneTimeGift]);

    return null;
}

const mapDispatchToProps = (dispatch) => ({
    saveOneTimeGift: (giftType) => dispatch(saveOneTimeGift(giftType)),
});

export default compose(connect(null, mapDispatchToProps))(withRouter(Subscribe));
