import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { EmailReset, EmailConfirmationModal } from "../components";
import LoginComponent from "../components/screenComponents/LoginComponent";
import { validateInputFilled, validateInputEmail } from "../services/DataValidationService";
import { signInEmailAndPassWord, signInWithFacebook, signInWithGoogle, firebaseService } from "../services/AuthService";
import { setCookie } from "../services/CookieService";
import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from "../services/LocalStorageService";
import { getUserStoredData } from "../store/user/actions";
import * as ROUTES from "../navigation/Routes";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import "../scss/pages/get_a_box.scss";
const inputsIds = [
    { name: "email", error: "Email is required" },
    { name: "password", error: "Password is required" },
];

function Login(props) {
    const { history, getUserStoredData, location, user, subscriptions } = props;
    const { giftType } = subscriptions;
    const { userData } = user;
    const isSubscriptionRoutes = location.state.accountRoutes === "subscription";
    const showModal = location.state.showModal;

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        email: "",
        password: "",
        emailError: false,
        passwordError: false,
        errorMessage: "",
        inputError: false,
        loadingSignUp: false,
        loadingSignInFacebook: false,
        loadingSignInGmail: false,
        disableSignUp: false,
        rememberMe: false,
    });
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [openResetEmailModal, setOpenResetEmailModal] = useState(false);

    useEffect(() => {
        const afterLoginRoute = () => {
            if (giftType) {
                history.replace(ROUTES.STEP_SIX);
            } else {
                if (isSubscriptionRoutes) {
                    history.replace(ROUTES.STEP_FOUR);
                } else {
                    history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
                }
            }
        };

        if (showModal) {
            setOpenResetEmailModal(true);
        }

        if (userData && userData.firebase.sign_in_provider === "password") {
            if (userData.email_verified) {
                afterLoginRoute();
            } else {
                setStateForm({
                    openConfirmModal: true,
                    email: userData.email,
                });
            }
        }
    }, [showModal, history, userData, giftType, isSubscriptionRoutes]);

    useEffect(() => {
        const userEmail = getLocalStorageItem(LOCALSTORAGE.REMEMBER_ME);

        if (userEmail) {
            setStateForm({
                email: userEmail,
                rememberMe: true,
            });
        }
    }, []);

    const onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;

        setStateForm({
            [inputId]: value,
            [`${inputId}Error`]: false,
            errorMessage: "",
        });
    };

    const rememberAction = (event, { checked }) => {
        setStateForm({
            rememberMe: checked,
        });

        if (checked) {
            setLocalStorageItem(LOCALSTORAGE.REMEMBER_ME, formState.email);
        } else {
            removeLocalStorageItem(LOCALSTORAGE.REMEMBER_ME);
            setStateForm({
                email: "",
            });
        }
    };

    const prepareSignUpEmailAndPassword = () => {
        const { inputError } = formState;

        const inputsRequiredAreFilled = checkInputsRequiredAreFilled();

        if (inputsRequiredAreFilled && !inputError) {
            signIpUserControl("email");
        }
    };

    const signIpUserControl = (type, userData) => {
        setStateForm({
            disableSignUp: true,
            errorMessage: "",
        });

        switch (type) {
            case "email":
                withEmailAndPasswordAction();
                break;
            case "facebook":
                withFacebookAction();
                break;
            case "google":
                withGoogleAction();
                break;
            default:
                break;
        }
    };

    const withEmailAndPasswordAction = async () => {
        setStateForm({
            loadingSignUp: true,
            disableSignUp: true,
        });

        const userData = {
            email: formState.email,
            password: formState.password,
        };

        try {
            const userSignUp = await signInEmailAndPassWord(userData, getUserStoredData);

            if (userSignUp) {
                setStateForm({
                    loadingSignUp: false,
                    disableSignUp: false,
                });

                if (userSignUp.data.user) {
                    if (userSignUp.data.user.emailVerified) {
                        afterLoginRoute();
                    } else {
                        setOpenConfirmModal(true);
                    }
                } else {
                    handleErrorMessage(userSignUp.message);
                }
            }
        } catch (error) {
            console.log(error);
            handleErrorMessage(error.message);
        }
    };

    const withFacebookAction = async () => {
        setStateForm({
            loadingSignInFacebook: true,
            disableSignUp: true,
        });

        try {
            const userSignUp = await signInWithFacebook(getUserStoredData);

            if (userSignUp) {
                setStateForm({
                    loadingSignInFacebook: false,
                    disableSignUp: false,
                });

                if (userSignUp.data) {
                    afterLoginRoute();
                } else {
                    handleErrorMessage(userSignUp.message);
                }
            }
        } catch (error) {
            setStateForm({
                loadingSignInFacebook: false,
                disableSignUp: false,
            });

            handleErrorMessage(error.message);
        }
    };

    const withGoogleAction = async () => {
        setStateForm({
            loadingSignInGmail: true,
            disableSignUp: true,
        });

        try {
            const userSignUp = await signInWithGoogle(getUserStoredData);

            setStateForm({
                loadingSignInGmail: false,
                disableSignUp: false,
            });

            if (userSignUp.data) {
                afterLoginRoute();
            } else {
                handleErrorMessage(userSignUp.message);
            }
        } catch (error) {
            setStateForm({
                loadingSignInGmail: false,
                disableSignUp: false,
            });

            handleErrorMessage(error.message);
        }
    };

    const checkInputsRequiredAreFilled = () => {
        const inputsFilledList = checkIfInputAreFilledAndCreateList();
        const quantityInputsFilled = inputsFilledList.filter((isFilled) => isFilled === true);

        if (quantityInputsFilled.length === inputsIds.length) {
            return true;
        }
        return false;
    };

    const checkIfInputAreFilledAndCreateList = () => {
        const inputsFilledQuantity = [];

        inputsIds.forEach((inputId) => {
            const inputFilled = validateInputFilled(formState[inputId.name]);

            if (inputId.name === "email" && inputFilled) {
                const inputEmail = validateInputEmail(formState[inputId.name]);
                inputsFilledQuantity.push(inputEmail);
                emailErrorController(inputEmail, inputId);
            } else {
                inputsFilledQuantity.push(inputFilled);
            }

            if (!inputFilled) {
                setStateForm({
                    [`${inputId.name}Error`]: inputId.error,
                });
            }
        });

        return inputsFilledQuantity;
    };

    const emailErrorController = (inputEmail, inputId) => {
        if (!inputEmail) {
            setStateForm({
                [`${inputId.name}Error`]: "Please enter a valid email",
                inputError: true,
            });
        } else {
            setStateForm({
                [`${inputId.name}Error`]: false,
                inputError: false,
            });
        }
    };

    const goToSignUp = () => {
        history.replace(ROUTES.SIGN_UP, {
            accountRoutes: "subscription",
        });
    };

    const goToSubscrption = () => {
        history.replace(ROUTES.STEP_ONE, { from: ROUTES.LOGIN} );
    };

    const handleResetEmailModal = () => {
        setOpenResetEmailModal(!openResetEmailModal);
    };

    const afterLoginRoute = () => {
        if (isSubscriptionRoutes || giftType) {
            history.replace(ROUTES.STEP_SIX);
        } else {
            history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
        }
    };

    const handleErrorMessage = (errorMessage) => {
        setStateForm({
            errorMessage: errorMessage,
        });
    };

    const closeConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    const confirmVerification = async () => {
        const idToken = await firebaseService.getIdToken();

        if (idToken) {
            setCookie(LOCALSTORAGE.USER_DATA, idToken);
            getUserStoredData();
        }
    };

    const removeErrorMessage = () => {
        setStateForm({
            errorMessage: "",
        });
    };

    let confirmationModal = null;

    if (openConfirmModal) {
        confirmationModal = (
            <EmailConfirmationModal
                open={openConfirmModal}
                email={formState.email}
                closeAction={closeConfirmModal}
                confirmAction={confirmVerification}
            />
        );
    }

    let resetEmailModal = null;

    if (openResetEmailModal) {
        resetEmailModal = <EmailReset open={openResetEmailModal} closeFunction={handleResetEmailModal} />;
    }

    return (
        <>
            {resetEmailModal}
            {confirmationModal}

            <Container>
                <LoginComponent
                    state={formState}
                    onChangeWriteOptions={onChangeWriteOptions}
                    rememberAction={rememberAction}
                    prepareSignUpEmailAndPassword={prepareSignUpEmailAndPassword}
                    signUpUserControl={signIpUserControl}
                    goToSignUp={goToSignUp}
                    handleResetEmailModal={handleResetEmailModal}
                    isSubscriptionRoutes={isSubscriptionRoutes}
                    goToSubscrption={goToSubscrption}
                    removeErrorMessage={removeErrorMessage}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getUserStoredData: () => dispatch(getUserStoredData()),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(Login));
