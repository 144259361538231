import React, { useEffect, useRef } from "react";
import { Label } from "semantic-ui-react";
import { waitForGlobal } from "../../services/LoadScriptService";
import "../../scss/components/_input.scss";

let autoComplete = null;

const loadScript = (callback) => {
    const googleIsLoaded = waitForGlobal("google");

    googleIsLoaded.then((isLoaded) => {
        if (isLoaded) {
            callback();
        }
    });
};

const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
        types: ["geocode"],
        componentRestrictions: { country: ["us", "pr", "vi"] },
    });

    autoComplete.setFields(["address_components", "geometry", "icon", "name"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery));
};

const handlePlaceSelect = async (updateQuery) => {
    const addressObject = await autoComplete.getPlace();

    if (addressObject) updateQuery(addressObject);
};

export function SearchLocationInput(props) {
    const { id, label, placeholder, onSelectedPlace, value, errorMessage } = props;

    const autoCompleteRef = useRef(null);

    let streetAddressText = value;
    let inputLabel = null;
    let inputContentClasses = "input-content";
    let inputClassName = "ui input";

    useEffect(() => {
        loadScript(() => handleScriptLoad(onSelectedPlace, autoCompleteRef));
    }, [onSelectedPlace]);

    if (value !== "") {
        streetAddressText = value;
        inputContentClasses += " filled";
    }

    if (streetAddressText && streetAddressText.length > 0 && label) {
        inputLabel = <Label floating>{label}</Label>;
    }

    let errorInfo = null;

    const showError = errorMessage && errorMessage.length > 0;

    if (showError) {
        errorInfo = <p className="input-error">{errorMessage}</p>;
        inputClassName += " error";
    }

    return (
        <div className={inputContentClasses}>
            {inputLabel}

            <div className={inputClassName}>
                <input
                    id={id}
                    type="text"
                    placeholder={placeholder}
                    ref={autoCompleteRef}
                    onChange={onSelectedPlace}
                    value={streetAddressText}
                />
            </div>
            {errorInfo}
        </div>
    );
}
