import React, { useState } from "react";
import { Image, Divider, Card } from "semantic-ui-react";
import { DefaultButton, BackButton, CustomInputButtom, ErrorModal, CustomCheckbox } from "../../..";
import { OrderCard } from "../../..";
import SecureCheckout from "../../../../images/secure-cheeckout.svg";
import FreeShipping from "../../../../images/free-shipp.svg";
import SecureSsl from "../../../../images/secure-ssl.svg";
import { validateDiscountCoupon } from "../../../../services/SetupService";
import * as ERRORS from "../../../../constants/Errors";
import * as CONFIRMATIONS from "../../../../constants/Confirmations";
import * as CONSTANTS from "../../../../constants/Global";

function OrderSummary(props) {
    const {
        saveAndPay,
        ordersSummaryData,
        removeUpgrade,
        removeSubscription,
        setAutoRenewing,
        loadingSecondaryButton,
        saveDiscountCoupon,
        couponData,
        giftType,
        oneTimeGift,
        formState,
        cardElementsState,
        showCreditCardForm,
        handleSubscribeNewsLetter,
    } = props;
    const [couponName, setCouponName] = useState(couponData ? couponData.code : "");
    const [loadingCoupon, setLoadingCoupon] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [couponError, setCouponError] = useState(false);
    const subscriptionData = ordersSummaryData.find((order) => order.type === CONSTANTS.BOX_TYPES.SUBSCRIPTION);

    const onChangeCoupon = (event, { value }) => {
        setCouponName(value);
    };

    const specialCodeValidations = async (couponValidation) => {
        const specialCodeConditions = [
            {
                codeName: "jingle",
                conditions: ["3", "christmas"],
                message:
                    "This promotional code could be added if you select a 3 months Subscription and the Christmas Upgrade, please select the suggested items and validate the code again.",
            },
        ];

        const findValidation = specialCodeConditions.find(
            (condition) => condition.codeName.toLowerCase() === couponValidation.code.toLowerCase()
        );

        if (findValidation) {
            const checkedConditions = [];

            for (let index = 0; index < findValidation.conditions.length; index++) {
                const element = findValidation.conditions[index];
                const validateOrders =  ordersSummaryData.some((order) => order.detail.toLowerCase().includes(element));
                checkedConditions.push(validateOrders)
            }

            const filteredCheckedConditions = checkedConditions.filter((checked) => checked)
            if (filteredCheckedConditions.length !== findValidation.conditions.length) {
                return {
                    valid: false,
                    message: findValidation.message,
                };
            }

            return {
                valid: true,
                message: "",
            };
        } else {
            return {
                valid: true,
                message: "",
            };
        }
    };

    const checkDiscountCoupon = async () => {
        if (couponName !== "") {
            setLoadingCoupon(true);
            const coupon = {
                code: couponName,
                subscriptionType: subscriptionData.packages,
            };

            const couponValidation = await validateDiscountCoupon(coupon);

            if (couponValidation) {
                setLoadingCoupon(false);
                if (couponValidation === "not found") {
                    setOpenErrorModal(true);
                    setCouponError(ERRORS.ERROR_COUPON_NOT_FOUND);
                } else if (couponValidation === "already used") {
                    setOpenErrorModal(true);
                    setCouponError(ERRORS.ERROR_COUPON_USED_PREVIOUSLY);
                } else {
                    if (couponValidation.active) {
                        const specialValidation = await specialCodeValidations(couponValidation);
                        if (specialValidation.valid) {
                            saveDiscountCoupon(couponValidation);
                        } else {
                            setOpenErrorModal(true);
                            setCouponError(specialValidation.message);
                            saveDiscountCoupon(null);
                        }
                    } else {
                        setOpenErrorModal(true);
                        setCouponError(ERRORS.ERROR_COUPON_INVALID);
                    }
                }
            } else {
                setLoadingCoupon(false);
                setOpenErrorModal(true);
                setCouponError(ERRORS.ERROR_VALIDATING_CODE);
            }
        }
    };

    const removeCode = () => {
        saveDiscountCoupon(null);
        setCouponName("");
    };

    const closeErrorModal = () => {
        setCouponName("");
        setOpenErrorModal(false);
    };

    const sumValuesBy = "price";
    const upgradesSum = ordersSummaryData
        .filter((order) => order.type === CONSTANTS.BOX_TYPES.UPGRADE)
        .reduce((a, b) => a + (b[sumValuesBy] || 0), 0);

    let subscriptionPrice = subscriptionData;

    if (subscriptionData) {
        subscriptionPrice = subscriptionData.price;
    }

    let discountedTotalValue = upgradesSum + subscriptionPrice;

    if (couponData) {
        if (couponData.coupon) {
            if (couponData.coupon.percent_off) {
                discountedTotalValue =
                    upgradesSum + subscriptionPrice - discountedTotalValue * (couponData.coupon.percent_off / 100);
            } else if (couponData.coupon.amount_off) {
                discountedTotalValue = (discountedTotalValue - couponData.coupon.amount_off);
            } else {
                discountedTotalValue = upgradesSum;
            }
        }
    }

    let errorModal = null;

    if (openErrorModal) {
        errorModal = <ErrorModal open={openErrorModal} message={couponError} closeFunction={closeErrorModal} />;
    }

    const couponDisabled = couponData && couponData.code !== "";

    let joinSubscriptionSection = null;

    let discountInfo = "";

    if (couponData && couponData.coupon) {
        if (couponData.coupon.percent_off) {
            discountInfo = `- ${couponData.coupon.percent_off}%`;
        } else if (couponData.coupon.amount_off) {
            discountInfo = `- $${couponData.coupon.amount_off}`;
        }
    }
    let promoCodeSection = (
        <>
            <Divider />
            <div className="summary-item">
                <p>Promo Code</p>
                <span>{couponData && couponData.code && `${couponData.code} (${discountInfo})`}</span>
            </div>
            <CustomInputButtom
                placeholder="Promo Code"
                onChangeAction={onChangeCoupon}
                value={couponData ? couponData.code : couponName}
                onClickAction={checkDiscountCoupon}
                loading={loadingCoupon}
                disabled={couponDisabled}
                removeCode={removeCode}
            />
        </>
    );

    if (giftType && oneTimeGift) {
        joinSubscriptionSection = (
            <>
                <div>
                    <h3 className="text-center">Save 30% on your first order when you add a Subscription!</h3>
                    <DefaultButton title="Join Now" inverted={true} buttonAction={() => removeSubscription(true)} />
                </div>
                <Divider />
            </>
        );

        promoCodeSection = null;
    }

    const cardDataError = [
        cardElementsState.cardCvcEmptyError,
        cardElementsState.cardExpiryEmptyError,
        cardElementsState.cardNumberEmptyError,
    ].some((item) => item);

    const cardDataInputsEmpty = [
        cardElementsState.cardCvcEmpty,
        cardElementsState.cardExpiryEmpty,
        cardElementsState.cardNumberEmpty,
    ].some((item) => item);

    const userDataInputs = [formState.localFirstName, formState.localLastName, formState.localPostalCode].some(
        (item) => !item
    );

    const disabledPaymenButton = (cardDataError || cardDataInputsEmpty || userDataInputs) && showCreditCardForm;

    return (
        <>
            {errorModal}
            <section className="order-summary-container">
                <h1
                    className="text-center text-h1"
                    style={{ paddingBottom: "25px", marginBottom: "25px", paddingTop: "25px" }}
                >
                    Order Summary
                </h1>
                <Card.Group textAlign="center" centered>
                    {ordersSummaryData.map((plan, index) => {
                        return (
                            <OrderCard
                                key={index}
                                plan={plan}
                                removeUpgrade={removeUpgrade}
                                removeSubscription={removeSubscription}
                                setAutoRenewing={setAutoRenewing}
                            />
                        );
                    })}
                </Card.Group>
                {joinSubscriptionSection}
                <div className="summary-item">
                    <p>SHIPPING</p>
                    <span className="bold">FREE</span>
                </div>
                {promoCodeSection}
                <div className="summary-item">
                    <CustomCheckbox
                        id="subscribeNewsLetter"
                        isBigSize={true}
                        label={CONFIRMATIONS.RECIEVE_MARKETING_EMAIL}
                        value={formState.subscribeNewsLetter}
                        onChangeAction={handleSubscribeNewsLetter}
                    />
                </div>
                <Divider />
                <div className="summary-item">
                    <p className="bold">Total (USD)</p>
                    <span className="bold total">{`$${discountedTotalValue.toFixed(2)}`}</span>
                </div>
                <div className="show-mobile margin-top padding-bottom container-padding">
                    <DefaultButton
                        title="Order The Box"
                        loading={loadingSecondaryButton}
                        buttonAction={saveAndPay}
                        disabled={disabledPaymenButton}
                    />
                    <br />
                    <BackButton title="Back" />
                </div>
            </section>
            <section className="icons-section">
                <Image src={SecureCheckout} size="small" />
                <Image src={SecureSsl} size="small" />
                <Image className="fixed-image" src={FreeShipping} size="small" />
            </section>
        </>
    );
}

export default OrderSummary;
