import React from "react";
import { Menu } from "semantic-ui-react";
import { useLocation, useHistory } from "react-router-dom";
import { SUBSCRIPTION_SUMMARY } from "../../navigation/Routes";

const CustomMenu = ({ signOut, open, closeMenu, handleTabIndexFromMenu }) => {
    const history = useHistory();
    const location = useLocation();

    const handleSubscriptionButton = () => {
        if (location.pathname !== SUBSCRIPTION_SUMMARY) {
            history.push(SUBSCRIPTION_SUMMARY, { tabIndex: 1 });
        } else {
            handleTabIndexFromMenu(1);
        }
    };

    const handleAccountButton = () => {
        if (location.pathname !== SUBSCRIPTION_SUMMARY) {
            history.push(SUBSCRIPTION_SUMMARY, { tabIndex: 0 });
        } else {
            handleTabIndexFromMenu(0);
        }
    };

    return (
        <div>
            {open && (
                <Menu vertical onMouseLeave={closeMenu}>
                    <Menu.Item onClick={handleAccountButton}>Account</Menu.Item>
                    <Menu.Item onClick={handleSubscriptionButton}>Subscription</Menu.Item>
                    <Menu.Item onClick={signOut}>Logout</Menu.Item>
                </Menu>
            )}
        </div>
    );
};

export default CustomMenu;
