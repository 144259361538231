import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import BoxDescriptionComponent from "../../components/screenComponents/account/BoxDescriptionComponent";
import { getUpgradeName, upgradeBoxOptions } from "../../services/UpgradeBoxService";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/pages/account.scss";

function BoxDescription(props) {
    const { history, location, user } = props;
    const { subscriptions } = user;
    const [boxData, setBoxData] = useState({});

    useEffect(() => {
        const configureCurrentBox = () => {
            const referenceData = location.search.replace("?", "");
            const boxDataIds = referenceData.split("&&");
            const seachSubscriptionId = boxDataIds[0];
            const searchBoxId = boxDataIds[1];

            const subscription = subscriptions.find(
                (subscription) => subscription.subscriptionId === seachSubscriptionId
            );

            const { parents, shippingAddress, ownerId, subscriptionId } = subscription;

            const currentRawBox = subscription.boxes.find((box) => box.boxId === searchBoxId);
            currentRawBox.parents = parents;

            let upgradesInfo = {};

            if (currentRawBox.upgrades.length > 0) {
                upgradesInfo.upgradeNames = currentRawBox.upgrades
                    .map((upgrade) => getUpgradeName(upgrade.upgradeType, currentRawBox.parents))
                    .join(" & ");
                upgradesInfo.upgradeImages = currentRawBox.upgrades.map((upgrade) => {
                    return {
                        image: upgradeBoxOptions.find(
                            (upgradeOption) => upgradeOption.tradename === upgrade.upgradeType
                        ).image,
                        name: getUpgradeName(upgrade.upgradeType, currentRawBox.parents),
                    };
                });
            }

            const settingOne = {
                "Shipping Id": currentRawBox.boxId,
                "Upgrade(s)": upgradesInfo.upgradeNames,
                "Track your Box_link": currentRawBox.trackingId,
                trackingLink: currentRawBox.trackingLink,
                Status: currentRawBox.status,
            };

            if (!upgradesInfo.hasOwnProperty("upgradeNames")) {
                delete settingOne["Upgrade(s)"];
            }

            const boxMonth = Number(currentRawBox.boxMonth) - 1;

            const currentBox = {
                ...currentRawBox,
                month: CONSTANTS.MONTHS[boxMonth],
                name: `${CONSTANTS.MONTHS[boxMonth]} Box`,
                parents: parents,
                shippingSubscriptionAddress: shippingAddress,
                boxSettings: settingOne,
                ownerId: ownerId,
                subscriptionId: subscriptionId,
                ...upgradesInfo,
            };

            setBoxData(currentBox);
        };

        if (subscriptions && subscriptions.length > 0) {
            configureCurrentBox();
        }
    }, [subscriptions, location]);

    let boxDescription = null;

    if (boxData.hasOwnProperty("boxId")) {
        boxDescription = <BoxDescriptionComponent history={history} boxData={boxData} />;
    }

    return <Container>{boxDescription}</Container>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, null))(withRouter(BoxDescription));
