let GRANDBOX_MAIN_URL = process.env.REACT_APP_GRANDBOX_URL;

if (!GRANDBOX_MAIN_URL) {
    GRANDBOX_MAIN_URL = "https://grandbox.co/";
}

export const GRANDBOX_URLS = {
    HOME: GRANDBOX_MAIN_URL,
    TERMS_OF_USE: `${GRANDBOX_MAIN_URL}privacy-policy`,
    PRIVACY_POLICITY: `${GRANDBOX_MAIN_URL}terms-of-services`,
    404: `${GRANDBOX_MAIN_URL}404`,
};

export const INITIAL = "/";

//subscription
export const SUBSCRIBE = "/subscribe/";
export const STEP_ONE = `${SUBSCRIBE}step-1`;
export const STEP_TWO = `${SUBSCRIBE}step-2`;
export const STEP_THREE = `${SUBSCRIBE}step-3`;
export const STEP_FOUR = `${SUBSCRIBE}step-4`;
export const STEP_FIVE = `${SUBSCRIBE}step-5`;
export const STEP_SIX = `${SUBSCRIBE}step-7`;
export const SUCCESSFUL = `${SUBSCRIBE}successful`;

//manage-subscription
export const ACCOUNT = "/account/";
export const SUBSCRIPTION_SUMMARY = `${ACCOUNT}summary`;
export const BOX_DESCRIPTION = `${ACCOUNT}box-description`;
export const SHIPPING_ADDRESS = `${ACCOUNT}shipping-address`;
export const ADD_FEATURE_NOTE = `${ACCOUNT}add-feature-note`;
export const ADD_FEATURE_PHOTOS = `${ACCOUNT}add-feature-photos`;
export const BOX_DETAILS = `${ACCOUNT}box-details`;
export const SIGN_UP = `${ACCOUNT}sign-up`;
export const LOGIN = `${ACCOUNT}login`;
export const RESET_PASSWORD = `${ACCOUNT}reset-password`;
export const EMAIL_VALIDATION = `${ACCOUNT}email-validation`;
export const USER_INFORMATION = `${ACCOUNT}user-information`;
export const ADD_NEW_UPGRADE = `${ACCOUNT}upgrade-market`;
export const UPDATE_PAYMENT_METHOD = `${ACCOUNT}update-payment-method`;

//cancel subscription
export const CANCEL_SUBSCRIPTION = "/cancel-subscription";
export const CANCEL_SUBSCRIPTION_REMAINING = `${CANCEL_SUBSCRIPTION}/boxes-remaining`;
export const CANCEL_SURVEY = `${CANCEL_SUBSCRIPTION}/cancel-survey`;
export const CONFIRM_CANCELATION = `${CANCEL_SUBSCRIPTION}/confirm-cancelation`;

export const ROUTES_LIST = [
    INITIAL,
    SIGN_UP,
    LOGIN,
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    SUCCESSFUL,
    SUBSCRIBE,
    STEP_ONE,
    STEP_TWO,
    STEP_THREE,
    STEP_FOUR,
    STEP_FIVE,
    STEP_SIX,
    ACCOUNT,
    SUBSCRIPTION_SUMMARY,
    BOX_DESCRIPTION,
    BOX_DETAILS,
    SHIPPING_ADDRESS,
    ADD_FEATURE_NOTE,
    ADD_FEATURE_PHOTOS,
    CANCEL_SUBSCRIPTION_REMAINING,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    USER_INFORMATION,
    ADD_NEW_UPGRADE,
    UPDATE_PAYMENT_METHOD,
];

export const HIDE_PROMO_ROUTES = [
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    ADD_FEATURE_NOTE,
    ADD_FEATURE_PHOTOS,
    BOX_DESCRIPTION,
    BOX_DETAILS,
    SHIPPING_ADDRESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    SUBSCRIPTION_SUMMARY,
    USER_INFORMATION,
    ADD_NEW_UPGRADE,
    UPDATE_PAYMENT_METHOD,
];

export const HIDE_GIFT_TITLE_ROUTES = [
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    SUBSCRIPTION_SUMMARY,
    ADD_FEATURE_NOTE,
    ADD_FEATURE_PHOTOS,
    BOX_DESCRIPTION,
    BOX_DETAILS,
    SHIPPING_ADDRESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    LOGIN,
    SIGN_UP,
    STEP_FOUR,
    STEP_FIVE,
    STEP_SIX,
    USER_INFORMATION,
    ADD_NEW_UPGRADE,
    UPDATE_PAYMENT_METHOD,
];

export const HIDE_UPGRADE_TITLE_ROUTES = [
    RESET_PASSWORD,
    EMAIL_VALIDATION,
    SUBSCRIPTION_SUMMARY,
    ADD_FEATURE_NOTE,
    ADD_FEATURE_PHOTOS,
    BOX_DESCRIPTION,
    BOX_DETAILS,
    SHIPPING_ADDRESS,
    CANCEL_SUBSCRIPTION,
    CANCEL_SURVEY,
    CONFIRM_CANCELATION,
    LOGIN,
    SIGN_UP,
    STEP_SIX,
    USER_INFORMATION,
    ADD_NEW_UPGRADE,
    UPDATE_PAYMENT_METHOD,
];
