import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import CancelSubscriptionComponent from "../../components/screenComponents/account/CancelSubscriptionComponent";
import { cancelSubscription } from "../../store/user/actions";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/pages/account.scss";

function CancelSubscription(props) {
    const { history, location, cancelSubscription, user } = props;
    const { cancelData } = user;
    const subscription = location.state;
    const [noMoreBoxes, setNoMoreBoxes] = useState(false);

    useEffect(() => {
        const areUnshippedBoxes = subscription.boxes.some((box) => box.status === CONSTANTS.BOX_STATUS.UNSHIPPED);
        if (!areUnshippedBoxes) {
            history.replace(ROUTES.CANCEL_SURVEY, subscription);
        }
    }, [subscription, history]);

    useEffect(() => {
        setNoMoreBoxes(user.cancelData.noMoreBoxes);
    }, [user.cancelData]);

    const goToCancelSurvey = () => {
        Object.assign(cancelData, {
            noMoreBoxes: noMoreBoxes,
        });

        cancelSubscription(cancelData);

        history.push(ROUTES.CANCEL_SURVEY, subscription);
    };

    const changeNoMoreBoxes = () => {
        setNoMoreBoxes(!noMoreBoxes);
    };

    return (
        <>
            <Container>
                <CancelSubscriptionComponent
                    history={history}
                    subscription={subscription}
                    goToCancelSurvey={goToCancelSurvey}
                    changeNoMoreBoxes={changeNoMoreBoxes}
                    noMoreBoxes={noMoreBoxes}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    cancelSubscription: (cancelData) => dispatch(cancelSubscription(cancelData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(CancelSubscription));
