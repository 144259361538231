import React from "react";
import { List, Grid, Header, Icon, Segment, Button, Image } from "semantic-ui-react";
import { SubHeader, DefaultButton } from "../..";
import "../../../scss/pages/account.scss";
import "../../../scss/components/_utilities.scss";
import VisaLogo from "../../../images/visa.jpg";
import MasterCardLogo from "../../../images/mastercard.jpg";

export default function UserInformationComponent(props) {
    const {
        userData,
        paymentMethod,
        loadingPaymentMethod,
        goToUpdatePaymentMethod,
        goToResetPassword,
        showAddPaymentMethod,
    } = props;

    let userInformation = null;

    if (userData) {
        const { email, name, phone, firebase } = userData;

        userInformation = (
            <Grid.Column mobile={16} computer={8} tablet={8}>
                <Segment className="segment-element">
                    <div className="header-div">
                        <Header as="h3" content="User Information" className="subtitle-bigger" />
                    </div>
                    <List size="large" className="user-information-list">
                        <List.Item className="info-item">
                            <List.Header className="list-element bold">Subscriber Name:</List.Header>
                            <p className="list-element name">{name}</p>
                        </List.Item>
                        <List.Item className="info-item">
                            <List.Header className="list-element bold">Email:</List.Header>
                            <p className="list-element">{email}</p>
                        </List.Item>
                        <List.Item className="info-item">
                            <List.Header className="list-element bold">Phone:</List.Header>
                            <p className="list-element phone">{phone}</p>
                        </List.Item>

                        <List.Item className="info-item">
                            <List.Header className="list-element bold">Password:</List.Header>
                            <div className="list-element">
                                {firebase.sign_in_provider === "password" ? (
                                    <>
                                        **********
                                        <Button onClick={goToResetPassword}>Reset</Button>
                                    </>
                                ) : (
                                    <>
                                        If you want to change your password, it must be through your social network
                                        provider ({firebase.sign_in_provider})
                                    </>
                                )}
                            </div>
                        </List.Item>
                    </List>
                </Segment>
            </Grid.Column>
        );
    }

    let paymentData = null;

    if (loadingPaymentMethod) {
        paymentData = (
            <Grid.Column mobile={16} computer={8} tablet={8}>
                <Header textAlign="center" as="h3" content="Payment Information" className="subtitle-bigger" />
                <List size="large">
                    <List.Item>Loading...</List.Item>
                </List>
            </Grid.Column>
        );
    } else {
        if (paymentMethod) {
            let cardBrandIcon = null;

            switch (paymentMethod.brand) {
                case "visa":
                    cardBrandIcon = <Image className="image-credit-card" src={VisaLogo} size="tiny" />;
                    break;
                case "mastercard":
                    cardBrandIcon = <Image className="image-credit-card" src={MasterCardLogo} size="tiny" />;
                    break;
                case "amex":
                    cardBrandIcon = <Icon size="large" name="cc amex" />;
                    break;
                case "diners_club":
                    cardBrandIcon = <Icon size="large" name="cc diners club" />;
                    break;
                default:
                    cardBrandIcon = <Icon size="large" name="credit card" />;
                    break;
            }

            paymentData = (
                <Grid.Column mobile={16} computer={8} tablet={8}>
                    <Header
                        textAlign="left"
                        as="h3"
                        content="Payment Information"
                        className="subtitle-bigger lesser-padding-top"
                    />
                    <Segment className="segment-element">
                        <List size="large">
                            <List.Item>
                                <div className="special-content">
                                    <div>
                                        <p className="card-info">
                                            {cardBrandIcon} <span>XXXX-XXXX-XXXX-{paymentMethod.last4}</span>
                                        </p>
                                        <p className="margin-top-0 expiration">
                                            Expiration Date:{" "}
                                            {paymentMethod.exp_month < 10
                                                ? `0${paymentMethod.exp_month}`
                                                : paymentMethod.exp_month}
                                            /{paymentMethod.exp_year}
                                        </p>
                                    </div>
                                    <Button onClick={goToUpdatePaymentMethod} className="eighteen-px-size color-black">
                                        Change
                                    </Button>
                                </div>
                            </List.Item>
                        </List>
                    </Segment>
                </Grid.Column>
            );
        } else {
            if (showAddPaymentMethod) {
                paymentData = (
                    <Grid.Column mobile={16} computer={8} tablet={8}>
                        <Header
                            textAlign="center"
                            as="h3"
                            content="Payment Information"
                            className="subtitle-bigger lesser-padding-top"
                        />
                        <List size="large">
                            <List.Item>
                                <List.Header>You are not have an active payment method:</List.Header>
                                <DefaultButton title="ADD PAYMENT METHOD" buttonAction={goToUpdatePaymentMethod} />
                            </List.Item>
                        </List>
                    </Grid.Column>
                );
            }
        }
    }

    return (
        <section className="padding-page">
            <div className="tab-subscription-container">
                <SubHeader textLeft mainTitle="Manage My Account" />
            </div>
            <div className="manage-subscription-container">
                <Grid className="user-information-grid">
                    {userInformation} {paymentData}
                </Grid>
            </div>
        </section>
    );
}
