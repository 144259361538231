export const LOAD_STORAGE_STEPS = "subscription/LOAD_STORAGE_STEPS";
export const SAVE_STEP_ONE = "subscription/SAVE_STEP_ONE";
export const SAVE_STEP_TWO = "subscription/SAVE_STEP_TWO";
export const SAVE_STEP_THREE = "subscription/SAVE_STEP_THREE";
export const SAVE_STEP_FOUR = "subscription/SAVE_STEP_FOUR";
export const SAVE_STEP_FIVE = "subscription/SAVE_STEP_FIVE";
export const SAVE_STEP_SIX = "subscription/SAVE_STEP_SIX";
export const SAVE_STEP_SEVEN = "subscription/SAVE_STEP_SEVEN";
export const SAVE_COUPON_DATA = "subscription/SAVE_COUPON_DATA";
export const RESET_SUBSCRIPTION_STORE = "subscription/RESET_SUBSCRIPTION_STORE";
export const SAVE_ONE_TIME_GIFT_TYPE = "subscription/SAVE_ONE_TIME_GIFT_TYPE";
