import { v4 as uuidv4 } from "uuid";
import * as actions from "./action-types";
import { getLocalStorageItem, setLocalStorageItem } from "../../services/LocalStorageService";
import { getCookie } from "../../services/CookieService";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
const jwtDecode = require("jwt-decode");

export const getUserStoredData = () => {
    return async function (dispatch) {
        const storedUserData = await getCookie(LOCALSTORAGE.USER_DATA);
        const storedSubscriptionsData = await getLocalStorageItem(LOCALSTORAGE.STORAGED_USER_SUBSCRIPTIONS);
        const storedSessionId = await getLocalStorageItem(LOCALSTORAGE.SESSION_ID);

        if (storedSubscriptionsData) {
            dispatch({ type: actions.SAVE_USER_SUBSCRIPTIONS, payload: storedSubscriptionsData });
        }

        if (storedUserData) {
            const decoded = jwtDecode(storedUserData);
            let sessionId = null;

            if (storedSessionId) {
                sessionId = storedSessionId;
            } else {
                sessionId = uuidv4();
                setLocalStorageItem(LOCALSTORAGE.SESSION_ID, sessionId);
            }

            dispatch({
                type: actions.LOAD_STORAGED_USER,
                payload: decoded,
            });
            return "loggedIn";
        } else {
            return "notLoggedIn";
        }
    };
};

export const saveUserSubscriptions = (userSubscriptions) => {
    return async function (dispatch) {
        const selectedSubscription = getLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION);
        let updatedSubscription = null;

        if (selectedSubscription) {
            updatedSubscription = userSubscriptions.find(
                (subscription) => subscription.subscriptionId === selectedSubscription.subscriptionId
            );

            setLocalStorageItem(LOCALSTORAGE.SELECTED_SUBSCRIPTION, updatedSubscription);
        }

        setLocalStorageItem(LOCALSTORAGE.STORAGED_USER_SUBSCRIPTIONS, userSubscriptions);

        dispatch({ type: actions.SAVE_USER_SUBSCRIPTIONS, payload: userSubscriptions });
    };
};

export const cancelSubscription = (cancelData) => {
    return async function (dispatch) {
        dispatch({ type: actions.SAVE_CANCEL_DATA, payload: cancelData });
    };
};
