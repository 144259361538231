export const returnAddressInfoList = (addressComponents) => {
    let address = {};
    const componentForm = {
        street_number: "long_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "long_name",
        country: "long_name",
        postal_code: "long_name",
    };

    for (let i = 0; i < addressComponents.length; i++) {
        const addressType = addressComponents[i].types[0];

        if (componentForm[addressType]) {
            let addresSection = "";

            if (addressType === "administrative_area_level_1") {
                addresSection = "state";
            } else if (addressType === "locality") {
                addresSection = "city";
            } else if (addressType === "postal_code") {
                addresSection = "postalCode";
            } else {
                addresSection = addressType;
            }

            const val = addressComponents[i][componentForm[addressType]];

            Object.assign(address, {
                [addresSection]: val,
            });
        }
    }

    let streetNumber = "";
    if (address.street_number) {
        streetNumber = address.street_number;
    }

    let route = "";
    if (address.route) {
        route = address.route;
    }

    const finalAddress = {
        city: address.city ? address.city : "",
        state: address.state ? address.state : "",
        address: `${streetNumber} ${route}`,
        postalCode: address.postalCode ? address.postalCode : "",
    };

    return finalAddress;
};
