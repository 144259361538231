import React from "react";
import { Image, Card, Header } from "semantic-ui-react";
import moment from "moment";
import { getDeliverDate } from "../../services/ManageSubscriptionService";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/components/_cards.scss";
import "../../scss/components/_utilities.scss";

export function MonthBoxCard(props) {
    const { box, goToBoxDetail, changePhotosQuantity} = props;
    const {
        boxMonth,
        boxType,
        boxNumber,
        features,
        status,
        boxName,
        boxImage,
        deliverDate,
        upgrades,
        welcomeBox,
        withUpgrades
    } = box;

    const { note, photos } = features;
    const monthIndex = Number(boxMonth) - 1;
    const monthName = CONSTANTS.MONTHS[monthIndex];

    const getAddFeatureStatusInfo = () => {
        let statusInfo = null;

        if ((boxNumber === 1 && welcomeBox) || withUpgrades) {
            const deliverDateThreeDaysLess = moment(deliverDate).subtract(4, "days").toISOString();
            const canAddFeatures = moment().isSameOrBefore(deliverDateThreeDaysLess);
            if (canAddFeatures) {
                statusInfo = statusInfo = (
                    <Card.Description style={{ color: "#d2142d" }}>Pending Features</Card.Description>
                );
            } else {
                statusInfo = <Card.Description style={{ color: "#ad7733" }}>Waiting Shipping</Card.Description>;
            }
        } else {
            const canAddFeatures = Number(moment().format("D")) < CONSTANTS.MAX_FEATURES_UPDATE_DAY;
            const monthBoxIsGreaterThanThisMonth = Number(boxMonth) > Number(moment().format("MM"));
            const monthBoxIsEqualThisMonth = Number(boxMonth) === Number(moment().format("MM"));

            if(monthBoxIsEqualThisMonth){ 
                if(canAddFeatures){
                    statusInfo = <Card.Description style={{ color: "#d2142d" }}>Pending Features</Card.Description>;
                } else {
                    statusInfo = <Card.Description style={{ color: "#ad7733" }}>Waiting Shipping</Card.Description>;
                }
            } else {
                if(monthBoxIsGreaterThanThisMonth) {
                    statusInfo = <Card.Description style={{ color: "#d2142d" }}>Pending Features</Card.Description>;
                } else {
                   const isSameYear = moment().isSame(deliverDate, 'year');

                   if(!isSameYear){
                        statusInfo = <Card.Description style={{ color: "#d2142d" }}>Pending Features</Card.Description>;
                   }
                }
            }
        }

        return {
            statusInfo: statusInfo,
        };
    };

    let boxShowName = `${monthName} Box`;
    let imageClasses = "image";
    let infoClasses = "info";
    let monthBoxCardClasses = "month-box-card";
    if (boxType === CONSTANTS.BOX_TYPES.UPGRADE) {
        boxShowName = `${boxName}`;
        imageClasses += " upgrade";
        infoClasses += " upgrade";
        monthBoxCardClasses += " upgrade";

        if (!boxName.includes("Box")) {
            boxShowName = `${boxName}`;
        }
    }

    let statusInfo = null;
    let deliverInfo = null;
    let routeAction = () => goToBoxDetail(box);

    const deliverDateText = getDeliverDate(deliverDate, upgrades);

    if (status === CONSTANTS.BOX_STATUS.UNSHIPPED) {
        if (
            note.status.toUpperCase() === CONSTANTS.FEATURES_STATUS.EMPTY ||
            photos.status.toUpperCase() === CONSTANTS.FEATURES_STATUS.EMPTY
        ) {
            statusInfo = getAddFeatureStatusInfo().statusInfo;

            if (getAddFeatureStatusInfo()) {
                if (changePhotosQuantity && photos.files.length === 4) {
                    statusInfo = <Card.Description style={{ color: "#ad7733" }}>Waiting Shipping</Card.Description>;
                }
            }
        } else {
            statusInfo = <Card.Description style={{ color: "#ad7733" }}>Waiting Shipping</Card.Description>;
        }

        deliverInfo = `Deliver in ${deliverDateText}`;
    } else {
        if (status === CONSTANTS.BOX_STATUS.CANCELLED) {
            routeAction = null;
            statusInfo = <Card.Description style={{ color: "blue" }}>Box Cancelled</Card.Description>;
        } else if (status === CONSTANTS.BOX_STATUS.DONATED) {
            routeAction = null;
            statusInfo = <Card.Description style={{ color: "blue" }}>Box Donated</Card.Description>;
        } else if (status === CONSTANTS.BOX_STATUS.INCOMPLETE) {
            statusInfo = <Card.Description style={{ color: "blue" }}>Subscription Incomplete</Card.Description>;
            routeAction = null;
        } else {
            const deliveredDate = moment(deliverDate).add(1, "day").format("MMM DD YYYY");

            deliverInfo = `Delivered in ${deliveredDate}`;
            statusInfo = <Card.Description style={{ color: "#78a55b" }}>Box Shipped</Card.Description>;
        }
    }

    return (
        <Card className={monthBoxCardClasses} fluid>
            <div className="card-overlay" onClick={routeAction} />
            <Card.Content>
                <div className="month-box-card-container">
                    <div className={imageClasses}>
                        <Image
                            src={boxImage}
                            size="medium"
                            style={{ width: boxShowName.includes("birthday") && "50%" }}
                        />
                    </div>
                    <div className={infoClasses}>
                        <div className="header-div">
                            <Header as="h3">{boxShowName}</Header>
                            <button className="fake-button">View</button>
                        </div>
                        <Card.Meta>{deliverInfo}</Card.Meta>
                        {statusInfo}
                    </div>
                </div>
            </Card.Content>
        </Card>
    );
}
