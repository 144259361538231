import React, { useEffect, useState } from "react";
import { Image, Modal, Header } from "semantic-ui-react";
import * as LOADINGS from "../../constants/Loadings";
import ConfirmImage from "../../images/gifs/confirm.gif";
import "../../scss/components/_modal.scss";

export function CreateSubscriptionModal(props) {
    const { open, confirmAction, paymentError, setShowCreateSubsModal } = props;
    const [textIndex, setTextIndex] = useState(0);
    const [uploadText, setUploadText] = useState("");

    useEffect(() => {
        if (paymentError === "") {
            confirmAction();
        } else {
            setShowCreateSubsModal(false);
        }
    }, [confirmAction, paymentError, setShowCreateSubsModal]);

    useEffect(() => {
        let timer = null;
        const handleLoadingMessages = () => {
            const textStrings = [LOADINGS.LOADING_PHOTOS_WAIT, LOADINGS.LOADING_PHOTOS_ALMOST_READY];

            setUploadText(textStrings[textIndex]);
            if (textIndex === textStrings.length) {
                setTextIndex(0);
            } else {
                timer = setTimeout(() => {
                    setTextIndex(textIndex + 1);
                    setUploadText(textStrings[textIndex]);
                }, 10000);
            }
        };

        handleLoadingMessages();

        return () => {
            clearTimeout(timer);
        };
    }, [textIndex]);

    return (
        <Modal className="confirm-modal" open={open} size="small">
            <Modal.Content>
                <Image src={ConfirmImage} size="small" centered />
                <Header as="h2" textAlign="center">
                    Awesome!
                </Header>
                <p className="create-subscription">
                    Remember that you qualified to <strong>FREE SHIPPING!</strong>
                </p>
                <p>{uploadText}</p>
            </Modal.Content>
        </Modal>
    );
}
