import React from "react";
import {
    BoxSubHeader,
    DefaultButton,
    CustomDropdown,
    CustomInput,
    SearchLocationInput,
    BackButton,
    Map,
} from "../components";
import usStates from "../json/USStates.json";

const countryOptions = [
    {
        key: 0,
        text: "United States",
        value: "United States",
    },
];

export function ShippingAddressForm(props) {
    const {
        parentsString,
        state,
        onChangeWriteOptions,
        onChangeSelectOptions,
        onSelectedPlace,
        showBackButton,
        submitAction,
        submitButtonTitle,
        subHeaderType,
        boxName,
        history,
        // writedAddressManually,
        type,
    } = props;

    let backButton = null;

    if (showBackButton) {
        backButton = <BackButton title="Back"/>;
    }

    let addressInMap = null;

    if (state.showAddressMap) {
        addressInMap = <Map geometry={state.localGeometry} />;
    }

    let subHeader = null;

    switch (subHeaderType) {
        case "box":
            subHeader = <BoxSubHeader title="Shipping Details" boxName={boxName} history={history} />;
            break;
        default:
            break;
    }

    let personalizedLabel = "";
    if (parentsString) {
        personalizedLabel = `${parentsString}'s`;
    }

    const sectionClasses = type === "subscription" ? "" : "padding-page";
    const containerClasses = type === "subscription" ? "input-container" : "input-container container-padding";

    return (
        <section className={sectionClasses}>
            {subHeader}
            <br />
            <div className={containerClasses}>
                <CustomInput
                    id="localFirstName"
                    placeholder="Full Name"
                    onChangeAction={onChangeWriteOptions}
                    value={state.localFirstName}
                    errorMessage={state.localFirstNameError}
                    type="text"
                    label={`${personalizedLabel} First Name`}
                />
                <CustomInput
                    id="localLastName"
                    placeholder="Last Name"
                    onChangeAction={onChangeWriteOptions}
                    value={state.localLastName}
                    errorMessage={state.localLastNameError}
                    type="text"
                    label={`${personalizedLabel} Last Name`}
                />
                <CustomInput
                    id="localPhoneNumber"
                    placeholder="Phone Number (Optional)"
                    onChangeAction={onChangeWriteOptions}
                    value={state.localPhoneNumber}
                    errorMessage={state.localPhoneNumberError}
                    type="text"
                    label={`${personalizedLabel} Phone Number`}
                    isPhoneNumber={true}
                />
                {/* <p className="color-cta" style={{ margin: "0 0 15px 5px" }}>
                    {state.writedAddress
                        ? " Write your address manually, please fill all no optional fields."
                        : " Write your address and select a place of the list, try to start with your Postal Code"}

                    <br />
                    <a
                        href="# "
                        tabIndex="0"
                        className={`hand-address-link ${state.writedAddress ? "color-black" : ""}`}
                        onClick={writedAddressManually}
                    >
                        {state.writedAddress ? "Autocomplete address?" : "Your address is not in the list?"}
                    </a>
                </p> */}
                {state.writedAddress ? (
                    <CustomInput
                        id="localDeliveryPlace"
                        placeholder="White your address"
                        onChangeAction={onChangeWriteOptions}
                        value={state.localDeliveryPlace}
                        errorMessage={state.localDeliveryPlaceError}
                        type="text"
                        label={`${personalizedLabel} Street Address`}
                    />
                ) : (
                    <SearchLocationInput
                        id="localDeliveryPlace"
                        placeholder="Delivery Address"
                        onSelectedPlace={onSelectedPlace}
                        value={state.localDeliveryPlace}
                        errorMessage={state.localDeliveryPlaceError}
                        type="text"
                        label={`${personalizedLabel} Delivery Place`}
                    />
                )}
                <CustomInput
                    id="localCountry"
                    options={countryOptions}
                    onChangeAction={null}
                    placeholder="Shipping Country"
                    label="Country"
                    value={state.localCountry}
                    error={state.localCountryError}
                />
                <div className="multi-input-line">
                    <div className="input-half">
                        <CustomInput
                            id="localCity"
                            placeholder="City"
                            onChangeAction={onChangeWriteOptions}
                            value={state.localCity}
                            errorMessage={state.writedAddress ? state.localCityError : null}
                            type="text"
                            label="City"
                        />
                    </div>
                    <div className="input-half">
                        {state.writedAddress ? (
                            <CustomDropdown
                                id="localState"
                                options={usStates}
                                onChangeAction={onChangeSelectOptions}
                                placeholder="State"
                                value={state.localState}
                                label="State"
                            />
                        ) : (
                            <CustomInput
                                id="localState"
                                search={true}
                                onChangeAction={onChangeWriteOptions}
                                errorMessage={state.writedAddress ? state.localStateError : null}
                                placeholder="State"
                                value={state.localState}
                                label="State"
                            />
                        )}
                    </div>
                </div>
                <div className="multi-input-line">
                    <div className="input-half">
                        <CustomInput
                            id="localApt"
                            placeholder="Apt (optional)"
                            onChangeAction={onChangeWriteOptions}
                            value={state.localApt}
                            type="text"
                            label="Apt"
                        />
                    </div>
                    <div className="input-half">
                        <CustomInput
                            id="localPostalCode"
                            placeholder="Postal Code"
                            onChangeAction={onChangeWriteOptions}
                            value={state.localPostalCode}
                            errorMessage={state.writedAddress ? state.localPostalCodeError : null}
                            type="text"
                            label="Postal Code"
                        />
                    </div>
                </div>
                <div className="padding-bottom">{addressInMap}</div>

                <div className="padding-bottom flex-center flex-column ">
                    <DefaultButton
                        title={submitButtonTitle}
                        loading={state.loadingUpdate}
                        buttonAction={submitAction}
                    />
                    {type !== "subscription" && (
                        <>
                            <br />
                            {backButton}
                        </>
                    )}
                </div>
            </div>
        </section>
    );
}
