import React from "react";
import { Card } from "semantic-ui-react";
import { SubHeader, DefaultButton, BackButton } from "../..";
import { UpgradeCard } from "../..";

function StepFiveComponent(props) {
    const {
        selectedUpgrades,
        upgradeOptions,
        subscriptions,
        saveStepFiveAndNext,
        skipStepFiveAndNext,
        onSelectUpgrade,
        goToStepFour,
        saveStepFour,
        plans,
    } = props;

    let upgradeDisabled = true;

    if (selectedUpgrades.length > 0) {
        upgradeDisabled = false;
    }

    const sorteredUpgrades = upgradeOptions?.sort((a, b) => {
        if (Number(b.availableDate.ocationDate) > Number(a.availableDate.ocationDate)) {
            return -1;
        }

        if (Number(b.availableDate.ocationDate) < Number(a.availableDate.ocationDate)) {
            return 1;
        }

        return 0;
    });

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle="Upgrade Your Box"
                specialText="Regular boxes are delivered between 2th to 15 of each month and special box deliver between this special day."
            />
            <div className="cards-container upgrade-boxes">
                <Card.Group textAlign="center" centered itemsPerRow={4}>
                    {sorteredUpgrades
                        .filter((upgrade) => upgrade.status !== "blocked")
                        .map((boxUpgrade, index) => {
                            const selectedBox = selectedUpgrades.find((item) => item.id === boxUpgrade.priceId);
                            return (
                                <UpgradeCard
                                    className="upgrade-card"
                                    key={index}
                                    plans={plans}
                                    boxUpgrade={boxUpgrade}
                                    onSelection={onSelectUpgrade}
                                    selectedBox={selectedBox}
                                    grandmaName={subscriptions.grandmaData.name}
                                    grandpaName={subscriptions.grandpaData.name}
                                    relationship={subscriptions.relationship}
                                    goToStepFour={goToStepFour}
                                    saveStepFour={saveStepFour}
                                />
                            );
                        })}
                </Card.Group>
            </div>
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton
                    title="YES, UPGRADE PLEASE!"
                    buttonAction={saveStepFiveAndNext}
                    disabled={upgradeDisabled}
                />
                <br />
                <DefaultButton title="NO, THANK YOU." inverted="true" buttonAction={skipStepFiveAndNext} />
                <br />
                <BackButton title="Back"/>
            </div>
        </section>
    );
}

export default StepFiveComponent;
