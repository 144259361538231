import "rc-calendar/assets/index.css";
import "../../scss/components/_calendar.scss";
import React, { useRef } from "react";
import { Label, Input } from "semantic-ui-react";
import Calendar from "rc-calendar";
import DatePicker from "rc-calendar/lib/Picker";
import moment from "moment";

export function CalendarInput(props) {
    const { id, label, placeholder, value, onChangeDateAction, error, disabled } = props;
    let calendarContainer = useRef(null);
    let inputLabel = null;

    if (value && value.length > 0 && label) {
        inputLabel = <Label floating>{label}</Label>;
    }

    const getCalendarContainer = () => {
        return calendarContainer;
    };

    const onChangeDate = (event) => {
        const dateInformation = {
            id: id,
            date: moment(event).format("YYYY/MM/DD"),
        };

        onChangeDateAction(dateInformation);
    };

    let formattedMonth = "";

    if (value) {
        formattedMonth = moment(value).format("DD / MMMM / YYYY");
    }

    return (
        <div className="input-content">
            <div className="calendar-container" ref={(n) => (calendarContainer = n)} />
            <>
                <DatePicker
                    disabled={disabled}
                    getCalendarContainer={getCalendarContainer}
                    onChange={onChangeDate}
                    value={moment(formattedMonth)}
                    calendar={<Calendar className="calendar" showDateInput={false} showToday={false} />}
                >
                    {() => {
                        return (
                            <span>
                                {inputLabel}
                                <Input
                                    id={id}
                                    readOnly
                                    fluid
                                    placeholder={placeholder}
                                    value={formattedMonth}
                                    icon="calendar"
                                    error={error}
                                    disabled={disabled}
                                />
                            </span>
                        );
                    }}
                </DatePicker>
            </>
        </div>
    );
}
