import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { ConfirmationModal } from "../../components";
import CancelSurveyComponent from "../../components/screenComponents/account/CancelSurveyComponent";
import { cancelSubscription } from "../../store/user/actions";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import * as CONFIRMATIONS from "../../constants/Confirmations";
import "../../scss/pages/account.scss";

function CancelSurvey(props) {
    const { history, location, user, cancelSubscription } = props;
    const { cancelData } = user;
    const subscription = location.state;
    const [cancelReason, setCancelReason] = useState("");
    const [showTextArea, setShowTextArea] = useState(false);
    const [userCancelReason, setUserCancelReason] = useState("");
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    useEffect(() => {
        if (!subscription) {
            history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
        }
    }, [subscription, history]);

    useEffect(() => {
        setCancelReason(cancelData.reason);
        setUserCancelReason(cancelData.otherReason);
    }, [cancelData]);

    const onChangeCancelReason = (event) => {
        const areUnshippedBoxes = subscription.boxes.some((box) => box.status === CONSTANTS.BOX_STATUS.UNSHIPPED);

        const value = event.target.id;

        setCancelReason(value);

        if (value === CONSTANTS.CANCEL_SUBSCRIPTIONS_REASONS[6].value) {
            setShowTextArea(true);
        } else {
            setShowTextArea(false);
            setUserCancelReason("");
        }

        if (value === CONSTANTS.CANCEL_SUBSCRIPTIONS_REASONS[2].value && !cancelData.noMoreBoxes && areUnshippedBoxes) {
            setOpenConfirmModal(true);
        }
    };

    const onChangeWriteOption = (event, { value }) => {
        setUserCancelReason(value);
    };

    const goToConfirmCancelation = () => {
        Object.assign(cancelData, {
            reason: cancelReason,
            otherReason: userCancelReason,
            donateBoxes: cancelData.noMoreBoxes ? false : null,
        });

        cancelSubscription(cancelData);

        history.push(ROUTES.CONFIRM_CANCELATION, subscription);
    };

    const closeConfirmModal = () => {
        Object.assign(cancelData, {
            donateBoxes: false,
        });

        cancelSubscription(cancelData);
        setOpenConfirmModal(false);
    };

    const donateBoxesAction = () => {
        Object.assign(cancelData, {
            reason: cancelReason,
            otherReason: userCancelReason,
            donateBoxes: true,
        });

        cancelSubscription(cancelData);

        history.push(ROUTES.CONFIRM_CANCELATION, subscription);
    };

    let confirmationModal = null;

    if (openConfirmModal) {
        confirmationModal = (
            <ConfirmationModal
                message={CONFIRMATIONS.DONATION_CONFIRMATION}
                open={openConfirmModal}
                closeAction={closeConfirmModal}
                confirmAction={donateBoxesAction}
                sadIcon={true}
                donationInfo={true}
            />
        );
    }

    return (
        <>
            {confirmationModal}
            <Container>
                <CancelSurveyComponent
                    history={history}
                    subscription={subscription && subscription.subscription}
                    onChangeCancelReason={onChangeCancelReason}
                    cancelReason={cancelReason}
                    showTextArea={showTextArea}
                    userCancelReason={userCancelReason}
                    onChangeWriteOption={onChangeWriteOption}
                    goToConfirmCancelation={goToConfirmCancelation}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    cancelSubscription: (cancelData) => dispatch(cancelSubscription(cancelData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(CancelSurvey));
