import * as actions from "./action-types";

export const initialState = {
    userData: null,
    subscriptions: null,
    cancelData: {
        noMoreBoxes: false,
        reason: "",
        otherReason: "",
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.LOAD_STORAGED_USER:
            return {
                ...state,
                userData: action.payload,
            };
        case actions.SAVE_USER_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.payload,
            };
        case actions.SAVE_CANCEL_DATA:
            return {
                ...state,
                cancelData: action.payload,
            };
        default:
            return state;
    }
}
