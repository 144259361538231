import React from "react";
import { Image, Modal } from "semantic-ui-react";
import ErrorImage from "../../images/gifs/error-404.gif";
import "../../scss/components/_modal.scss";
import { DefaultButton } from "../buttons/DefaultButton";

export function ErrorModal(props) {
    const { message, open, closeFunction, specialFunction } = props;

    return (
        <Modal className="error-modal" closeIcon open={open} size="small" onClose={closeFunction}>
            <Modal.Content>
                <Image src={ErrorImage} size="small" centered />

                <p>{message}</p>
            </Modal.Content>

            {specialFunction && (
                <Modal.Actions>
                    <div className="action-buttons-container">
                        <div className="action-buttons-content">
                            <DefaultButton title="Back to Grandbox?" buttonAction={closeFunction} inverted={true} />
                        </div>
                        <div className="action-buttons-content">
                            <DefaultButton title="Already have an account?" buttonAction={specialFunction} />
                        </div>
                    </div>
                </Modal.Actions>
            )}
        </Modal>
    );
}
