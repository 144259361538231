import React from "react";

export const DONATION_CONFIRMATION =
    "We'll really sorry your loss, but you can still give a little love to another people. You would like to donate your remaining boxes?";
export const DISCOUNT_10_OFF =
    "We'll really appreciate your confidence, in your next billing you will receive a 10% off to continue enjoy your Grandbox.";
export const SKIP_NOTE = "Are you sure that do you want to skip your note this month?";
export const SKIP_PHOTOS = "Are you sure that do you want to skip your photos this month?";
export const NO_UPLOAD_MORE_PHOTOS = "Are you sure that you don't want to upload more photos this month?";
export const CANCEL_SUBSCRIPTION =
    "Are you sure that you want to cancel your subscription (This action don't have undo)?";
export const RECIEVE_MARKETING_EMAIL = "I wish to receive updated news and information about GrandBox.";
export const UPLOD_PHOTOS_CONFIRMATION = (
    <>
        Are you sure that you want upload photos? <br />
        <strong>Check if your images are correct, this action not has undo.</strong>
    </>
);
export const UPLOD_NOTE_CONFIRMATION = (
    <>
        Are you sure that you want upload this note? <br />
        <strong>Check if your note is correct, this action not has undo.</strong>
    </>
);
export const PAUSE_SUBSCRIPTION = (pauseTime, nextRenewalDate) =>
    `Are you sure that you want to pause Subscription Renewal for ${pauseTime} days (your next renewal date will be ${nextRenewalDate})?`;
