import React, { useEffect, useState, useReducer } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container, Grid, Accordion, Header } from "semantic-ui-react";
import { BackButton, ConfirmationModal } from "../../components";
import ShippingAddress from "../../components/screenComponents/subscribe/stepSix/ShippingAddress";
import StepSixComponent from "../../components/screenComponents/subscribe/stepSix/StepSixComponent";
import OrderSummaryComponent from "../../components/screenComponents/subscribe/stepSix/OrderSummaryComponent";
import { getLastPaymentMethod, getUserSubscriptions, getInvoiceHostedUrl } from "../../services/SetupService";
import { getOrdersSummaryData } from "../../services/SubscriptionPlanService";
import { mobileCheck } from "../../services/DataValidationService";
import { saveStepFour, saveStepFive, saveDiscountCoupon } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/pages/get_a_box.scss";

function StepSix(props) {
    const { subscriptions, history, saveStepFive, saveStepFour, plans, saveDiscountCoupon, userData, business } = props;
    const { OTGPlans, OTGUpgrades } = business;
    const {
        subscriptionPlan,
        upgradeBoxes,
        grandmaData,
        grandpaData,
        couponData,
        giftType,
        oneTimeGift,
        relationship,
    } = subscriptions;

    const [paymentMethod, setPaymentMethod] = useState("");
    const [ordersSummaryData, setOrdersSummaryData] = useState([]);
    const [processPayment, setProcessPayment] = useState(null);
    const [loadingSecondaryButton, setLoadingSecondaryButton] = useState(false);
    const [lastPaymentMethod, setLastPaymentMethod] = useState(null);
    const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false);
    const [showCreditCardForm, setShowCreditCardForm] = useState(false);
    const [accordionActiveIndex, setAccordionActiveIndex] = useState(0);
    const [blockedUpgrade, setBlockedUpgrade] = useState();
    const [previousIncompleteSubscription, setPreviousIncompleteSubscription] = useState(false);
    const [showOrderSummary, setShowOrderSummary] = useState(true);

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        localFirstName: "",
        localLastName: "",
        localPostalCode: "",
        localFirstNameError: false,
        localLastNameError: false,
        localPostalCodeError: false,
        subscribeNewsLetter: true,
    });

    const [cardElementsState, setCardElementsStates] = useReducer((state, newState) => ({ ...state, ...newState }), {
        cardCvcEmpty: true,
        cardExpiryEmpty: true,
        cardNumberEmpty: true,
        cardNumberEmptyError: "",
        cardExpiryEmptyError: "",
        cardCvcEmptyError: "",
    });

    useEffect(() => {
        const isMobile = mobileCheck();

        if (isMobile && accordionActiveIndex === 0) {
            setShowOrderSummary(false);
        } else {
            setShowOrderSummary(true);
        }
    }, [accordionActiveIndex]);

    useEffect(() => {
        const getSubscriptionsList = async () => {
            const userSubscriptions = await getUserSubscriptions();

            if (userSubscriptions) {
                if (userSubscriptions.length > 0) {
                    const incomppleteSubscription = userSubscriptions.find(
                        (subscription) => subscription.status === CONSTANTS.SUBSCRIPTION_STATUS.INCOMPLETE
                    );

                    if (incomppleteSubscription) {
                        const link = await getInvoiceHostedUrl(incomppleteSubscription.sessionId);

                        if (link) {
                            const incompleteMessage = {
                                message: `url_incomplete&&${incomppleteSubscription.parents
                                    .map((parent) => `${parent.parentType.toLowerCase()} ${parent.name}`)
                                    .join(" & ")}&&${incomppleteSubscription.sessionId}`,
                                link: link,
                            };
                            setPreviousIncompleteSubscription(incompleteMessage);
                        }
                    }
                }
            }
        };

        getSubscriptionsList();
    }, []);

    useEffect(() => {
        if (subscriptionPlan.length > 0) {
            const plansToPay = subscriptionPlan.concat(upgradeBoxes);

            const ordersSummaryData = getOrdersSummaryData(plansToPay, grandmaData, grandpaData);

            setOrdersSummaryData(ordersSummaryData);
        }
    }, [subscriptionPlan, upgradeBoxes, grandmaData, grandpaData]);

    useEffect(() => {
        setLoadingPaymentMethod(true);
        const getLastPayment = async () => {
            try {
                const lastPaymentMethod = await getLastPaymentMethod({
                    userId: userData.user_id,
                });
                if (lastPaymentMethod) {
                    if (typeof lastPaymentMethod !== "string") {
                        setLoadingPaymentMethod(false);
                        setShowCreditCardForm(false);
                        setLastPaymentMethod(lastPaymentMethod);
                        setPaymentMethod("lastPayment");
                    } else {
                        setShowCreditCardForm(true);
                        setLoadingPaymentMethod(false);
                        setPaymentMethod("anotherCard");
                    }
                } else {
                    setShowCreditCardForm(true);
                    setLoadingPaymentMethod(false);
                    setPaymentMethod("anotherCard");
                }
            } catch (error) {
                setShowCreditCardForm(true);
                setLoadingPaymentMethod(false);
                setPaymentMethod("anotherCard");
            }
        };

        if (userData && userData.user_id) {
            getLastPayment();
        }
    }, [userData]);

    const onChangePaymentMethod = (event) => {
        const paymentMethod = event.target.id;
        setPaymentMethod(paymentMethod);

        if (paymentMethod === "anotherCard") {
            setShowCreditCardForm(true);
        } else {
            setShowCreditCardForm(false);
        }
    };

    const onChangeWriteOptions = (event, { value }) => {
        const inputId = event.target.id;

        console.log(inputId);
        setStateForm({
            [inputId]: value,
            [`${inputId}Error`]: false,
        });
    };

    const onChangeCardNumber = (event) => {
        const cardElement = event.elementType;

        if (event.complete) {
            setCardElementsStates({
                [`${cardElement}Empty`]: event.empty,
                [`${cardElement}EmptyError`]: false,
            });
        } else {
            setCardElementsStates({
                [`${cardElement}Empty`]: event.empty,
                [`${cardElement}EmptyError`]: event.error && event.error.message,
            });
        }
    };

    const removeUpgrade = (selection) => {
        const upgradeRemoved = selection.priceId;

        const newUpgradeBoxes = upgradeBoxes.filter((upgrade) => upgrade.id !== upgradeRemoved);

        saveStepFive(
            {
                upgradeBoxes: newUpgradeBoxes,
            },
            true
        );

        saveDiscountCoupon(null)
    };

    const removeSubscription = (isJoin) => {
        saveStepFour(
            {
                subscriptionPlan: [],
            },
            true
        );

        if (typeof isJoin === "boolean") {
            saveStepFive(
                {
                    upgradeBoxes: [],
                },
                true
            );

            history.replace(ROUTES.STEP_FOUR, {
                isJoiningUser: true,
            });
        } else {
            if (giftType) {
                processOneTimeGift();
            } else {
                history.replace(ROUTES.STEP_FOUR);
            }
        }
    };

    const setAutoRenewing = (selection) => {
        if (selection.type === CONSTANTS.BOX_TYPES.SUBSCRIPTION) {
            setPlanAutoRenewing(selection);
        } else {
            setUpgradeAutoRenewing(selection);
        }
    };

    const setPlanAutoRenewing = (selection) => {
        let subscriptionPlanData = plans.find(
            (plan) =>
                plan.metadata.tradename === selection.detail &&
                plan.metadata.priceType === selection.priceType &&
                plan.type === selection.billingType &&
                plan.metadata.grandboxType === CONSTANTS.BOX_TYPES.SUBSCRIPTION
        );

        subscriptionPlanData.autoRenewing = !selection.autoRenewing;

        const newSubscriptionPlan = [subscriptionPlanData];

        if (newSubscriptionPlan[0]) {
            setAllUpgradeAutoRenewing(newSubscriptionPlan[0].autoRenewing);
        }

        if (!subscriptionPlanData.autoRenewing && subscriptionPlanData.metadata.packageQty === "1") {
            const blockedUpgrade = ordersSummaryData.find(
                (order) => order.type === CONSTANTS.BOX_TYPES.UPGRADE && order.upgradeMonthToMonthBlocked
            );

            if (blockedUpgrade) {
                setBlockedUpgrade(blockedUpgrade);
            }
        } else {
            setBlockedUpgrade(null);
        }

        saveStepFour(
            {
                subscriptionPlan: newSubscriptionPlan,
            },
            true
        );
    };

    const setUpgradeAutoRenewing = (selection) => {
        if (subscriptionPlan[0].autoRenewing) {
            const newUpgradeBoxes = upgradeBoxes.map((upgrade) => {
                if (selection.priceId === upgrade.id) {
                    return {
                        ...upgrade,
                        autoRenewing: !selection.autoRenewing,
                    };
                } else {
                    return upgrade;
                }
            });

            saveStepFive(
                {
                    upgradeBoxes: newUpgradeBoxes,
                },
                true
            );
        }
    };

    const setAllUpgradeAutoRenewing = (autoRenewing) => {
        const newUpgradeBoxes = upgradeBoxes.map((upgrade) => {
            return {
                ...upgrade,
                autoRenewing: autoRenewing,
            };
        });

        saveStepFive(
            {
                upgradeBoxes: newUpgradeBoxes,
            },
            true
        );
    };

    const saveAndPay = () => {
        setProcessPayment(true);
    };

    const resetFormValidation = () => {
        setProcessPayment(null);
    };

    const handleLoadingSecondaryButton = (state) => {
        setLoadingSecondaryButton(state);
    };

    const processOneTimeGift = () => {
        let OTGPlan = null;

        if (giftType === "christmas") {
            if (relationship === CONSTANTS.GRANDPARENTS) {
                OTGPlan = OTGPlans.filter((plan) => plan.metadata.couple);
            } else {
                OTGPlan = OTGPlans.filter((plan) => plan.metadata.single);
            }
        }

        const otg =
            OTGUpgrades &&
            OTGUpgrades.find((upgrade) => {
                if (giftType === "birthday") {
                    if (grandmaData.hasOwnProperty("name")) {
                        return upgrade.metadata.upgradeType.includes("birthdayGrandma");
                    } else {
                        return upgrade.metadata.upgradeType.includes("birthdayGrandpa");
                    }
                } else {
                    return upgrade.metadata.upgradeType.includes(giftType);
                }
            });

        if (OTGPlan) {
            saveStepFour({
                subscriptionPlan: OTGPlan,
            });

            saveStepFive({
                upgradeBoxes: [otg],
                oneTimeGift: true,
            });
        }
    };

    const handleTitleClick = (event, itemProps) => {
        const { index } = itemProps;

        window.scrollTo(0, 0);
        if (accordionActiveIndex === 0) {
            if (index === -1) {
                setAccordionActiveIndex(1);
            }
        } else {
            if (index === 0) {
                setAccordionActiveIndex(0);
            }
        }
    };

    const handleSubscribeNewsLetter = () => {
        setStateForm({
            subscribeNewsLetter: !formState.subscribeNewsLetter,
        });
    };

    const getAccordionPanels = () => {
        let parentsString = "";
        let parentsNames = [];

        const parents = [];
        if (grandmaData.hasOwnProperty("birthday")) {
            parents.push(grandmaData);
        }
        if (grandpaData.hasOwnProperty("birthday")) {
            parents.push(grandpaData);
        }

        if (parents.length > 0) {
            parentsNames = parents.map((parent) => {
                return parent.name;
            });
        }

        parentsString = parentsNames.join(" or ");

        const panels = [
            {
                key: 0,
                title: {
                    content: <Header size="large" textAlign="center" content={`${parentsString}'s Shipping Details`} />,
                },
                content: {
                    content: (
                        <ShippingAddress
                            type="subscription"
                            parentsString={parentsString}
                            handleTitleClick={handleTitleClick}
                        />
                    ),
                },
            },
            {
                key: 1,
                title: {
                    content: accordionActiveIndex === 1 && (
                        <Header size="large" textAlign="center" content="Payment Method" />
                    ),
                },
                content: {
                    content: (
                        <StepSixComponent
                            paymentMethod={paymentMethod}
                            ordersSummaryData={ordersSummaryData}
                            cardElementsState={cardElementsState}
                            formState={formState}
                            subscriptions={subscriptions}
                            saveAndPay={saveAndPay}
                            onChangeWriteOptions={onChangeWriteOptions}
                            onChangePaymentMethod={onChangePaymentMethod}
                            onChangeCardNumber={onChangeCardNumber}
                            history={history}
                            processPayment={processPayment}
                            resetFormValidation={resetFormValidation}
                            handleLoadingSecondaryButton={handleLoadingSecondaryButton}
                            loadingPaymentMethod={loadingPaymentMethod}
                            lastPaymentMethod={lastPaymentMethod}
                            showCreditCardForm={showCreditCardForm}
                            previousIncompleteSubscription={previousIncompleteSubscription}
                        />
                    ),
                },
            },
        ];

        return panels;
    };

    let confirmationModal = null;

    if (blockedUpgrade) {
        const openConfirmationModal = Object.keys(blockedUpgrade).length > 0;
        const message = (
            <>
                <span>
                    Your selected <strong className="color-cta">${blockedUpgrade.name}</strong> upgrade will not
                    available because your month to month subscription is not autorenewing and this upgrade is not from
                    the same month of your subscription.
                </span>
                <br />
                <br />
                <span>Do you want continue cancel autorenewing?</span>
            </>
        );

        const subscriptionOrder = ordersSummaryData.find((order) => order.type === CONSTANTS.BOX_TYPES.SUBSCRIPTION);

        confirmationModal = (
            <ConfirmationModal
                open={openConfirmationModal}
                message={message}
                confirmAction={() => {
                    removeUpgrade(blockedUpgrade);
                    setBlockedUpgrade(null);
                }}
                closeAction={() => setAutoRenewing(subscriptionOrder)}
            />
        );
    }

    return (
        <>
            {confirmationModal}
            <Container className="payment-container">
                <Grid className="payment-grid" centered reversed="computer">
                    <Grid.Row>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <section className="step-six-form padding-page">
                                <Accordion
                                    activeIndex={accordionActiveIndex}
                                    panels={getAccordionPanels()}
                                    onTitleClick={handleTitleClick}
                                />
                                <div className="show-computer">
                                    <BackButton title="Back"/>
                                </div>
                            </section>
                        </Grid.Column>
                        {showOrderSummary && (
                            <Grid.Column computer={8} tablet={8} mobile={16}>
                                <OrderSummaryComponent
                                    ordersSummaryData={ordersSummaryData}
                                    saveAndPay={saveAndPay}
                                    removeUpgrade={removeUpgrade}
                                    removeSubscription={removeSubscription}
                                    setAutoRenewing={setAutoRenewing}
                                    history={history}
                                    loadingSecondaryButton={loadingSecondaryButton}
                                    saveDiscountCoupon={saveDiscountCoupon}
                                    couponData={couponData}
                                    giftType={giftType}
                                    oneTimeGift={oneTimeGift}
                                    formState={formState}
                                    cardElementsState={cardElementsState}
                                    showCreditCardForm={showCreditCardForm}
                                    handleSubscribeNewsLetter={handleSubscribeNewsLetter}
                                />
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveStepFive: (stepFive, isSet) => dispatch(saveStepFive(stepFive, isSet)),
    saveStepFour: (stepFour, isSet) => dispatch(saveStepFour(stepFour, isSet)),
    saveDiscountCoupon: (couponData) => dispatch(saveDiscountCoupon(couponData)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        plans: state.business.plans,
        userData: state.user.userData,
        business: state.business,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepSix));
