import * as actions from './action-types';

export const initialState = {
	upgrades: [],
	plans: []
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actions.LOAD_PRODUCTS_LIST:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
}
