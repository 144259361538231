import { applyMiddleware, compose, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import todoApp from './reducers';
import * as CONSTANT from '../constants/Global';

const logger = createLogger({
	collapsed: true,
	timestamp: true,
	duration: true
});

function configureStore(initialState) {
	let store = {};
	if (process.env.NODE_ENV === CONSTANT.ENV_DEVELOPMENT) {
		store = createStore(todoApp, initialState, compose(applyMiddleware(thunk, logger)));
	} else {
		store = createStore(todoApp, initialState, compose(applyMiddleware(thunk)));
	}

	return store;
}

export default configureStore;
