import React from "react";
import { Button } from "semantic-ui-react";
import "../../scss/components/_button.scss";

export function OutlineButton(props) {
    const { buttonAction, title, disabled, loading } = props;

    return (
        <Button className="outline-button" onClick={buttonAction} disabled={disabled} loading={loading} fluid>
            {title}
        </Button>
    );
}
