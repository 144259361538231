import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import SuccessfulComponent from "../../components/screenComponents/subscribe/SuccessfulComponent";
import { resetSubscriptionStore } from "../../store/subscriptions/actions";
import * as ROUTES from "../../navigation/Routes";

import "../../scss/pages/get_a_box.scss";

function Successful(props) {
    const { history, location, resetSubscriptionStore } = props;

    const subscriptionId = location.state;

    useEffect(() => {
        resetSubscriptionStore();
    }, [resetSubscriptionStore]);

    const goToManageSubscriptions = (goToCurrent) => {
        if (goToCurrent) {
            history.replace(ROUTES.SUBSCRIPTION_SUMMARY, { subscriptionId: subscriptionId });
        } else {
            history.replace(ROUTES.SUBSCRIPTION_SUMMARY);
        }
    };

    return (
        <>
            <Container className="payment-container">
                <SuccessfulComponent goToManageSubscriptions={goToManageSubscriptions} />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    resetSubscriptionStore: () => dispatch(resetSubscriptionStore()),
});

export default compose(connect(null, mapDispatchToProps))(withRouter(Successful));
