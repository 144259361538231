const API_URL = process.env.REACT_APP_API_URL;

const API_KEY = ``;
const APP_ID = ``;

export const getAppId = () => {
    return APP_ID;
};

export const getApiUrl = () => {
    return API_URL;
};

export const getApiKey = () => {
    return API_KEY;
};
