import React, { useReducer, useEffect } from "react";
import { Label } from "semantic-ui-react";
import moment from "moment";
import "../../scss/components/_calendar.scss";

export function CalendarDropdown(props) {
    const { id, onChangeDateAction, value } = props;

    const [date, setDate] = useReducer((state, newState) => ({ ...state, ...newState }), {
        selectedDay: moment().format("DD"),
        selectedMonth: moment().format("MM"),
        selectedYear: moment().format("YYYY"),
        daySelected: false,
        monthSelected: false,
        yearSelected: false,
    });

    useEffect(() => {
        if (value) {
            setDate({
                selectedDay: moment(value).format("DD"),
                selectedMonth: moment(value).format("MM"),
                selectedYear: moment(value).format("YYYY"),
                daySelected: true,
                monthSelected: true,
                yearSelected: true,
            });
        }
    }, [value]);

    const onChangeDate = (event) => {
        const dateId = event.target.id;
        const value = event.target.value;
        let inputSelected = "";

        switch (dateId) {
            case "selectedDay":
                inputSelected = "daySelected";
                break;
            case "selectedMonth":
                inputSelected = "monthSelected";
                break;
            case "selectedYear":
                inputSelected = "yearSelected";
                break;
            default:
                break;
        }

        setDate({
            [dateId]: value,
            [inputSelected]: true,
        });

        let day = dateId === "selectedDay" ? value : date.selectedDay;
        let month = dateId === "selectedMonth" ? value : date.selectedMonth;
        let year = dateId === "selectedYear" ? value : date.selectedYear;

        const selectedDate = {
            id: id,
            date: `${month}/${day}/${year}`,
        };

        onChangeDateAction(selectedDate);
    };

    const getYear = () => {
        const years = [];
        const initialYear = 1900;

        for (let i = Number(moment().format("YYYY")); i > initialYear; i--) {
            const year = {
                name: String(i),
                value: String(i),
            };

            years.push(year);
        }

        return years;
    };

    const getYearMonths = () => {
        const yearMonths = [
            {
                name: "January",
                value: "01",
            },
            {
                name: "February",
                value: "02",
            },
            {
                name: "March",
                value: "03",
            },
            {
                name: "April",
                value: "04",
            },
            {
                name: "May",
                value: "05",
            },
            {
                name: "June",
                value: "06",
            },
            {
                name: "July",
                value: "07",
            },
            {
                name: "August",
                value: "08",
            },
            {
                name: "September",
                value: "09",
            },
            {
                name: "October",
                value: "10",
            },
            {
                name: "November",
                value: "11",
            },
            {
                name: "December",
                value: "12",
            },
        ];

        return yearMonths;
    };

    const getMonthDays = () => {
        const monthDays = moment([date.selectedYear, date.selectedMonth], "YYYY-MM").daysInMonth();

        const days = [];

        for (let i = 1; i <= monthDays; i++) {
            let dayText = `${i}`;
            if (i < 10) dayText = `0${i}`;

            const day = {
                name: dayText,
                value: dayText,
            };

            days.push(day);
        }

        return days;
    };

    const years = getYear();
    const yearMonths = getYearMonths();
    const monthDays = getMonthDays();

    return (
        <div className="input-content-calendar">
            <div className="select-container">
                <div className="item-container">
                    <Label htmlFor="selectedMonth" floating className="custom-floating label-position">
                        Month
                    </Label>
                    <select
                        id="selectedMonth"
                        className={date.monthSelected ? "select-item normal selected" : "select-item normal"}
                        onChange={onChangeDate}
                        value={date.selectedMonth}
                    >
                        {yearMonths.map((month, index) => {
                            return (
                                <option className="select-option" key={index} value={month.value}>
                                    {month.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="item-container small">
                    <Label htmlFor="selectedDay" floating className="custom-floating label-position">
                        Day
                    </Label>
                    <select
                        id="selectedDay"
                        className={date.daySelected ? "select-item small selected" : "select-item small"}
                        onChange={onChangeDate}
                        value={date.selectedDay}
                    >
                        {monthDays.map((day, index) => {
                            return (
                                <option className="select-option" key={index} value={day.value}>
                                    {day.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="item-container small">
                    <Label floating htmlFor="selectedYear" className="label custom-floating label-position">
                        Year
                    </Label>
                    <select
                        id="selectedYear"
                        className={date.yearSelected ? "select-item small selected" : "select-item small"}
                        onChange={onChangeDate}
                        value={date.selectedYear}
                    >
                        {years.map((year, index) => {
                            return (
                                <option className="select-option" key={index} value={year.value}>
                                    {year.name}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </div>
    );
}
