import React from "react";
import { Checkbox } from "semantic-ui-react";
import "../../scss/components/_input.scss";

export function CustomCheckbox(props) {
    const { id, label, onChangeAction, value, inverted, isBigSize, noMargin } = props;

    let checkboxClasses = "checkbox-content";

    if (inverted) {
        checkboxClasses += " inverted";
    }

    if (isBigSize) {
        checkboxClasses += " bigSize";
    }

    if (noMargin) {
        checkboxClasses += " no-margin";
    }
    return (
        <div className={checkboxClasses}>
            <Checkbox id={id} label={label} onChange={onChangeAction} checked={value} />
        </div>
    );
}
