import React from "react";
import { Button } from "semantic-ui-react";
import "../../scss/components/_button.scss";

export function DefaultButton(props) {
    const { buttonAction, title, disabled, dark, orange, loading, inverted, hidden, fixed, tenPercent } = props;
    let className = "default-button";

    if (fixed) {
        className += " width-fixed";
    }

    if (tenPercent) {
        className += " width-ten-percent";
    }

    if (dark) {
        className += " dark";
    }

    if (inverted) {
        className += " inverted";
    }

    if (orange) {
        className += " bg-orange $color-cards";
    }

    if (hidden) {
        className += " hidden";
    }

    return (
        <Button className={className} onClick={buttonAction} disabled={disabled} loading={loading} fluid>
            {title}
        </Button>
    );
}
