import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup, signOut, updateProfile, sendPasswordResetEmail } from "firebase/auth";
import { setCookie } from "./CookieService";
import * as LOCALSTORAGE from "../constants/LocalStorage";
import * as CONSTANTS from "../constants/Global";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKED,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class FirebaseService {
    constructor() {
        this.app = initializeApp(firebaseConfig);
        this.auth = getAuth(this.app);
        this.storage = getStorage(this.app)
        this.getCurrentUser();
    }

    getCurrentUser = async () => {
        onAuthStateChanged(this.auth, (user) => {
            user &&
                user
                    .getIdToken()
                    .then((data) => {
                        setCookie(LOCALSTORAGE.USER_DATA, data);
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });
        });
    };

    doSendEmailVerification = async () => {
        onAuthStateChanged(this.auth, (user) => {
            if (user) {
                user.sendEmailVerification();
            }
        });
    };

    doSignOut = async () => {
        return new Promise(async (resolve, reject) => {
           signOut(this.auth)
                .then(function () {
                    resolve("successfull");
                })
                .catch(function (error) {
                    reject(error.message);
                });
        });
    };

    doCreateUserWithEmailAndPassword = async (email, password, fullName) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { user } = await createUserWithEmailAndPassword(this.auth, email, password);

                await updateProfile(user, {
                    displayName: fullName,
                });

                if (user) {
                    this.getCurrentUser();
                }
                return resolve(user);
            } catch (error) {
                return reject(error);
            }
        });
    };

    doSignInWithEmailAndPassword = async (email, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await signInWithEmailAndPassword(this.auth, email, password);
                if (response) {
                    this.getCurrentUser();
                }
                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    doFacebookSignInWithPopup = async () => {
        return new Promise(async (resolve, reject) => {
            const provider = new FacebookAuthProvider();
            try {
                const response = await signInWithPopup(this.auth, provider);
                if (response) {
                    this.getCurrentUser();
                }
                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    doGoogleSignIn = async () => {
        return new Promise(async (resolve, reject) => {
            const provider = new GoogleAuthProvider();

            try {
                const response = await signInWithPopup(this.auth, provider);
                if (response) {
                    this.getCurrentUser();
                }

                return resolve(response);
            } catch (error) {
                return reject(error);
            }
        });
    };

    getIdToken = async () => {
        const currentUser = this.auth.currentUser;
        let idToken = null;
        if (currentUser) {
            idToken = await currentUser.getIdToken(true);
            process.env.NODE_ENV === CONSTANTS.ENV_DEVELOPMENT && console.log("1", idToken);
            setCookie(LOCALSTORAGE.USER_DATA, idToken);

            return idToken;
        } else {
            return null;
        }
    };

    uploadFiles = async (data) => {
        const filesSaved = [];
        let errorUploading = null;
        const files = data.files.filter((file) => file.hasOwnProperty("base64"));

        for (const file of files) {
            try {
                const imgPath =  `grandbox/${data.ownerId}/${data.boxId}/${file.name}`
                const childRef = ref(this.storage, imgPath);
                const uploadTask = await uploadString(childRef, file.encodeString, 'base64', file.metadata)
                if (uploadTask.metadata.md5Hash) {
                    const url = await getDownloadURL(childRef);
                    filesSaved.push(url);
                }
            } catch (error) {
                errorUploading = error;
            }
        }

        const uploadResponse = {
            filesSaved: filesSaved,
            error: errorUploading,
        };
        return uploadResponse;
    };

    resetPassword = async (email) => {
        return new Promise(async (resolve, reject) => {
            try {
                const sentPasswordResetEmail = await sendPasswordResetEmail(this.auth, email)
                console.log("sentPasswordResetEmail", sentPasswordResetEmail)
                return resolve(sentPasswordResetEmail);
            } catch (error) {
                return reject(error);
            }
        });
    }
}

export default FirebaseService;