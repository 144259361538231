import React from "react";
import "../../scss/components/_input.scss";

export function CustomFileInput(props) {
    const { id, label, onChangeAction } = props;

    return (
        <div className="input-content">
            <div className="ui label">
                <label className="file" htmlFor={id}>
                    <i aria-hidden="true" className="paperclip icon large"></i>
                    {label}
                </label>
            </div>
            <input type="file" id={id} accept="application/pdf" style={{ display: "none" }} onChange={onChangeAction} />
        </div>
    );
}
