import React, { useRef } from "react";
import { SubHeader, DefaultButton, BackButton, DropdownCheckbox } from "../..";
import * as CONSTANTS from "../../../constants/Global";

function StepTwoComponent(props) {
    const { state, subscriptions, saveStepThreeAndNext, onChangeDietaryAction } = props;
    const { grandmaData, grandpaData, relationship } = subscriptions;
    const { grandpaDiet, grandmaDiet } = state;
    const dropDownRef = useRef(null);
    const grandpaName = grandpaData.name;
    const grandmaName = grandmaData.name;

    let dropdownsToShow = null;

    if (relationship === CONSTANTS.GRANDMA) {
        dropdownsToShow = (
            <>
                <p className="ui header subtitle">Does {grandmaName} has a dietary restriction?</p>
                <DropdownCheckbox
                    reference={dropDownRef}
                    id="grandmaDiet"
                    options={CONSTANTS.DIETARY_OPTIONS}
                    placeholder="Select a Restriction"
                    onChangeAction={onChangeDietaryAction}
                    value={grandmaDiet}
                    destroyMultipleWith="UNRESTRICTED"
                />
            </>
        );
    }

    if (relationship === CONSTANTS.GRANDPA) {
        dropdownsToShow = (
            <>
                <p className="ui header subtitle">Does {grandpaName} has a dietary restriction?</p>
                <DropdownCheckbox
                    reference={dropDownRef}
                    id="grandpaDiet"
                    options={CONSTANTS.DIETARY_OPTIONS}
                    placeholder="Select Restriction"
                    onChangeAction={onChangeDietaryAction}
                    value={grandpaDiet}
                    destroyMultipleWith="UNRESTRICTED"
                />
            </>
        );
    }

    if (relationship === CONSTANTS.GRANDPARENTS) {
        dropdownsToShow = (
            <>
                <p className="ui header subtitle">Does {grandpaName} has a dietary restriction?</p>
                <DropdownCheckbox
                    id="grandpaDiet"
                    options={CONSTANTS.DIETARY_OPTIONS}
                    placeholder="Select Restriction"
                    onChangeAction={onChangeDietaryAction}
                    value={grandpaDiet}
                    destroyMultipleWith="UNRESTRICTED"
                />
                <p className="ui header subtitle">Does {grandmaName} has a dietary restriction?</p>
                <DropdownCheckbox
                    id="grandmaDiet"
                    options={CONSTANTS.DIETARY_OPTIONS}
                    placeholder="Select Restriction"
                    onChangeAction={onChangeDietaryAction}
                    value={grandmaDiet}
                    destroyMultipleWith="UNRESTRICTED"
                    isTwoDropdowns={true}
                />
            </>
        );
    }

    let nextStepDisdabled = true;
    let dietaryRestrictionsText = "";
    if (relationship === CONSTANTS.GRANDPARENTS) {
        dietaryRestrictionsText = `Dietary Restrictions`;

        if (grandpaDiet.length > 0 && grandmaDiet.length > 0) {
            nextStepDisdabled = false;
        }
    } else {
        if (relationship === CONSTANTS.GRANDMA) {
            dietaryRestrictionsText = `Dietary Restrictions`;

            if (grandmaDiet.length > 0) {
                nextStepDisdabled = false;
            }
        } else {
            dietaryRestrictionsText = `Dietary Restrictions`;

            if (grandpaDiet.length > 0) {
                nextStepDisdabled = false;
            }
        }
    }

    return (
        <section className="padding-page">
            <SubHeader mainTitle={dietaryRestrictionsText} hasDivider={true} />
            <br />
            <div className="input-container container-padding">
                {dropdownsToShow}

                <div className="margin-top">
                    <DefaultButton title="NEXT STEP" disabled={nextStepDisdabled} buttonAction={saveStepThreeAndNext} />
                    <br />
                    <BackButton title="Back" />
                </div>
            </div>
        </section>
    );
}

export default StepTwoComponent;
