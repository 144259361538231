import moment from "moment";
import { getShippingDateRanges } from "./UpgradeBoxService";

export const getDeliverDate = (deliverDate, upgrades) => {
    let estimatedDelivery = "";
    const format = "MMM D[th] YYYY";

    if (upgrades.length > 0) {
        const birthdayData = upgrades.find((upgrade) => upgrade.upgradeType.includes("birthday"));
        const isHanukkah = upgrades[0].upgradeType === "hanukkah";
        let shippingDateRanges = null;

        if (birthdayData) {
            shippingDateRanges = getShippingDateRanges(moment(birthdayData.deliverDate).add(1, "day"));
        } else {
            shippingDateRanges = getShippingDateRanges(moment(deliverDate).add(1, "day"), isHanukkah);
        }

        const below = moment(shippingDateRanges.below).format("MMM D[th]");
        const above = moment(shippingDateRanges.above).format(format);

        estimatedDelivery = `${below} to ${above}`;
    } else {
        estimatedDelivery = moment(deliverDate).add(1, "day").format(format);
    }

    return estimatedDelivery;
};
