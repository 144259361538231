import React from 'react';
import { Image } from 'semantic-ui-react';
import Loading from '../images/gifs/loading.gif';
import '../scss/components/_loader.scss';

export function Loader(props) {
	const { message } = props;

	let loadingText = 'Loading...';
	if (message) {
		loadingText = message;
	}

	return (
		<div className="loader flex-center flex-column margin-bottom margin-top">
			<Image src={Loading} size="small" />
			<p>{loadingText}.</p>
		</div>
	);
}
