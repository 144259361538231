import React from "react";
import { Divider } from "semantic-ui-react";
import {
    SubHeader,
    DefaultButton,
    LinkButton,
    CustomInput,
    CustomPasswordInput,
    SocialButton,
    BackButton,
    ErrorModal,
} from "..";
import { GRANDBOX_URLS } from "../../navigation/Routes";

function SignUpComponent(props) {
    const {
        onChangeWriteOptions,
        state,
        prepareSignUpEmailAndPassword,
        signUpUserControl,
        goToLogin,
        removeErrorMessage,
    } = props;

    let errorMessage = null;

    if (state.errorMessage.length > 0) {
        errorMessage = (
            <ErrorModal
                open={state.errorMessage.length > 0}
                message={state.errorMessage}
                closeFunction={removeErrorMessage}
            />
        );
    }

    return (
        <section className="padding-page">
            {errorMessage}
            <SubHeader mainTitle="You are almost there create your account" />
            <br />
            <div className="flex-center flex-row container-padding">
                <div style={{ width: "40vh" }}>
                    <SocialButton
                        socialNetworkIcon="google"
                        socialNetwork="google"
                        buttonAction={() => signUpUserControl("google")}
                        loading={state.loadingSignInGmail}
                        disabled={state.disableSignUp}
                    />
                </div>
                <br />
                {/* <SocialButton
                    socialNetworkIcon="facebook f"
                    socialNetwork="facebook"
                    buttonAction={() => signUpUserControl("facebook")}
                    loading={state.loadingSignInFacebook}
                    disabled={state.disableSignUp}
                /> */}
            </div>
            <div className="input-container container-padding">
                <Divider horizontal>Or</Divider>

                <CustomInput
                    id="fullName"
                    placeholder="Full Name"
                    label="Full Name"
                    onChangeAction={onChangeWriteOptions}
                    value={state.fullName}
                    errorMessage={state.fullNameError}
                />
                <CustomInput
                    id="email"
                    placeholder="Email"
                    label="Email"
                    onChangeAction={onChangeWriteOptions}
                    value={state.email}
                    errorMessage={state.emailError}
                />
                <CustomPasswordInput
                    id="password"
                    placeholder="Password"
                    label="Password"
                    onChangeAction={onChangeWriteOptions}
                    value={state.password}
                    errorMessage={state.passwordError}
                />
                <CustomPasswordInput
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    onChangeAction={onChangeWriteOptions}
                    value={state.confirmPassword}
                    errorMessage={state.confirmPasswordError}
                />
                <div className="terms-links-container">
                    By clicking your agree to{" "}
                    <a href={GRANDBOX_URLS.TERMS_OF_USE} target="_blank" rel="noopener noreferrer">
                        The Terms of Use{" "}
                    </a>
                    and{" "}
                    <a href={GRANDBOX_URLS.PRIVACY_POLICITY} target="_blank" rel="noopener noreferrer">
                        Privacy Policiy
                    </a>
                </div>
            </div>
            <div className="padding-bottom flex-center flex-column container-padding">
                <DefaultButton
                    title="Next"
                    buttonAction={prepareSignUpEmailAndPassword}
                    loading={state.loadingSignUp}
                    disabled={state.disableSignUp}
                />
                {!state.disableSignUp && (
                    <>
                        <br />
                        <div className="flex-center flex-column">
                            <LinkButton title="Log in" buttonAction={goToLogin} textBefore="Already have an account?" />
                        </div>
                        <BackButton title="Back" />
                    </>
                )}
            </div>
        </section>
    );
}

export default SignUpComponent;
