import React from "react";
import { SubHeader, DefaultButton, BackButton, CalendarDropdown } from "../..";
import * as CONSTANTS from "../../../constants/Global";
import Birthday from "../../..//images/birthday.svg";
import Anniversary from "../../../images/anniversary.svg";
import "../../../scss/components/_subheader.scss";

function StepTwoComponent(props) {
    const { state, subscriptions, saveStepTwoAndNext, onChangeDateAction } = props;
    const { grandmaBDay, grandpaBDay, anniversary, showAnniversarySection } = state;
    const { grandmaData, grandpaData, relationship } = subscriptions;
    const grandpaName = grandpaData.name;
    const grandmaName = grandmaData.name;

    let inputsToShow = null;
    let textBirthday = null;

    if (relationship === CONSTANTS.GRANDPARENTS) {
        textBirthday = "When Is Their Birthday?";
        inputsToShow = (
            <>
                <p className="ui subtitle">{`${grandpaName}'s birthday?`}</p>
                <CalendarDropdown id="grandpaBDay" onChangeDateAction={onChangeDateAction} value={grandpaBDay} />
                <p className="ui subtitle">{`${grandmaName}'s birthday?`}</p>
                <CalendarDropdown id="grandmaBDay" onChangeDateAction={onChangeDateAction} value={grandmaBDay} />
            </>
        );
    } else {
        if (relationship === CONSTANTS.GRANDMA) {
            textBirthday = `When Is Her Birthday?`;
            inputsToShow = (
                <>
                    <p className="ui subtitle">{`${grandmaName}'s birthday?`}</p>
                    <CalendarDropdown id="grandmaBDay" value={grandmaBDay} onChangeDateAction={onChangeDateAction} />
                </>
            );
        } else {
            textBirthday = `When Is His Birthday?`;
            inputsToShow = (
                <>
                    <p className="ui subtitle">{`${grandpaName}'s birthday?`}</p>
                    <CalendarDropdown id="grandpaBDay" onChangeDateAction={onChangeDateAction} value={grandpaBDay} />
                </>
            );
        }
    }

    const anniversaryInput = (
        <CalendarDropdown id="anniversary" onChangeDateAction={onChangeDateAction} value={anniversary} />
    );

    let nextStepDisdabled = true;

    if (relationship === CONSTANTS.GRANDPARENTS) {
        if (showAnniversarySection) {
            if (anniversary !== "") {
                nextStepDisdabled = false;
            }
        } else {
            if (grandmaBDay !== "" && grandpaBDay !== "") {
                nextStepDisdabled = false;
            }
        }
    } else {
        if (relationship === CONSTANTS.GRANDMA) {
            if (grandmaBDay !== "") {
                nextStepDisdabled = false;
            }
        } else {
            if (grandpaBDay !== "") {
                nextStepDisdabled = false;
            }
        }
    }

    return (
        <section className="padding-page">
            <SubHeader
                mainTitle={showAnniversarySection ? "When is anniversary?" : textBirthday}
                image={showAnniversarySection ? Anniversary : Birthday}
            />

            <div className="input-container container-padding">
                {showAnniversarySection ? anniversaryInput : inputsToShow}

                <div className="margin-top">
                    <DefaultButton title="NEXT STEP" disabled={nextStepDisdabled} buttonAction={saveStepTwoAndNext} />
                    <br />
                    <BackButton title="Back"/>
                </div>
            </div>
        </section>
    );
}

export default StepTwoComponent;
