export const GetPromotionCode = `
query GetPromotionCode($code: String!, $subscriptionType: String!){
    getPromotionCode(code: $code , subscriptionType: $subscriptionType){
    id
    object
    active
    code
    coupon{
        id
        amount_off
        created
        currency
        name
        percent_off
        valid
    }
    created
    }
}`;
