import React from "react";

export function ResponsiveImage(props) {
    const { smallImg, largeImg, altTag, className, role, width } = props;

    let style = width ? { width: `${width}` } : { "": "" };

    return (
        <picture>
            <source media="(max-width: 719px)" srcSet={smallImg} />
            <source media="(min-width: 720px)" srcSet={largeImg} />
            <img src={largeImg} alt={altTag} loading="lazy" className={className} style={style} role={role} />
        </picture>
    );
}
