import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { ErrorModal } from "../../components";
import BoxDetailsComponent from "../../components/screenComponents/account/BoxDetailsComponent";
import { updateDietaryRestrictions } from "../../services/SetupService";
import { updateSubscriptionsStore } from "../../services/UpdateReduxStoreService";
import { saveUserSubscriptions } from "../../store/user/actions";
import * as ERRORS from "../../constants/Errors";
import { DIETARY_OPTIONS } from "../../constants/Global";

import "../../scss/pages/account.scss";

function BoxDetails(props) {
    const { history, location, user, saveUserSubscriptions } = props;

    const parent = location.state.parent;
    const subscriptionId = location.state.subscriptionId;
    const boxName = location.state.boxName;

    const [formState, setStateForm] = useReducer((state, newState) => ({ ...state, ...newState }), {
        relationship: "",
        nickname: "",
        birthday: "",
        dietary: [],
    });
    const [loadingDietary, setLoadingDietary] = useState(false);
    const [showErrorModal, setShowErroModal] = useState(false);

    useEffect(() => {
        const dietary = parent.Dietary.split(", ").map((dietary) => {
            const dietEnum = DIETARY_OPTIONS.find((option) => option.text === dietary)
            return dietEnum.value
        });

        setStateForm({
            relationship: parent.Relationship === "" ? "" : parent.Relationship,
            nickname: parent.Nickname === "" ? "" : parent.Nickname,
            birthday: parent.Birthday === "" ? "" : parent.Birthday,
            dietary: parent.Dietary === "" ? "" : dietary,
        });
    }, [location, parent]);

    const onChangeWriteOptions = (event, { value }) => {
        setStateForm({
            nickname: value,
        });
    };

    const onChangeDateAction = (item) => {
        setStateForm({
            birthday: item,
        });
    };

    const onChangeDietaryAction = (data) => {
        setStateForm({
            dietary: data.value,
        });
    };

    const setDietaryRestrictions = async () => {
        console.log("formState", formState)

        setLoadingDietary(true);
        const parentData = {
            subcriptionId: subscriptionId,
            input: {
                name: formState.nickname,
                birthday: parent.Birthday,
                anniversary: parent.anniversary ? parent.anniversary : "1900/01/01",
                dietaryRestriction: formState.dietary,
                parentType: formState.relationship.toUpperCase(),
            },
        };

        const subscriptionUpdated = await updateDietaryRestrictions(parentData);

        if (subscriptionUpdated) {
            const subscriptions = user.subscriptions;
            setLoadingDietary(false);
            const updated = updateSubscriptionsStore(saveUserSubscriptions, subscriptions, subscriptionUpdated);

            if (updated) {
                history.goBack();
            }
        } else {
            setLoadingDietary(false);
            setShowErroModal(true);
        }
    };

    const closeErrorModal = () => {
        setShowErroModal(false);
    };

    let errorModal = null;

    if (showErrorModal) {
        errorModal = (
            <ErrorModal
                open={showErrorModal}
                message={ERRORS.ERROR_UPLOADIND_PARENTS}
                closeFunction={closeErrorModal}
            />
        );
    }

    return (
        <>
            {errorModal}

            <Container>
                <BoxDetailsComponent
                    history={history}
                    state={formState}
                    onChangeWriteOptions={onChangeWriteOptions}
                    onChangeDateAction={onChangeDateAction}
                    onChangeDietaryAction={onChangeDietaryAction}
                    setDietaryRestrictions={setDietaryRestrictions}
                    loadingDietary={loadingDietary}
                    boxName={boxName}
                />
            </Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveUserSubscriptions: (subscriptionsData) => dispatch(saveUserSubscriptions(subscriptionsData)),
});

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(BoxDetails));
