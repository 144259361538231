import React, { useState } from "react";
import { Image } from "semantic-ui-react";
import {
    BoxSubHeader,
    CustomImageInput,
    DefaultButton,
    LinkButton,
    OutlineButton,
    Error,
    ImagePreviewModal,
} from "../..";
import * as CONSTANTS from "../../../constants/Global";
import * as ERRORS from "../../../constants/Errors";

function AddBoxPhotosComponent(props) {
    const {
        history,
        onChangeFile,
        photosPreview,
        removePhotoPreview,
        loadingUploadPhotos,
        parentsNames,
        handleConfirmationModal,
        photosStatus,
        buttonLoading,
        boxStatus,
        boxMonth,
        photosQuantity,
    } = props;

    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [photoToModal, setPhotoToModal] = useState(false);

    const handlePreviewModal = (photoSrc) => {
        setOpenPreviewModal(!openPreviewModal);
        setPhotoToModal(photoSrc);
    };

    const photosLength = photosPreview.length;
    const buttonDisabled = photosLength === 0;
    const showUploader =
        boxStatus === CONSTANTS.BOX_STATUS.UNSHIPPED &&
        photosPreview.length < photosQuantity &&
        photosStatus === CONSTANTS.FEATURES_STATUS.EMPTY;

    let previewSection = null;

    if (!buttonDisabled) {
        let imagePreviewClasses = "image-preview";

        previewSection = (
            <div className="image-preview-container">
                {photosPreview.map((photo, index) => {
                    let removePhotoPreviewButton = null;
                    let photoSrc = photo;
                    if (photo.hasOwnProperty("base64")) {
                        photoSrc = photo.base64;
                        removePhotoPreviewButton = (
                            <button
                                id={photo.name}
                                onClick={removePhotoPreview}
                                className="floated-button-small"
                                style={{
                                    backgroundColor: "rgba(185, 139, 85, 0.5)",
                                    border: "1px solid #b98b55",
                                    color: "#b98b55",
                                    width: 22,
                                    height: 22,
                                    padding: 0,
                                }}
                            >
                                &#x2715;
                            </button>
                        );
                    }

                    if (!showUploader) {
                        imagePreviewClasses += " big-images";
                    }

                    return (
                        <div key={index} className={imagePreviewClasses}>
                            <Image src={photoSrc} size="small" onClick={() => handlePreviewModal(photoSrc)} />
                            {removePhotoPreviewButton}
                        </div>
                    );
                })}
            </div>
        );
    }

    let photoUploader = null;

    if (showUploader) {
        photoUploader = (
            <>
                <CustomImageInput id="images" onChangeAction={onChangeFile} />
                <p className="add-photos-title photos-quantity">{`${photosLength}/${photosQuantity}`}</p>
            </>
        );
    }

    let actionButtons = null;
    const somePhotoToUpload = photosPreview.some((photo) => photo.hasOwnProperty("base64"));

    let skipOrCompleteButton = null;
    const hasUploadedPhotos = photosPreview.some(
        (photo) => typeof photo === "string" && photo.includes("firebasestorage")
    );

    if (hasUploadedPhotos) {
        skipOrCompleteButton = (
            <OutlineButton
                title="I don't want to upload more photos"
                buttonAction={() => handleConfirmationModal(CONSTANTS.FEATURES_STATUS.FILLED)}
                loading={buttonLoading}
            />
        );
    } else {
        skipOrCompleteButton = (
            <LinkButton
                title="Skip for this month"
                dark={true}
                buttonAction={() => handleConfirmationModal(CONSTANTS.FEATURES_STATUS.SKIPPED)}
                loading={buttonLoading}
            />
        );
    }

    if (showUploader || somePhotoToUpload) {
        actionButtons = (
            <div className="margin-top padding-bottom flex-center flex-column button-container-padding">
                <DefaultButton
                    title="Update photos"
                    disabled={!somePhotoToUpload}
                    loading={loadingUploadPhotos}
                    buttonAction={() => handleConfirmationModal("SAVE")}
                />
                <br />
                {skipOrCompleteButton}
            </div>
        );
    }

    let photoSection = null;

    if (boxStatus === CONSTANTS.BOX_STATUS.UNSHIPPED) {
        photoSection = (
            <>
                {photoUploader}
                {previewSection}
            </>
        );
    } else {
        if (photosLength === 0) {
            photoSection = <Error message={ERRORS.ERROR_SHIPPED_BOX_FEATURES} />;
        } else {
            photoSection = previewSection;
        }
    }

    let imagePreviewModal = null;

    if (openPreviewModal) {
        imagePreviewModal = (
            <ImagePreviewModal open={openPreviewModal} closeFunction={handlePreviewModal} photo={photoToModal} />
        );
    }

    return (
        <section className="padding-page">
            {imagePreviewModal}
            <BoxSubHeader title={`${boxMonth} Box Photos`} boxName={`Photos to ${parentsNames}`} history={history} />
            <div className="container-padding-feature feature-section">{photoSection}</div>
            {actionButtons}
        </section>
    );
}

export default AddBoxPhotosComponent;
