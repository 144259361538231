import React, { useState } from "react";
import moment from "moment";
import { Card, Image } from "semantic-ui-react";
import { truncateString } from "../../services/DataValidationService";
import { getOriginalMonthersDay } from "../../services/UpgradeBoxService";
import * as CONSTANTS from "../../constants/Global";
import "../../scss/components/_boxes.scss";
import "../../scss/components/_cards.scss";
import "../../scss/components/_utilities.scss";
import "../../scss/components/_variables.scss";

export function UpgradeCard(props) {
    const {
        boxUpgrade,
        selectedBox,
        onSelection,
        grandmaName,
        grandpaName,
        goToStepFour,
        plans,
        saveStepFour,
        relationship,
    } = props;
    const { logoEvent, price, availableDate, status, tradename } = boxUpgrade;
    const customPrice = price.unit_amount / 100;
    let boxesCard = "boxes-card";
    let onSelectAction = () => onSelection(boxUpgrade);

    const [showChangePlanButtons, setShowChangePlanButtons] = useState(false);

    const openChangePlanButtons = () => {
        setShowChangePlanButtons(true);
    };

    const closeChangePlanButtons = () => {
        setShowChangePlanButtons(false);
    };

    if (selectedBox) {
        boxesCard += " selected";
    }

    let availablePlan = null;

    if (status === "blocked") {
        const availableWithPlan = boxUpgrade.availableDate.monthDifference;

        availablePlan = plans.find(
            (plan) =>
                plan.metadata.grandboxType === CONSTANTS.BOX_TYPES.SUBSCRIPTION &&
                Number(plan.metadata.packageQty) >= availableWithPlan
        );

        boxesCard += " disabled-box";
        onSelectAction = () => goToStepFour(availableWithPlan);
    }

    const priceType = relationship === CONSTANTS.GRANDPARENTS ? "couple" : "single";
    const suuggestedPlan =
        availablePlan &&
        plans.find(
            (plan) =>
                plan.metadata.tradename === availablePlan.metadata.tradename && plan.metadata.priceType === priceType
        );

    const upgradeSubscriptionPlan = () => {
        const selectedPlan = {
            ...availablePlan,
            autoRenewing: true,
        };

        saveStepFour({
            subscriptionPlan: [selectedPlan],
            oneTimeGift: false,
        });

        closeChangePlanButtons();
        onSelection(boxUpgrade);
    };

    let cardName = tradename;
    let deliveryText = null;

    if (tradename.includes("birthday")) {
        if (availableDate.text1 === "They're different months") {
            deliveryText = (
                <p className="bd-text">
                    First box theme of your subscription correspond the month of your pay day and your selected plan is
                    "1 moth to month", in this case the upgrade can't be for a different month
                </p>
            );
        }

        if (tradename.includes("Grandpa")) {
            cardName = `${truncateString(grandpaName)}'s Birthday`;
        } else {
            cardName = `${truncateString(grandmaName)}'s Birthday`;
        }
    } else {
        if (availableDate.text1 === "They're different months") {
            deliveryText = (
                <p className="bd-text">
                    First box theme of your subscription correspond the month of your pay day and your selected plan is
                    "1 moth to month", in this case the upgrade can't be for a different month
                </p>
            );
        }

        if (relationship === CONSTANTS.GRANDPARENTS) {
            if (tradename.includes("christmas")) {
                cardName = `Couple Christmas`;
            }
        } else {
            cardName = `Christmas`;
        }

        if (tradename.includes("anniversary")) {
            cardName = `Anniversary`;
        }

        if (tradename.includes("fathersDay")) {
            cardName = `Father's Day`;
        }

        if (tradename.includes("mothersDay")) {
            const currentDay = moment();
            const originalMothersDay = getOriginalMonthersDay();
            const isMothersMonth = moment(currentDay).isSameOrAfter(moment(originalMothersDay));

            cardName = `Mother's Day`;

            if (isMothersMonth) {
                cardName = `Mother's Month`;
            }
        }

        if (tradename.includes("hanukkah")) {
            cardName = `Hanukkah`;
        }

        if (tradename.includes("valentine")) {
            cardName = `Valentine's Day`;
        }
    }

    const overlay = (
        <div className="card-overlay" onClick={status === "blocked" ? openChangePlanButtons : onSelectAction}></div>
    );

    const ocationLessTwoDays = moment(Number(availableDate.ocationDate)).subtract(2, "days");
    const ocationMoreFiveDays = moment(Number(availableDate.ocationDate)).add(5, "days");
    const isCurrentYear = Number(moment().format("YYYY")) === Number(availableDate.ocationYear);
    const isClosedDate = moment(Number(availableDate.ocationDate)).isBetween(ocationLessTwoDays, ocationMoreFiveDays);
    const blockedCondition = showChangePlanButtons && status === "blocked";

    return (
        <div className="card-container upgrade">
            <Card className={boxesCard} style={{ backgroundColor: blockedCondition && "#F7EFE0" }}>
                {!blockedCondition && overlay}
                <Card.Content>
                    {!blockedCondition && (
                        <div className="flex-container">
                            <Card.Meta className="date-text">
                                <p className="text-h1 card-title">{cardName}</p>
                                <span className="card-subtitle-one">{availableDate.text1}</span>
                                <span
                                    className={
                                        (isClosedDate && isCurrentYear ? "closed-date" : "empty-style",
                                        selectedBox ? "empty-style white" : "empty-style")
                                    }
                                >
                                    {availableDate.text2} {availableDate.ocationYear}
                                </span>
                                <Card.Meta className="text-center">{availableDate.text3}</Card.Meta>
                            </Card.Meta>

                            <div className="card-image-event">
                                <span
                                    className={selectedBox ? "span-price" : "span-price selected"}
                                >{`+$${customPrice} extra`}</span>
                                <Image className="image-logo" src={logoEvent} size="tiny" />
                            </div>
                        </div>
                    )}
                </Card.Content>
                {blockedCondition && (
                    <>
                        <div className="inner-content">
                            <p className="text-h1 text-left" style={{ marginBottom: "3px" }}>
                                {cardName}
                            </p>
                            <p style={{ padding: 0, margin: 0 }} className="second-normal">
                                Need improve your subscription to get this box
                            </p>
                            <p style={{ display: "inline-block", margin: 0, padding: 0 }}>
                                Suggested Plan:{" "}
                                <span className="normal-text padding-top">{availablePlan.metadata.tradename}</span>
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "15px",
                                marginLeft: "10px",
                                marginRight: "10px",
                            }}
                        >
                            <button
                                className="ui button default-button cancel"
                                style={{ textTransform: "capitalize", height: "26px" }}
                                onClick={closeChangePlanButtons}
                            >
                                Cancel
                            </button>
                            <button
                                className="ui button default-button accept"
                                style={{ margin: "5px", padding: "5px", textTransform: "capitalize", height: "26px" }}
                                onClick={() => upgradeSubscriptionPlan()}
                            >
                                Accept
                            </button>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                bottom: "-30px",
                                fontFamily: "$font-family",
                            }}
                        >
                            *{availablePlan.metadata.tradename}/${suuggestedPlan.unit_amount / 100}
                        </div>
                    </>
                )}
            </Card>
            {deliveryText}
        </div>
    );
}
