import React, { useState } from "react";
import { Modal, Header } from "semantic-ui-react";
import { CustomInput, DefaultButton } from "../../components";
import { validateInputEmail } from "../../services/DataValidationService";
import { resetUserPassword } from "../../services/AuthService";
import "../../scss/components/_modal.scss";

export function EmailReset(props) {
    const { open, closeFunction } = props;
    const [emailReset, setEmailReset] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailSendMessage, setEmailSendMessage] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);

    const onChangeWriteOptions = (event, { value }) => {
        setEmailError(false);
        setEmailReset(value);
    };

    const sendResetPasswordResquest = () => {
        const isValidEmail = validateInputEmail(emailReset);
        if (isValidEmail) {
            try {
                const passwordReset = resetUserPassword(emailReset)
                if (passwordReset) {
                    setLoadingRequest(false);
                    setEmailSendMessage(true);
                    setTimeout(() => {
                        setEmailSendMessage(false);
                        closeFunction();
                    }, 4000);
                }
            } catch (error) {
                setLoadingRequest(false);
                setEmailError(error.message);
            }
        } else {
            setEmailError("Please write a valid email");
        }
    };

    let resetEmailMessage = null;

    if (emailSendMessage) {
        resetEmailMessage = (
            <>
                <p>
                    We have sent an email to the indicated address, to continue with the process of resetting your
                    password.
                </p>
                <Modal.Actions></Modal.Actions>
            </>
        );
    } else {
        resetEmailMessage = (
            <>
                <p className="text-info">
                    We can help you reset your password.
                    <br />
                    <br />
                    First enter your email account and follow the instructions.
                </p>
                <CustomInput
                    id="email"
                    placeholder="Email"
                    label="Email"
                    onChangeAction={onChangeWriteOptions}
                    value={emailReset}
                    errorMessage={emailError}
                />
                <Modal.Actions>
                    <div className="action-buttons-container">
                        <div className="action-buttons-content">
                            <DefaultButton title="Cancel" inverted={true} buttonAction={closeFunction} />
                        </div>
                        <div className="action-buttons-content">
                            <DefaultButton
                                title="Request"
                                buttonAction={sendResetPasswordResquest}
                                loading={loadingRequest}
                                disabled={emailReset === ""}
                            />
                        </div>
                    </div>
                </Modal.Actions>
            </>
        );
    }

    return (
        <Modal className="reset-modal" open={open} size="small">
            <Header as="h2" textAlign="center">
                Reset your password!
            </Header>

            <Modal.Content>{resetEmailMessage}</Modal.Content>
        </Modal>
    );
}
